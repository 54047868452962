import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  BarController,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { useState } from "react";
import { Chart } from "react-chartjs-2";
import { ElectricDemand } from "types/electric-demand";

ChartJS.register(
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function fillMissingDemandData(data: ElectricDemand[]): ElectricDemand[] {
  const filledData: ElectricDemand[] = [];
  for (let i = 0; i < 24; i++) {
    const hourData = data.find((row) => row.hourid % 24 === i);
    if (!hourData) {
      filledData.push({
        hourid: i,
        energy_demand_kwh_summer: 0,
        energy_demand_kwh_winter: 0,
        energy_demand_kwh_shoulder: 0,
        power_demand_kw_summer: 0,
        power_demand_kw_winter: 0,
        power_demand_kw_shoulder: 0,
      });
    } else {
      filledData.push(hourData);
    }
  }
  return filledData;
}

export type DemandChartProps = {
  data?: ElectricDemand[];
  variant: "power" | "energy";
};

export default function DemandChart({ data, variant }: DemandChartProps) {
  const [selectedSeason, setSelectedSeason] = useState<
    "summer" | "winter" | "shoulder"
  >("summer");
  const yAxisTitle = variant === "power" ? "Power kW" : "Energy kWh";
  const filledData = fillMissingDemandData(data ?? []);

  const seasonMapping = {
    summer:
      variant === "power"
        ? "power_demand_kw_summer"
        : "energy_demand_kwh_summer",
    winter:
      variant === "power"
        ? "power_demand_kw_winter"
        : "energy_demand_kwh_winter",
    shoulder:
      variant === "power"
        ? "power_demand_kw_shoulder"
        : "energy_demand_kwh_shoulder",
  };

  const selectedDataset = {
    label: selectedSeason.charAt(0).toUpperCase() + selectedSeason.slice(1),
    data: filledData?.map(
      (d) => d[seasonMapping[selectedSeason] as keyof ElectricDemand]
    ),
    type: variant === "power" ? ("line" as const) : ("bar" as const),
    stepped: variant === "power",
    fill: variant === "power",
    backgroundColor:
      variant === "energy"
        ? selectedSeason === "summer"
          ? "rgba(253, 190, 2, 0.8)"
          : selectedSeason === "shoulder"
          ? "rgba(220, 130, 130, 0.8)"
          : "rgba(5, 194, 204, 0.8)"
        : selectedSeason === "summer"
        ? "rgba(253, 190, 2, 0.6)"
        : selectedSeason === "shoulder"
        ? "rgba(220, 130, 130, 0.6)"
        : "rgba(5, 194, 204, 0.6)",
    borderColor:
      variant === "energy"
        ? selectedSeason === "summer"
          ? "rgba(204, 152, 0, 1)"
          : selectedSeason === "shoulder"
          ? "rgba(180, 80, 80, 1)"
          : "rgba(0, 150, 160, 1)"
        : selectedSeason === "summer"
        ? "rgba(204, 152, 0, 1)"
        : selectedSeason === "shoulder"
        ? "rgba(180, 80, 80, 1)"
        : "rgba(0, 150, 160, 1)",

    borderWidth: variant === "power" ? 2 : 0,
    pointRadius: variant === "power" ? 1 : 0,
  };

  return (
    <Box>
      <FormControl
        component="fieldset"
        sx={{ marginBottom: 1, alignContent: "center" }}
      >
        <RadioGroup
          row
          value={selectedSeason}
          onChange={(e) =>
            setSelectedSeason(
              e.target.value as "summer" | "winter" | "shoulder"
            )
          }
        >
          <FormControlLabel value="summer" control={<Radio />} label="Summer" />
          <FormControlLabel value="winter" control={<Radio />} label="Winter" />
          <FormControlLabel
            value="shoulder"
            control={<Radio />}
            label="Shoulder"
          />
        </RadioGroup>
      </FormControl>

      <Chart
        type={variant === "power" ? "line" : "bar"}
        data={{
          labels: filledData?.map((_: any, i: number) => i),
          datasets: [selectedDataset],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Hour",
              },
              grid: {
                display: false,
              },
            },
            y: {
              title: {
                display: true,
                text: yAxisTitle,
              },
              min: 0,
              suggestedMax: 100,
            },
          },
        }}
      />
    </Box>
  );
}
