import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export function DownloadButton(props) {
  const getText = () => {
    if (props.loading) {
      return "Downloading...";
    } else if (props.error) {
      return "Error, unable to download";
    } else {
      return props.innerText ? props.innerText : "Download Data";
    }
  };
  return (
    <LoadingButton
      color="primary"
      variant="outlined"
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.disabled || props.loading || props.error}
      loadingPosition="start"
      startIcon={<CloudDownloadIcon />}
      className={props.className || "download-data-button"}
    >
      {getText()}
    </LoadingButton>
  );
}

DownloadButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  innerText: PropTypes.string,
  className: PropTypes.string,
};

export function LdvDownloadButton(props) {
  const getText = () => {
    if (props.loading) {
      return "Downloading...";
    } else if (props.error) {
      return "Error, unable to download";
    } else {
      return props.innerText ? props.innerText : "Download Demand Data";
    }
  };

  const isDisabled =
    props.loading ||
    props.error ||
    !props.demandLoaded ||
    props.demandTypes.length === 0
      ? props.corridorCheckbox
        ? false
        : true
      : false;

  const isEnabled =
    props.demandTypes.length > 0 &&
    props.corridorCheckbox &&
    !props.error &&
    !props.loading &&
    !props.demandLoaded;

  return (
    <LoadingButton
      color="primary"
      variant="outlined"
      onClick={props.onClick}
      loading={props.loading}
      disabled={isDisabled && !isEnabled}
      loadingPosition="start"
      startIcon={<CloudDownloadIcon />}
      className={props.className || "download-data-button"}
    >
      {getText()}
    </LoadingButton>
  );
}

LdvDownloadButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  innerText: PropTypes.string,
  className: PropTypes.string,
  demandTypes: PropTypes.array,
  demandLoaded: PropTypes.bool,
  corridorCheckbox: PropTypes.bool,
};

export function FeederDownloadButton(props) {
  const getText = () => {
    if (props.loading) {
      return "Downloading...";
    } else if (props.error) {
      return "Error, unable to download";
    } else {
      return props.innerText ? props.innerText : "Download Feeder Line Data";
    }
  };

  const isDisabled =
    props.loading ||
    props.error ||
    props.loadingFeederLines ||
    props.location?.name !== "PG&E";

  return (
    <LoadingButton
      color="primary"
      variant="outlined"
      onClick={props.onClick}
      loading={props.loading}
      disabled={isDisabled}
      loadingPosition="start"
      startIcon={<CloudDownloadIcon />}
      className={props.className || "download-feeder-line-data-button"}
    >
      {getText()}
    </LoadingButton>
  );
}

FeederDownloadButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  innerText: PropTypes.string,
  className: PropTypes.string,
  loadingFeederLines: PropTypes.bool,
  location: PropTypes.object,
};
