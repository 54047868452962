import { ArrowDownwardSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Section } from "components/Section";
import { HubUtilityRateNew } from "types/hub-utility-rate";
import { MONTHSHORT } from "utils/month";

export type UtilityRateBoxProps = {
  utilityRate: HubUtilityRateNew;
};

export default function UtilityRateBox({ utilityRate }: UtilityRateBoxProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDownwardSharp />}>
        <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
          Utility Rate Components
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <List dense sx={{ width: "30%" }}>
            <ListItem disableGutters>
              <ListItemText
                primary="Utility Name"
                secondary={`${utilityRate.name}`}
              />
            </ListItem>
            <Divider sx={{ marginTop: 2 }} />
            <ListItem disableGutters>
              <ListItemText
                primary="Fixed Monthly Fees"
                secondary={`$${utilityRate.fixedMonthlyFees.toFixed(2) ?? 0}`}
              />
            </ListItem>
          </List>
          <Stack direction={"row"} columnGap={1}>
            <Section>
              <Stack
                direction={"column"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography marginBottom={2}>
                    Rates for Energy Charges
                  </Typography>
                  <Table
                    sx={{
                      border: "1px solid #e0e0e0",
                      width: "100%",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Tier</TableCell>
                        <TableCell>Buy ($/kWh)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {utilityRate.energyRates.map((energy, index) => (
                        <TableRow key={index}>
                          <TableCell>{energy.period}</TableCell>
                          <TableCell>{energy.tier}</TableCell>
                          <TableCell>{energy.rates}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>

                <Table
                  size="small"
                  sx={{
                    marginTop: 2,
                    border: "1px solid #ddd",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 28, height: 28 }}></TableCell>
                      {Array.from({ length: 24 }, (_, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{
                            transform: "rotate(-90deg)",
                            fontSize: "9px",
                            width: 28,
                            height: 28,
                            padding: 0,
                            borderBottom: "none",
                          }}
                        >
                          {index === 0
                            ? "12AM"
                            : index < 12
                            ? `${index}AM`
                            : `${index - 12 || 12}PM`}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {utilityRate.energyCharges.map((rates, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: "9px",
                            textAlign: "center",
                            padding: 0,
                          }}
                        >
                          {MONTHSHORT[index]}
                        </TableCell>
                        {rates.map((rate, rateIndex) => (
                          <TableCell
                            key={rateIndex}
                            sx={{
                              padding: 0,
                              width: 28,
                              height: 28,
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "9px",
                                textAlign: "center",
                              }}
                            >
                              {rate}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            </Section>

            <Section>
              <Stack
                direction={"column"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography marginBottom={2}>
                    Rates for Demand Charges
                  </Typography>
                  <Table
                    sx={{
                      border: "1px solid #e0e0e0",
                      width: "100%",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Tier</TableCell>
                        <TableCell>Charge ($/kW)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {utilityRate.demandRates.map((energy, index) => (
                        <TableRow key={index}>
                          <TableCell>{energy.period}</TableCell>
                          <TableCell>{energy.tier}</TableCell>
                          <TableCell>{energy.rates}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>

                <Table
                  size="small"
                  sx={{
                    marginTop: 2,
                    border: "1px solid #ddd",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 28, height: 28 }}></TableCell>
                      {Array.from({ length: 24 }, (_, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{
                            transform: "rotate(-90deg)",
                            fontSize: "9px",
                            width: 28,
                            height: 28,
                            padding: 0,
                            borderBottom: "none",
                          }}
                        >
                          {index === 0
                            ? "12AM"
                            : index < 12
                            ? `${index}AM`
                            : `${index - 12 || 12}PM`}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {utilityRate.demandCharges.map((rates, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: "9px",
                            textAlign: "center",
                            padding: 0,
                          }}
                        >
                          {MONTHSHORT[index]}
                        </TableCell>
                        {rates.map((rate, rateIndex) => (
                          <TableCell
                            key={rateIndex}
                            sx={{
                              padding: 0,
                              width: 28,
                              height: 28,
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "9px",
                                textAlign: "center",
                              }}
                            >
                              {rate}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Stack>
            </Section>
          </Stack>
        </>
      </AccordionDetails>
    </Accordion>
  );
}
