import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
  Input,
  InputAdornment,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Stack, styled } from "@mui/system";
import { HubScenarioYear } from "types/hub-scenario-year";
import { updateHubScenarioYears } from "api/hub/update-scenario";
import { fleetsDataType } from "api/hub/fleetData";
import { ScenarioYearlyParam } from "types/hub-scenario-data";
import {
  CAR_VEHICLE_ID,
  LIGHT_DUTY_VEHICLE_ID,
  TRAILER_VEHICLE_TYPE_ID,
  TRUCK_VEHICLE_TYPE_ID,
} from "../HubScenarioPage";
import { SiteVehicleList } from "types/hub-site-vehicle";
import { useAccessToken } from "utils/get-access-token";

const TableCell = styled(MuiTableCell)(() => ({
  border: `1px solid #000`,
}));
const Table = styled(MuiTable)(() => ({
  border: `1px solid #000`,
}));

type customInputProp = {
  endAdornment: string;
  value: number | string;
  onChange: (e: any) => void;
  readOnly?: boolean;
};
const CustomInput = ({
  endAdornment,
  value,
  onChange,
  readOnly = false,
}: customInputProp) => (
  <Input
    id="standard-adornment-weight"
    endAdornment={
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    }
    type="number"
    inputProps={{
      min: 0,
      max: 100,
      style: { textAlign: "center" },
    }}
    sx={{ width: "100px" }}
    disableUnderline={true}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    readOnly={readOnly}
  />
);

type CaptureRatesDialogProps = {
  openCaptureRatesDialog: boolean;
  siteId: number;
  scenarioId: number;
  maxHorizonYear: number | undefined;
  yearlyParams: ScenarioYearlyParam[];
  fleetsData: fleetsDataType;
  toggleCaptureRatesModal: () => void;
  refetch: () => void;
  siteVehicles: SiteVehicleList;
};

type VehicleType = {
  cars: boolean;
  lightDuty: boolean;
  trucks: boolean;
  tractorTrailers: boolean;
};

export default function CaptureRatesDialog({
  openCaptureRatesDialog,
  siteId,
  scenarioId,
  maxHorizonYear,
  yearlyParams,
  fleetsData,
  toggleCaptureRatesModal,
  refetch,
  siteVehicles,
}: CaptureRatesDialogProps) {
  const [yearData, setYearData] = useState<HubScenarioYear[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const { getToken } = useAccessToken();
  const [captureRateWarning, setCaptureRateWarning] = useState<{
    isWarning: boolean;
    years: number[];
  }>({ isWarning: false, years: [] });

  useEffect(() => {
    const yearParams = yearlyParams.map((data) => ({
      ...data,
      scenario_id: scenarioId,
    }));
    setYearData(
      yearParams.map((yearParam) => ({
        ...yearParam,
        max_utility_supply: yearParam.max_utility_supply,
      })) || []
    );

    const filteredYearParams = yearParams.filter(
      (yearParam) => !maxHorizonYear || yearParam.year <= maxHorizonYear
    );

    const years = filteredYearParams.map((data) => data.year);

    setYears(
      years.filter((year, index) => years.indexOf(year) === index).sort()
    );
  }, [yearlyParams, scenarioId, maxHorizonYear]);

  const onChangeYearlyParams = (
    year: number,
    vehicleType: number,
    objectKey: keyof HubScenarioYear,
    value: string
  ) => {
    const updatedYearlyParams = yearData.map((yearlyParams) => {
      if (objectKey === "max_utility_supply" && yearlyParams.year === year) {
        yearlyParams[objectKey] = Number(value);
      } else if (
        yearlyParams.year === year &&
        yearlyParams.vehicle_type_id === vehicleType
      ) {
        yearlyParams[objectKey] = Number(value) / 100;
      }
      return yearlyParams;
    });
    setYearData(updatedYearlyParams);
  };

  const saveScenarioYear = async () => {
    const apiToken = await getToken();
    updateHubScenarioYears(siteId, scenarioId, yearData, apiToken).then(() => {
      refetch();
      toggleCaptureRatesModal();
    });
  };

  const hasFleets = useMemo(() => {
    return Object.keys(fleetsData).length > 0;
  }, [fleetsData]);

  return (
    <Dialog
      open={openCaptureRatesDialog}
      onClose={() => toggleCaptureRatesModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={() => toggleCaptureRatesModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box>
          <Typography
            sx={{ flex: "1 1 100%", marginBottom: 3 }}
            variant="h2"
            id="tableTitle"
            component="div"
          >
            Capture Rates
          </Typography>
          <TableContainer
            sx={{
              maxHeight: 550,
              overflowY: "auto",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2} rowSpan={2}></TableCell>
                  <TableCell align="center" rowSpan={2}>
                    Maximum Utility Supply(MW)
                  </TableCell>
                  <TableCell align="center" colSpan={8}>
                    Capture Rates(%)
                  </TableCell>
                </TableRow>
                <TableRow>
                  {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
                    <>
                      <TableCell align="center">Public Cars</TableCell>
                      <TableCell align="center">Private Cars</TableCell>
                      <TableCell align="center">Public Light Trucks</TableCell>
                      <TableCell align="center">Private Light Trucks</TableCell>
                    </>
                  )}
                  {(siteVehicles.trucks || siteVehicles.trailers) && (
                    <>
                      <TableCell align="center">
                        Public Straight Trucks
                      </TableCell>
                      <TableCell align="center">
                        Private Straight Trucks
                      </TableCell>
                      <TableCell align="center">
                        Public Tractor-Trailers
                      </TableCell>
                      <TableCell align="center">
                        Private Tractor-Trailers
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {yearData.length > 0 &&
                  years.map((year: number, index: number) => (
                    <TableRow key={index}>
                      {index === 0 && (
                        <TableCell
                          align="center"
                          rowSpan={years.length}
                          sx={{ verticalAlign: "top", pt: "200px" }}
                        >
                          Planning Year
                        </TableCell>
                      )}
                      <TableCell align="center">{year}</TableCell>
                      <TableCell align="center">
                        <CustomInput
                          endAdornment={"MW"}
                          value={
                            yearData.filter(
                              (yearlyParams) => yearlyParams.year === year
                            )[0]?.max_utility_supply
                          }
                          onChange={(value) => {
                            const vehicleTypeIds: {
                              type: keyof SiteVehicleList;
                              id: number;
                            }[] = [
                              { type: "cars", id: CAR_VEHICLE_ID },
                              {
                                type: "lightDutyVehicle",
                                id: LIGHT_DUTY_VEHICLE_ID,
                              },
                              { type: "trucks", id: TRUCK_VEHICLE_TYPE_ID },
                              {
                                type: "trailers",
                                id: TRAILER_VEHICLE_TYPE_ID,
                              },
                            ];
                            if (index !== 0) {
                              const previousYearData = yearData.find(
                                (item) => item.year === year - 1
                              );

                              if (previousYearData) {
                                setCaptureRateWarning((prev) => {
                                  const isBelowThreshold =
                                    value < previousYearData.max_utility_supply;
                                  const updatedYears = isBelowThreshold
                                    ? Array.from(
                                        new Set([...prev.years, year])
                                      ).sort((a, b) => a - b) // Add and sort
                                    : prev.years.filter(
                                        (warningYear) => warningYear !== year
                                      ); // Remove

                                  return {
                                    ...prev,
                                    isWarning: updatedYears.length > 0,
                                    years: updatedYears,
                                  };
                                });
                              }
                            }

                            vehicleTypeIds.forEach(({ type, id }) => {
                              if (siteVehicles[type]) {
                                onChangeYearlyParams(
                                  year,
                                  id,
                                  "max_utility_supply",
                                  value
                                );
                              }
                            });
                          }}
                        />
                      </TableCell>
                      {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
                        <>
                          <TableCell align="center">
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      CAR_VEHICLE_ID
                                )[0]?.capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  CAR_VEHICLE_ID,
                                  "capture_rate",
                                  value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      CAR_VEHICLE_ID
                                )[0]?.subscription_capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  CAR_VEHICLE_ID,
                                  "subscription_capture_rate",
                                  value
                                )
                              }
                              // readOnly={hasFleets}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      LIGHT_DUTY_VEHICLE_ID
                                )[0]?.capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  LIGHT_DUTY_VEHICLE_ID,
                                  "capture_rate",
                                  value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      LIGHT_DUTY_VEHICLE_ID
                                )[0]?.subscription_capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  LIGHT_DUTY_VEHICLE_ID,
                                  "subscription_capture_rate",
                                  value
                                )
                              }
                              // readOnly={hasFleets}
                            />
                          </TableCell>
                        </>
                      )}
                      {(siteVehicles.trucks || siteVehicles.trailers) && (
                        <>
                          <TableCell align="center">
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      TRUCK_VEHICLE_TYPE_ID
                                )[0]?.capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  TRUCK_VEHICLE_TYPE_ID,
                                  "capture_rate",
                                  value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      TRUCK_VEHICLE_TYPE_ID
                                )[0]?.subscription_capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  TRUCK_VEHICLE_TYPE_ID,
                                  "subscription_capture_rate",
                                  value
                                )
                              }
                              // readOnly={hasFleets}
                            />
                          </TableCell>{" "}
                          <TableCell align="center">
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      TRAILER_VEHICLE_TYPE_ID
                                )[0]?.capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  TRAILER_VEHICLE_TYPE_ID,
                                  "capture_rate",
                                  value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: "#fff",
                            }}
                          >
                            <CustomInput
                              endAdornment={"%"}
                              value={(
                                yearData.filter(
                                  (yearlyParams) =>
                                    yearlyParams.year === year &&
                                    yearlyParams.vehicle_type_id ===
                                      TRAILER_VEHICLE_TYPE_ID
                                )[0]?.subscription_capture_rate * 100 || 0
                              ).toFixed(0)}
                              onChange={(value) =>
                                onChangeYearlyParams(
                                  year,
                                  TRAILER_VEHICLE_TYPE_ID,
                                  "subscription_capture_rate",
                                  value
                                )
                              }
                              // readOnly={hasFleets}
                            />
                          </TableCell>{" "}
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems={"flex-start"}>
            {captureRateWarning.isWarning && (
              <Typography
                sx={{ marginTop: 1 }}
                variant="body1"
                style={{ color: "#ed8e1a" }}
              >
                Utility supply drops in year{" "}
                {captureRateWarning.years.join(", ")} after being higher in the
                respective previous years.
              </Typography>
            )}
          </Stack>
        </Box>
        <DialogActions sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={saveScenarioYear}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="inherit"
            sx={{ color: "black" }}
            onClick={() => toggleCaptureRatesModal()}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
