import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ScenarioYearlyParam, SegmentData } from "types/hub-scenario-data";
import { downloadScenarioData } from "../utils/downloadScenario";
import { EvGrowthScenario } from "types/hub-site";
import { getDownloadData } from "api/hub/downloadData";
import { HubScenarioControls } from "types/hub-scenario-parameters";
import { range } from "utils/array";
import { setDisabledState } from "redux/features/Header/locationSlice";
import { updateHubScenario } from "api/hub/update-scenario";
import { useAppDispatch } from "redux/store";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { useFleetsDownloadData } from "api/hub/fleetData";
import AdvancedSettingsDialog from "./components/AdvancedSettingsDialog";
import Arrivals from "./arrivals/Arrivals";
import ChargerChoices, {
  VehicleChargerValues,
} from "./components/ChargerChoices";
import ChargersHub from "./chargers/ChargersHub";
import EnergyHub from "./energy/EnergyHub";
import FinancialHub from "./financial/FinancialHub";
import OptimalConfirmDialog from "./components/OptimalConfirmDialog";
import ParametersHub from "./parameters/ParametersHub";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SiteSpecificSlider from "./components/SiteSpecificSlider";
import StickyBottomBox from "dashboard/controls/StickyBottomBox";
import UploadDialog from "./components/UploadDialog";
import useChargers from "api/hub/chargers";
import useScenarioParameters from "api/hub/scenario-parameters";
import useSiteScenarioData from "api/hub/site-scenario-data";
import UtilitySelectBox from "./components/UtilitySelectBox";
import YearControl from "dashboard/controls/YearControl";
import useLandUseTypes from "api/hub/land-use-types";
import CaptureRatesDialog from "./components/CaptureRatesDialog";
import { SiteVehicleData, SiteVehicleList } from "types/hub-site-vehicle";
import { useAccessToken } from "utils/get-access-token";
import useSiteTypes from "api/hub/site-types";
import {
  uploadHubUtilityRate,
  useHubUtilityRates,
} from "api/hub/site-utility-rates";
import { HubUtilityRateNew } from "types/hub-utility-rate";
import { UtilityRateStructureNewInput } from "types/terminal-financial";

export const CAR_VEHICLE_ID = 1;
export const LIGHT_DUTY_VEHICLE_ID = 2;
export const TRUCK_VEHICLE_TYPE_ID = 3;
export const TRAILER_VEHICLE_TYPE_ID = 4;

const AVERAGE_ANNUAL_MAINTENANCE_COST_PER_CHARGER = 400;

const LAND_USE_CODE = [
  {
    type: "Intermodal Truck Terminal",
    code: 30,
  },
  {
    type: "General Light Industrial",
    code: 110,
  },
  {
    type: "Manufacturing",
    code: 140,
  },
  {
    type: "Warehousing",
    code: 150,
  },
  {
    type: "Truck Stop",
    code: 950,
  },
];

const ALLEN_ST = "345 S Allen St";
const SANTA_FE = "1680 Santa Fe";

const EV_REALTY_ALLEN_ST = [
  {
    type: ALLEN_ST,
    code: 1051,
  },
];

const EV_REALTY_SANTA_FE = [
  {
    type: SANTA_FE,
    code: 1052,
  },
];

const MICHIGAN_CENTRAL = "Michigan Central";
const COMMERCIAL_CONSUMER_LAND_USE_CODE = 820;

const currentYear = new Date().getFullYear();

type HubTerminalPageProps = {
  siteId: number;
  evGrowthScenarioId: number;
  evGrowthScenarios: EvGrowthScenario[];
  backToLandingPage: () => void;
  handleSiteListScenariosChange: (siteId: number, scenarioId: number) => void;
  tutorialStep?: number;
  isTutorial: Boolean;
  setIsOpen: (visible: Boolean) => void;
  siteVehicles: SiteVehicleData[];
  isAdmin: Boolean;
};
const HubScenarioPage: React.FC<HubTerminalPageProps> = ({
  siteId,
  evGrowthScenarioId,
  evGrowthScenarios,
  backToLandingPage,
  handleSiteListScenariosChange,
  tutorialStep,
  isTutorial,
  setIsOpen,
  siteVehicles,
  isAdmin,
}: HubTerminalPageProps) => {
  const [selectedEvGrowthScenarioId, setSelectedEvGrowthScenarioId] =
    useState<number>(evGrowthScenarioId);
  const [optimalStatus, setOptimalStatus] = useState<boolean>(true);
  const [ldvOptimalStatus, setLdvOptimalStatus] = useState<boolean>(true);
  const [annualAveragePerCharger, setAnnualAveragePerCharger] =
    useState<number>(AVERAGE_ANNUAL_MAINTENANCE_COST_PER_CHARGER);
  const [openAdvancedSettingsDialog, setOpenAdvancedSettingsDialog] =
    useState(false);
  const [openCaptureRatesDialog, setOpenCaptureRatesDialog] = useState(false);
  const [optimalDialogOpen, setOptimalDialogOpen] = useState<{
    isOpen: boolean;
    isLdv: boolean;
  }>({ isLdv: false, isOpen: false });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [transitionState, setTransitionState] = useState(isTutorial ? 1 : 0);
  const [maxHorizonYear, setMaxHorizonYear] = useState<number | undefined>(
    2040
  );

  const { getToken } = useAccessToken();

  const [currentTab, setCurrentTab] = useState(
    tutorialStep === 15 ? "chargers" : "arrivals"
  );

  const [scenarioId, setScenarioId] = useState<number | undefined>();
  const [scenarioError, setScenarioError] = useState<{
    parkingArea: boolean;
    ldvParkingArea: boolean;
  }>({
    parkingArea: false,
    ldvParkingArea: false,
  });
  const [scenarioControls, setScenarioControls] = useState<HubScenarioControls>(
    {
      ldvLandUseCode: null,
      mhdLandUseCode: null,
      year: currentYear,
      carsParkingPct: 0.5,
      lightDutyParkingPct: 0.5,
      trucksParkingPct: 0.5,
      trailersParkingPct: 0.5,
      publicCarChargerPct: 1,
      publicChargerPct: 1,
      parkingArea: 15000,
      ldvParkingArea: 15000,
      utilityRateId: null,
      carChargerId: null,
      lightDutyChargerId: null,
      carChargerCost: 0,
      lightDutyChargerCost: 0,
      truckChargerId: null,
      truckChargerCost: 0,
      trailerChargerId: null,
      trailerChargerCost: 0,
      truckOperatingHours: [0, 24],
      trailerOperatingHours: [0, 24],
      carOperatingHours: [0, 24],
      lightDutyOperatingHours: [0, 24],
    }
  );

  const { scenarioParameters } = useScenarioParameters(
    siteId,
    selectedEvGrowthScenarioId
  );

  const { utilityRates, refetch: refetchUtilityRates } =
    useHubUtilityRates(siteId);
  const { chargers } = useChargers();
  const { siteScenarioData, refetch } = useSiteScenarioData(
    siteId,
    scenarioId,
    maxHorizonYear && maxHorizonYear < currentYear
      ? currentYear + 6
      : maxHorizonYear || currentYear + 6,
    optimalStatus,
    annualAveragePerCharger
  );
  const { fleetsData, refetch: refetchFleetsData } =
    useFleetsDownloadData(siteId);
  const { landUseTypes } = useLandUseTypes(siteId);
  const { siteTypes: ldvSiteTypes } = useSiteTypes("LDV");
  const { siteTypes: mhdSiteTypes } = useSiteTypes("MHD");

  let isEvRealtyAllen = false,
    isEvRealtySanta = false;
  if (scenarioParameters?.siteId === 22) {
    isEvRealtyAllen = true;
  }

  if (scenarioParameters?.siteId === 23) {
    isEvRealtySanta = true;
  }

  let isMichiganCentral = false;
  if (siteScenarioData?.name.toLowerCase() === MICHIGAN_CENTRAL.toLowerCase()) {
    isMichiganCentral = true;
  }

  const {
    aggregateData,
    truckData,
    carsData,
    lightDutyData,
    carYearlyParams,
    lightDutyYearlyParams,
    trailerData,
    truckYearlyParams,
    trailerYearlyParams,
  } = useMemo(() => {
    return {
      aggregateData: siteScenarioData?.scenario.calculated_data.aggregate_data,
      truckData: siteScenarioData?.scenario.calculated_data.segment_data.find(
        (data: SegmentData) => data.vehicle_type_id === TRUCK_VEHICLE_TYPE_ID
      ),
      trailerData: siteScenarioData?.scenario.calculated_data.segment_data.find(
        (data: SegmentData) => data.vehicle_type_id === TRAILER_VEHICLE_TYPE_ID
      ),
      carsData: siteScenarioData?.scenario.calculated_data.segment_data.find(
        (data: SegmentData) => data.vehicle_type_id === CAR_VEHICLE_ID
      ),
      lightDutyData:
        siteScenarioData?.scenario.calculated_data.segment_data.find(
          (data: SegmentData) => data.vehicle_type_id === LIGHT_DUTY_VEHICLE_ID
        ),
      truckYearlyParams:
        siteScenarioData?.scenario.yearly_params.filter(
          (param: ScenarioYearlyParam) =>
            param.vehicle_type_id === TRUCK_VEHICLE_TYPE_ID
        ) ?? [],
      trailerYearlyParams:
        siteScenarioData?.scenario.yearly_params.filter(
          (param: ScenarioYearlyParam) =>
            param.vehicle_type_id === TRAILER_VEHICLE_TYPE_ID
        ) ?? [],
      carYearlyParams:
        siteScenarioData?.scenario.yearly_params.filter(
          (param: ScenarioYearlyParam) =>
            param.vehicle_type_id === CAR_VEHICLE_ID
        ) ?? [],
      lightDutyYearlyParams:
        siteScenarioData?.scenario.yearly_params.filter(
          (param: ScenarioYearlyParam) =>
            param.vehicle_type_id === LIGHT_DUTY_VEHICLE_ID
        ) ?? [],
    };
  }, [siteScenarioData]);

  useEffect(() => {
    if (scenarioParameters) {
      setScenarioId(scenarioParameters.id);
      // This code may be needed in the future.
      // setMaxHorizonYear(scenarioParameters.maxHorizonYear);

      // Set to Truck Stop (950) if land use code is not one of the above 5 types.
      // let landUseCode = LAND_USE_CODE.some(
      //   (landUse) => landUse.code === scenarioParameters.landUseCode
      // )
      //   ? scenarioParameters.landUseCode
      //   : 950;

      // if (isEvRealtyAllen) {
      //   landUseCode = 1051;
      // }
      // if (isEvRealtySanta) {
      //   landUseCode = 1052;
      // }

      const ldvLandUseCode = isMichiganCentral
        ? COMMERCIAL_CONSUMER_LAND_USE_CODE
        : scenarioParameters.ldvLandUseCode;

      const ldvSite = ldvSiteTypes.find(
        (type) => type.landUseCode === ldvLandUseCode
      );
      const mhdSite = mhdSiteTypes.find(
        (type) => type.landUseCode === scenarioParameters.mhdLandUseCode
      );

      setScenarioControls({
        ...scenarioParameters,
        ldvLandUseCode,
        carOperatingHours:
          ldvSite?.operatingHours ?? scenarioParameters.carOperatingHours,
        lightDutyOperatingHours:
          ldvSite?.operatingHours ?? scenarioParameters.lightDutyOperatingHours,
        truckOperatingHours:
          mhdSite?.operatingHours ?? scenarioParameters.truckOperatingHours,
        trailerOperatingHours:
          mhdSite?.operatingHours ?? scenarioParameters.trailerOperatingHours,
      });
    }
  }, [isMichiganCentral, ldvSiteTypes, mhdSiteTypes, scenarioParameters]);

  useEffect(() => {
    setScenarioControls((prevControls) => {
      const isValidYear =
        scenarioControls.year &&
        maxHorizonYear &&
        maxHorizonYear >= scenarioControls.year;

      return {
        ...prevControls,
        year: isValidYear
          ? scenarioControls.year
          : scenarioParameters?.year ?? currentYear,
      };
    });
  }, [scenarioControls.year, maxHorizonYear, scenarioParameters?.year]);

  useEffect(() => {
    if (isTutorial) {
      switch (tutorialStep) {
        case 12:
          setCurrentTab("arrivals");
          break;
        case 13:
          setCurrentTab("energy");
          break;
        case 14:
        case 15:
          setCurrentTab("chargers");
          break;
        case 16:
          setCurrentTab("financial");
          break;
        default:
          break;
      }
    }
  }, [tutorialStep, isTutorial]);

  useEffect(() => {
    if (tutorialStep === 15 && currentTab === "chargers") {
      if (isTutorial && transitionState === 1) {
        setIsOpen(false);
        setTransitionState(2);
      } else if (transitionState === 2) {
        setIsOpen(true);
        setTransitionState(0);
      }
    }
    if (tutorialStep !== 15) {
      setTransitionState(1);
    }
  }, [tutorialStep, transitionState, currentTab, isTutorial, setIsOpen]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setDisabledState(true));
    return () => {
      dispatch(setDisabledState(false));
    };
  }, []);

  useDebouncedEffect(
    () => {
      const { parkingArea, ldvParkingArea } = scenarioControls;

      const isParkingAreaInvalid = !parkingArea || parkingArea < 1000;
      const isLdvParkingAreaInvalid = !ldvParkingArea || ldvParkingArea < 1000;
      setScenarioError({
        parkingArea: isParkingAreaInvalid,
        ldvParkingArea: isLdvParkingAreaInvalid,
      });

      if (!isParkingAreaInvalid) {
        saveScenario();
      }
    },
    [scenarioControls, maxHorizonYear],
    500
  );

  const saveScenario = async () => {
    const apiToken = await getToken();
    if (scenarioId) {
      const data = { ...scenarioControls };
      data.trailersParkingPct = 1 - data.trucksParkingPct;

      updateHubScenario(siteId, scenarioId, scenarioControls, apiToken)
        .then((response) => {
          setSnackbarMessage("success:Scenario data successfully updated.");
          refetch();
        })
        .catch((err) => {
          setSnackbarMessage("error:Scenario data failed to update.");
        });
    }
  };

  const onClickOptimal = () => {
    optimalStatus
      ? setOptimalDialogOpen({ isLdv: false, isOpen: true })
      : setOptimalStatus(true);
    !optimalStatus &&
      setScenarioControls({
        ...scenarioControls,
        trucksParkingPct: 50 / 100,
      });
  };

  const onClickLDVOptimal = () => {
    ldvOptimalStatus
      ? setOptimalDialogOpen({ isLdv: true, isOpen: true })
      : setLdvOptimalStatus(true);
    !ldvOptimalStatus &&
      setScenarioControls({
        ...scenarioControls,
        carsParkingPct: 50 / 100,
      });
  };
  const uploadUtilityRate = async (
    utilityRate: UtilityRateStructureNewInput
  ) => {
    const apiToken = await getToken();
    const response = (await uploadHubUtilityRate(
      { ...utilityRate },
      siteId,
      apiToken
    )) as HubUtilityRateNew;
    setSnackbarMessage("success:Utility rate is successfully uploaded.");
    refetchUtilityRates();
    setScenarioControls({
      ...scenarioControls,
      utilityRateId: response.id,
    });
  };

  const selectedUtility = useMemo(() => {
    return utilityRates.find(
      (rate) => rate.id === siteScenarioData?.scenario.utility_rate_id
    );
  }, [siteScenarioData?.scenario.utility_rate_id, utilityRates]);

  const handleDownloadData = useCallback(async () => {
    if (scenarioId) {
      const apiToken = await getToken();
      const data = await getDownloadData(
        siteId,
        scenarioId,
        optimalStatus,
        apiToken
      );
      if (data.downloadData && selectedUtility) {
        downloadScenarioData(data.downloadData, selectedUtility);
      } else {
        alert(data.error?.message);
      }
    }
  }, [getToken, scenarioId, siteId, optimalStatus, selectedUtility]);

  const getCorrectInt = (value: string, min: number, max: number) => {
    if (value === "") {
      return undefined;
    }
    return Math.max(Math.min(parseInt(value), max), min);
  };

  const getYearControlIncrement = (): number => {
    if (!maxHorizonYear) return 1;

    const deltaYear = maxHorizonYear - currentYear;
    if (deltaYear <= 10) return 1;

    let increment: number;

    if (deltaYear <= 20) increment = 2;
    else if (deltaYear <= 30) increment = 3;
    else if (deltaYear <= 40) increment = 5;
    else increment = 10;

    while (deltaYear % increment > 0 && deltaYear % increment < 3) increment++;

    return increment;
  };

  const siteVehicleList: SiteVehicleList = useMemo(() => {
    return {
      cars: siteVehicles.some(
        (vehicle) => vehicle.vehicle_type_id === CAR_VEHICLE_ID
      ),
      lightDutyVehicle: siteVehicles.some(
        (vehicle) => vehicle.vehicle_type_id === LIGHT_DUTY_VEHICLE_ID
      ),
      trucks: siteVehicles.some(
        (vehicle) => vehicle.vehicle_type_id === TRUCK_VEHICLE_TYPE_ID
      ),
      trailers: siteVehicles.some(
        (vehicle) => vehicle.vehicle_type_id === TRAILER_VEHICLE_TYPE_ID
      ),
    };
  }, [siteVehicles]);

  useEffect(() => {
    setScenarioControls((prevControls) => {
      const carCharger = chargers.find(
        (charger) => charger.id === prevControls.carChargerId
      );
      const ldvCharger = chargers.find(
        (charger) => charger.id === prevControls.lightDutyChargerId
      );

      const truckCharger = chargers.find(
        (charger) => charger.id === prevControls.truckChargerId
      );
      const trailerCharger = chargers.find(
        (charger) => charger.id === prevControls.trailerChargerId
      );

      return {
        ...prevControls,
        carChargerCost: carCharger
          ? carCharger.price
          : prevControls.carChargerCost,
        lightDutyChargerCost: ldvCharger
          ? ldvCharger.price
          : prevControls.lightDutyChargerCost,
        truckChargerCost: truckCharger
          ? truckCharger.price
          : prevControls.truckChargerCost,
        trailerChargerCost: trailerCharger
          ? trailerCharger.price
          : prevControls.trailerChargerCost,
      };
    });
  }, [
    chargers,
    scenarioControls.carChargerId,
    scenarioControls.lightDutyChargerId,
    scenarioControls.truckChargerId,
    scenarioControls.trailerChargerId,
  ]);

  return (
    <Stack
      direction="row"
      sx={{ height: "100%" }}
      className={"hub-scenario-page"}
    >
      <Stack
        sx={{ width: "512px", height: "100%", border: "0.5px solid silver" }}
        className={"scenario-left-pane"}
      >
        <Stack
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={2}
          sx={{ padding: "30px", overflowY: "auto" }}
        >
          <Box>
            <Button variant="outlined" onClick={() => backToLandingPage()}>
              Back
            </Button>
          </Box>

          <Box sx={{ width: "100%" }} className={"scenario-parameters-box"}>
            <Typography variant="h3" sx={{ marginBottom: 3 }}>
              Site Parameters
            </Typography>
            <Stack spacing={2}>
              <TextField
                value={scenarioParameters?.siteName}
                label="Site Name"
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel>Scenario Name</InputLabel>
                  <Select
                    value={selectedEvGrowthScenarioId}
                    label="Scenario Name"
                    onChange={(e) => {
                      const evGrowthScenarioId = Number(e.target.value);
                      setSelectedEvGrowthScenarioId(evGrowthScenarioId);
                      handleSiteListScenariosChange(siteId, evGrowthScenarioId);
                    }}
                  >
                    {evGrowthScenarios.map((scenario, index) => {
                      return (
                        <MenuItem key={index} value={scenario.id}>
                          {scenario.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Stack direction={"row"} spacing={1}>
                {isAdmin &&
                  (siteVehicleList.cars ||
                    siteVehicleList.lightDutyVehicle) && (
                    <Box sx={{ width: "50%" }}>
                      <FormControl fullWidth>
                        <InputLabel>LDV Site Type</InputLabel>
                        <Select
                          value={scenarioControls?.ldvLandUseCode ?? ""}
                          label="LDV Site Type"
                          onChange={(e) => {
                            const siteType = Number(e.target.value);
                            const ldvSite = ldvSiteTypes.find(
                              (type) => type.landUseCode === siteType
                            );
                            setScenarioControls({
                              ...scenarioControls,
                              ldvLandUseCode: siteType,
                              carOperatingHours: ldvSite?.operatingHours ??
                                scenarioControls.carOperatingHours ?? [0, 24],
                              lightDutyOperatingHours:
                                ldvSite?.operatingHours ??
                                  scenarioControls.lightDutyOperatingHours ?? [
                                    0, 24,
                                  ],
                            });
                          }}
                        >
                          {isMichiganCentral
                            ? ldvSiteTypes
                                .filter(
                                  (type) =>
                                    type.landUseCode ===
                                    COMMERCIAL_CONSUMER_LAND_USE_CODE
                                )
                                .map((landUseType, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={landUseType.landUseCode}
                                    >
                                      {landUseType.name}
                                    </MenuItem>
                                  );
                                })
                            : ldvSiteTypes.map((landUseType, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={landUseType.landUseCode}
                                  >
                                    {landUseType.name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                {isAdmin &&
                  (siteVehicleList.trucks || siteVehicleList.trailers) && (
                    <Box sx={{ width: "50%" }}>
                      <FormControl fullWidth>
                        <InputLabel>MHD Site Type</InputLabel>
                        <Select
                          value={scenarioControls?.mhdLandUseCode ?? ""}
                          label="MHD Site Type"
                          onChange={(e) => {
                            const siteType = Number(e.target.value);
                            const mhdSite = mhdSiteTypes.find(
                              (type) => type.landUseCode === siteType
                            );
                            setScenarioControls({
                              ...scenarioControls,
                              mhdLandUseCode: siteType,
                              truckOperatingHours: mhdSite?.operatingHours ??
                                scenarioControls.truckOperatingHours ?? [0, 24],
                              trailerOperatingHours: mhdSite?.operatingHours ??
                                scenarioControls.trailerOperatingHours ?? [
                                  0, 24,
                                ],
                            });
                          }}
                        >
                          {mhdSiteTypes.map((landUseType, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={landUseType.landUseCode}
                              >
                                {landUseType.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
              </Stack>
            </Stack>
          </Box>

          <Box sx={{ width: "100%" }} className={"site-parameters-box"}>
            <Typography variant="h3" sx={{ marginBottom: 2 }}>
              EV Adoption Scenario Parameters
            </Typography>
            <Box>
              <Typography>Cars are EPA class 1 and 2a vehicles</Typography>
              <Typography>
                Light Trucks are EPA class 2b and 3 vehicles
              </Typography>
              <Typography>
                Straight Trucks are EPA class 4, 5, 6, 7 vehicles
              </Typography>
              <Typography sx={{ marginBottom: 2 }}>
                Tractor-Trailers are EPA class 8 vehicles
              </Typography>
            </Box>
            <Stack spacing={1} sx={{ marginBottom: 3 }}>
              <Typography variant="body2">Year</Typography>
              <YearControl
                value={scenarioControls.year}
                increment={getYearControlIncrement()}
                years={range(
                  currentYear,
                  ((maxHorizonYear && maxHorizonYear > currentYear
                    ? maxHorizonYear
                    : currentYear + 1) || currentYear + 5) + 1
                )}
                onChange={(e) =>
                  setScenarioControls({
                    ...scenarioControls,
                    year: Number((e.target as HTMLInputElement).value),
                  })
                }
              />

              {/* <Stack
                direction="row"
                justifyContent={"space-between"}
                spacing={2}
                pt={3}
              >
                <Typography variant="subtitle1">Project out to year</Typography>
                <TextField
                  value={maxHorizonYear}
                  onChange={(event) => {
                    setMaxHorizonYear(
                      getCorrectInt(
                        event.target.value,
                        currentYear + 1,
                        Math.min(
                          scenarioParameters?.maxHorizonYear || 2050,
                          2050
                        )
                      )
                    );
                  }}
                  type="number"
                  variant="standard"
                  size="small"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </Stack> */}
            </Stack>

            <Box sx={{ marginBottom: 3 }}>
              <Link
                component="button"
                variant="inherit"
                sx={{ fontWeight: 500 }}
                onClick={() =>
                  setOpenAdvancedSettingsDialog(!openAdvancedSettingsDialog)
                }
                className={"advanced-setting-link"}
              >
                Advanced Settings
              </Link>
            </Box>

            <Box sx={{ marginBottom: 3 }}>
              <Link
                component="button"
                variant="inherit"
                sx={{ fontWeight: 500 }}
                onClick={() =>
                  setOpenCaptureRatesDialog(!openCaptureRatesDialog)
                }
                className={"capture-rates-link"}
              >
                Capture Rates
              </Link>
            </Box>

            <Stack
              spacing={3}
              sx={{ marginTop: 4 }}
              className={"utility-parameters-box"}
            >
              <Stack spacing={1} direction={"row"}>
                {(siteVehicleList.cars || siteVehicleList.lightDutyVehicle) && (
                  <Tooltip
                    sx={{ width: "50%" }}
                    title={
                      "'Usable' refers to the space in a site that is available for charger installation. " +
                      "This metric should not be lower than 1000 sq. ft."
                    }
                    placement="right"
                  >
                    <TextField
                      error={scenarioError.ldvParkingArea}
                      value={scenarioControls.ldvParkingArea.toString()}
                      onKeyDown={(event) => {
                        if (event.key === "-" || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                      type="number"
                      inputProps={{ min: 0 }}
                      helperText={
                        scenarioError.ldvParkingArea
                          ? "Usable Square Footage should not be lower than 1000 sq. ft."
                          : ""
                      }
                      label="LDV Usable Square Footage"
                      onChange={(e) => {
                        const ldvParkingArea = parseInt(e.target.value);
                        setScenarioControls({
                          ...scenarioControls,
                          ldvParkingArea,
                        });
                      }}
                    />
                  </Tooltip>
                )}
                {(siteVehicleList.trucks || siteVehicleList.trailers) && (
                  <Tooltip
                    sx={{ width: "50%" }}
                    title={
                      "'Usable' refers to the space in a site that is available for charger installation. " +
                      "This metric should not be lower than 1000 sq. ft."
                    }
                    placement="right"
                  >
                    <TextField
                      error={scenarioError.parkingArea}
                      value={scenarioControls.parkingArea.toString()}
                      onKeyDown={(event) => {
                        if (event.key === "-" || event.key === ".") {
                          event.preventDefault();
                        }
                      }}
                      type="number"
                      inputProps={{ min: 0 }}
                      label="MHD Usable Square Footage"
                      helperText={
                        scenarioError.parkingArea
                          ? "Usable Square Footage should not be lower than 1000 sq. ft."
                          : ""
                      }
                      onChange={(e) => {
                        const parkingArea = parseInt(e.target.value);
                        setScenarioControls({
                          ...scenarioControls,
                          parkingArea: parkingArea,
                        });
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>
              <Box>
                <ChargerChoices
                  siteVehicles={siteVehicleList}
                  chargers={chargers}
                  carChargerId={scenarioControls.carChargerId}
                  carChargerCost={scenarioControls.carChargerCost}
                  lightDutyChargerId={scenarioControls.lightDutyChargerId}
                  lightDutyChargerCost={scenarioControls.lightDutyChargerCost}
                  truckChargerId={scenarioControls.truckChargerId}
                  truckChargerCost={scenarioControls.truckChargerCost}
                  trailerChargerId={scenarioControls.trailerChargerId}
                  trailerChargerCost={scenarioControls.trailerChargerCost}
                  handleChargerValuesChange={(
                    vehicleChargerValues: VehicleChargerValues
                  ) =>
                    setScenarioControls({
                      ...scenarioControls,
                      ...vehicleChargerValues,
                    })
                  }
                />
              </Box>
            </Stack>
          </Box>
        </Stack>

        <StickyBottomBox>
          <Button
            variant="contained"
            onClick={handleDownloadData}
            className="scenario-download-button"
          >
            Download data
          </Button>
          <Button
            variant="contained"
            onClick={() => setUploadDialogOpen(true)}
            className="scenario-upload-button"
          >
            Upload Fleet Arrivals
          </Button>
        </StickyBottomBox>
      </Stack>
      <Stack
        direction={"column"}
        sx={{ flex: 1, position: "relative", overflowY: "scroll" }}
        className="tab-panels"
      >
        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={(e, tab) => setCurrentTab(tab)}
              variant={"fullWidth"}
            >
              <Tab
                label={"Arrivals"}
                value={"arrivals"}
                className="arrivals-tab"
              />
              <Tab label={"Energy"} value={"energy"} className="energy-tab" />
              <Tab
                label={"Chargers"}
                value={"chargers"}
                className="chargers-tab"
              />
              <Tab
                label={"Financial"}
                value={"financial"}
                className="financial-tab"
              />
              <Tab
                label={"Parameters"}
                value={"parameters"}
                className="parameters-tab"
              />
            </TabList>
          </Box>
          <TabPanel value={"arrivals"} className="chart-tab-panel">
            <Arrivals
              truckData={truckData}
              trailerData={trailerData}
              carData={carsData}
              lightDutyData={lightDutyData}
            />
          </TabPanel>
          <TabPanel value={"financial"} className="chart-tab-panel">
            <FinancialHub
              financialData={aggregateData?.financial}
              year={scenarioControls.year}
            />
          </TabPanel>
          <TabPanel value={"chargers"} className="chart-tab-panel">
            <ChargersHub
              carChargersData={carsData?.chargers}
              lightDutyChargersData={lightDutyData?.chargers}
              truckChargersData={truckData?.chargers}
              trailerChargersData={trailerData?.chargers}
            />
          </TabPanel>
          <TabPanel value={"energy"} className="chart-tab-panel">
            <EnergyHub
              aggregateEnergyDemandData={aggregateData?.energy_demand}
            />
          </TabPanel>
          <TabPanel value={"parameters"} className="chart-tab-panel">
            <ParametersHub
              siteName={siteScenarioData?.name}
              scenarioName={siteScenarioData?.scenario.name}
              utilityRate={selectedUtility}
              truckData={truckData}
              trailerData={trailerData}
              truckYearlyParams={truckYearlyParams}
              trailerYearlyParams={trailerYearlyParams}
              carData={carsData}
              LightDutyData={lightDutyData}
              carYearlyParams={carYearlyParams}
              lightDutyYearlyParams={lightDutyYearlyParams}
              siteVehicles={siteVehicleList}
            />
          </TabPanel>
        </TabContext>
      </Stack>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        onClose={() => setSnackbarMessage(undefined)}
      >
        <Alert severity={snackbarMessage?.split(":")[0] as "success" | "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Yearly Assumption Model */}
      {scenarioId && openAdvancedSettingsDialog && utilityRates && (
        <AdvancedSettingsDialog
          openAdvancedSettingsDialog={openAdvancedSettingsDialog}
          toggleAdvancedSettingsModal={() =>
            setOpenAdvancedSettingsDialog(!openAdvancedSettingsDialog)
          }
          annualAveragePerCharger={annualAveragePerCharger}
          setAnnualAveragePerCharger={setAnnualAveragePerCharger}
          scenarioControls={scenarioControls}
          setScenarioControls={setScenarioControls}
          siteVehicles={siteVehicleList}
          fleetsData={fleetsData}
          optimalStatus={optimalStatus}
          ldvOptimalStatus={ldvOptimalStatus}
          onClickOptimal={onClickOptimal}
          onClickLDVOptimal={onClickLDVOptimal}
          utilityRates={utilityRates}
          uploadUtilityRate={uploadUtilityRate}
        />
      )}

      {/* Capture Rates Model */}
      {scenarioId && openCaptureRatesDialog && (
        <CaptureRatesDialog
          openCaptureRatesDialog={openCaptureRatesDialog}
          siteId={siteId}
          scenarioId={scenarioId}
          maxHorizonYear={maxHorizonYear}
          yearlyParams={siteScenarioData?.scenario.yearly_params ?? []}
          fleetsData={fleetsData}
          toggleCaptureRatesModal={() =>
            setOpenCaptureRatesDialog(!openCaptureRatesDialog)
          }
          refetch={refetch}
          siteVehicles={siteVehicleList}
        />
      )}

      {/* File upload Model */}
      <UploadDialog
        uploadDialogOpen={uploadDialogOpen}
        setUploadDialogOpen={setUploadDialogOpen}
        siteId={siteId}
        refetchScenarioData={refetch}
        fleetsData={fleetsData}
        refetchFleetsData={refetchFleetsData}
      />

      {/* Optimal Confirm Dialog */}
      {optimalDialogOpen && (
        <OptimalConfirmDialog
          open={optimalDialogOpen.isOpen}
          handleConfirm={() =>
            optimalDialogOpen.isLdv
              ? setLdvOptimalStatus(!ldvOptimalStatus)
              : setOptimalStatus(!optimalStatus)
          }
          handleClose={() =>
            setOptimalDialogOpen({ isLdv: false, isOpen: false })
          }
        />
      )}
    </Stack>
  );
};

export default HubScenarioPage;
