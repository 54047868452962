import { Box, Divider, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";
import {
  FleetScenario,
  FleetScenarioChargerLevel,
  FleetScenarioVehicle,
} from "types/fleets";
import { generate24HourLabels } from "utils/time";

export type FleetOverviewProps = {
  scenario?: FleetScenario;
  scope?: string[];
  vehiclePurchaseSuggestions?: FleetScenarioVehicle[];
  chargerPurchaseSuggestions?: FleetScenarioChargerLevel[];
};

function FleetOverview({
  scenario,
  scope,
  vehiclePurchaseSuggestions,
  chargerPurchaseSuggestions,
}: FleetOverviewProps) {
  const timeLabels = generate24HourLabels();

  const isEV = useMemo(() => {
    return scenario?.name.includes("EV");
  }, [scenario]);

  const evVehicleCount = useMemo(() => {
    return vehiclePurchaseSuggestions?.reduce((total, vehicle) => {
      return total + (vehicle.numElectricVehicles || 0);
    }, 0);
  }, [vehiclePurchaseSuggestions]);

  const vehicles = useMemo(() => {
    return vehiclePurchaseSuggestions?.map((vehicle) => ({
      ...vehicle,
      reference_vehicle:
        vehicle.numElectricVehicles === 0 ? "N/A" : vehicle.referenceVehicle,
      iceReferenceVehicle:
        vehicle.numIceVehicles === 0 ? "N/A" : vehicle.iceReferenceVehicle,
      otherEvReferenceVehicle:
        vehicle.numElectricVehicles === 0
          ? "N/A"
          : vehicle.otherEvReferenceVehicle.join(", "),
    }));
  }, [vehiclePurchaseSuggestions]);

  const vehicleColumns: GridColumns = [
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      flex: 1,
    },
    {
      field: isEV ? "referenceVehicle" : "iceReferenceVehicle",
      headerName: `${isEV ? "EV" : "ICE"} Reference Make/Model`,
      flex: 1,
    },
    {
      field: isEV ? "numElectricVehicles" : "numIceVehicles",
      headerName: `Quantity (${isEV ? "EV" : "ICE"})`,
      flex: 1,
    },
    {
      field: "operatingHours",
      headerName: "Operating Hours",
      flex: 1,
      valueFormatter: (params) => {
        return `${timeLabels[params.value[0]]} - ${
          timeLabels[params.value[1] === 24 ? 0 : params.value[1]]
        }`;
      },
    },
    {
      field: "vehicleCosts",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      maxWidth: 150,
      flex: 1,
    },
  ];

  if (isEV) {
    vehicleColumns.splice(2, 0, {
      field: "otherEvReferenceVehicle",
      headerName: `Other EV Reference Make/Model`,
      flex: 1,
    });
  }

  const chargerColumns: GridColumns = [
    {
      field: "chargerType",
      headerName: "Charger Type",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "referenceCharger",
      headerName: "Reference Make/Model",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "capacity",
      headerName: "Level (KW)",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "numOfChargers",
      headerName: "Quantity",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "chargerCost",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      minWidth: 140,
      flex: 1,
    },
  ];

  const filteredVehicleRows = scope?.includes("Vehicles") ? vehicles : [];

  const filteredChargerRows =
    scope?.includes("Chargers/EVSE") && evVehicleCount && evVehicleCount > 0
      ? chargerPurchaseSuggestions
      : [];

  return (
    <Box>
      <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
        Fleet Overview
      </Typography>
      <Stack spacing={2} divider={<Divider />}>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ marginTop: "15px", fontWeight: "bold" }}
          >
            Vehicles
          </Typography>
          <Box sx={{ height: "300px" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1.25,
                },
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              getRowHeight={() => "auto"}
              rows={filteredVehicleRows ?? []}
              headerHeight={60}
              columns={vehicleColumns}
              checkboxSelection={false}
              loading={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ fontWeight: "bold" }}
          >
            Chargers
          </Typography>
          <Stack spacing={1} direction="column">
            <Box sx={{ height: "300px" }}>
              <DataGrid
                rows={filteredChargerRows ?? []}
                columns={chargerColumns}
                checkboxSelection={false}
                loading={false}
                keepNonExistentRowsSelected={false}
              />
            </Box>
            <Typography fontSize={14} color="secondary">
              Please note: All charger-vehicle combinations should be validated
              for compatibility prior to purchase.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FleetOverview;
