import { Add, Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useUtilityRatesForOrganizations } from "api/terminal/utility-rates";
import { Section } from "components/Section";
import React, { useEffect, useMemo, useState } from "react";
import { AnnualPayload, Configuration } from "types/terminal";
import { MONTH, MONTHSHORT } from "utils/month";

const TOTALSTEPS = 3;

type AddAnnualizationDialogProps = {
  DialogProps: DialogProps;
  configurations: Configuration[];
  vehicleTypes: { id: number; name: string }[];
  annualPayload: AnnualPayload;
  filterValues: { terminal: string; costCenter: string; vehicleType: string };
  annualizationNames: string[];
  setAnnualPayload: (values: AnnualPayload) => void;
  onUpdateVehicleType: (VehicleType: string) => void;
  addNewConfigurations: () => void;
  removeNewConfigurations: (index: number) => void;
  onSubmit: () => void;
};

const SelectVehicleType = React.memo(
  ({
    vehicleTypes,
    onUpdateVehicleType,
    filterValues,
  }: {
    vehicleTypes: { id: number; name: string }[];
    onUpdateVehicleType: any;
    filterValues: { terminal: string; costCenter: string; vehicleType: string };
  }) => (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="vehicle-type-label">Preferred Vehicle Type</InputLabel>
      <Select
        labelId="vehicle-type-label"
        label="Preferred Vehicle Type"
        value={filterValues.vehicleType || ""}
        onChange={(event) => onUpdateVehicleType(event.target.value)}
      >
        {vehicleTypes.map((vehicle) => (
          <MenuItem key={vehicle.id} value={vehicle.name}>
            {vehicle.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
);

const SelectUtilityType: React.FC<{
  annualPayload: AnnualPayload;
  handleFieldUpdate: (name: string, value: number) => void;
}> = React.memo(({ annualPayload, handleFieldUpdate }) => {
  const { utilityRates, loadingUtilityRates, errorLoadingUtilityRates } =
    useUtilityRatesForOrganizations(annualPayload.propertyId);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="utility-type-label">Utility Rate Applied *</InputLabel>
      <Select
        labelId="utility-type-label"
        label="Utility Rate Applied *"
        required
        value={annualPayload.utilityRateId}
        onChange={(event) =>
          handleFieldUpdate("utilityRateId", Number(event.target.value))
        }
      >
        {utilityRates.map((utility) => (
          <MenuItem key={utility.id} value={utility.id}>
            {utility.name}
          </MenuItem>
        ))}

        {loadingUtilityRates && (
          <MenuItem disabled>Loading utility rates...</MenuItem>
        )}

        {errorLoadingUtilityRates && (
          <MenuItem disabled>Error loading utility rates</MenuItem>
        )}
      </Select>
    </FormControl>
  );
});

const SelectConfigurationType = React.memo(
  ({
    scenarioId,
    index,
    configurations,
    isNew,
    selectedScenarioIds,
    onChange,
  }: {
    scenarioId: number;
    index: number;
    isNew: boolean;
    selectedScenarioIds: number[];
    configurations: Configuration[];
    onChange: (index: number, newScenarioId: Configuration) => void;
  }) => {
    // Filter configurations for new entries
    const availableConfigurations = isNew
      ? configurations.filter(
          (config) => !selectedScenarioIds.includes(config.scenarioId)
        )
      : configurations;

    return (
      <FormControl fullWidth sx={{ width: "170px" }} variant="outlined">
        <InputLabel id={`configuration-label-${index}`}>
          Configuration {index + 1} *
        </InputLabel>
        <Select
          labelId={`configuration-label-${index}`}
          value={scenarioId || ""}
          required
          label={`Configuration ${index + 1} *`}
          onChange={(event) => {
            const selectedScenarioId = Number(event.target.value);
            const selectedConfig = configurations.find(
              (config) => config.scenarioId === selectedScenarioId
            );
            if (selectedConfig) {
              onChange(index, selectedConfig);
            }
          }}
        >
          {availableConfigurations.length > 0 &&
            availableConfigurations.map((config) => (
              <MenuItem key={config.id} value={config.scenarioId}>
                {config.name}
              </MenuItem>
            ))}
          {availableConfigurations.length === 0 && (
            <MenuItem disabled>No configurations are available.</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
);

export default function AddAnnualizationDialog({
  DialogProps,
  vehicleTypes,
  configurations,
  annualPayload,
  filterValues,
  annualizationNames,
  setAnnualPayload,
  onUpdateVehicleType,
  addNewConfigurations,
  removeNewConfigurations,
  onSubmit,
}: AddAnnualizationDialogProps) {
  const [step, setStep] = useState<number>(1);
  const [isDifferentVehicleScenarios, setDifferentVehicleScenarios] =
    useState<boolean>(true);
  const [annualizationName, setAnnualizationName] = useState<string>("");
  const [error, setError] = useState<{ [key: string]: boolean }>(
    MONTHSHORT.reduce((acc, month) => ({ ...acc, [month]: false }), {})
  );

  const isDifferentVehicleScenariosMemo = useMemo(() => {
    if (!annualPayload.terminalAnnualizationScenario?.length) return false;
    const vehicleTypeNames = annualPayload.terminalAnnualizationScenario
      .map((scenario) => scenario.scenarioVehicleName?.trim())
      .filter(Boolean);
    return new Set(vehicleTypeNames).size > 1;
  }, [annualPayload]);

  useEffect(() => {
    setDifferentVehicleScenarios(isDifferentVehicleScenariosMemo);
  }, [isDifferentVehicleScenariosMemo]);

  const handleStepChange = (increment: boolean) => {
    setStep((prev) => (increment ? prev + 1 : prev - 1));
  };

  const handleSubmit = () => {
    const updatedTerminalScenarios =
      annualPayload.terminalAnnualizationScenario.map((scenario) => {
        const filledDaysApplied = Array.from(
          { length: 12 },
          (_, index) => scenario.daysApplied[index] || 0
        );
        return {
          ...scenario,
          daysApplied: filledDaysApplied,
        };
      });
    const filteredTerminalScenarioVehicles = updatedTerminalScenarios.filter(
      (scenario) => scenario.scenarioVehicleName.trim() !== ""
    );
    const updatedAnnualization = {
      ...annualPayload,
      terminalAnnualizationScenario: filteredTerminalScenarioVehicles,
      name: annualizationName,
    };
    setAnnualPayload(updatedAnnualization);
    setStep(TOTALSTEPS);
  };

  const handleCloseDialog = () => {
    DialogProps.onClose?.({}, "backdropClick");
    setStep(1);
    onSubmit();
  };

  const disableNextButton =
    !annualizationName ||
    !annualPayload.utilityRateId ||
    annualPayload.terminalAnnualizationScenario.length < 1 ||
    annualPayload.terminalAnnualizationScenario.some(
      (scenario) => !scenario.scenarioVehicleName
    ) ||
    Object.values(error).includes(true);

  return (
    <Dialog {...DialogProps} maxWidth="lg" fullWidth>
      {step < TOTALSTEPS ? (
        <Box>
          <DialogTitle>
            <Grid
              container
              direction="row"
              columnGap={3}
              marginTop={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3">
                  <b>Create Annual Plan</b>
                </Typography>
              </Grid>
              <Grid item md>
                {step === 1 && (
                  <SelectVehicleType
                    vehicleTypes={vehicleTypes}
                    filterValues={filterValues}
                    onUpdateVehicleType={onUpdateVehicleType}
                  />
                )}
              </Grid>
              {isDifferentVehicleScenarios ? (
                <Grid item md>
                  <Alert color="warning" severity="warning">
                    Annualization requires the same vehicle class.
                  </Alert>
                </Grid>
              ) : (
                <Grid item md></Grid>
              )}
              <Grid item>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Step {step} of {TOTALSTEPS - 1}
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {step === 1 ? (
              <Box>
                <Grid container justifyContent="space-between" marginBottom={3}>
                  <Grid item md>
                    <TextField
                      label="Annualization Name"
                      required
                      inputProps={{ maxLength: 40 }}
                      value={annualizationName}
                      onChange={(event) =>
                        setAnnualizationName(event.target.value)
                      }
                      error={annualizationNames.includes(annualizationName)}
                      helperText={
                        annualizationNames.includes(annualizationName)
                          ? "This name already exists. Please choose a different name."
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md></Grid>
                  <Grid item md>
                    <SelectUtilityType
                      annualPayload={annualPayload}
                      handleFieldUpdate={(name, utility) => {
                        const values = { ...annualPayload };
                        values.utilityRateId = Number(utility);
                        setAnnualPayload(values);
                      }}
                    />
                  </Grid>
                </Grid>
                <Section>
                  <Grid
                    container
                    columnGap={3}
                    alignItems={"center"}
                    marginBottom={3}
                  >
                    <Grid item md></Grid>
                    <Grid item>Months</Grid>
                    <Grid item>
                      <Grid container spacing={3.2} marginRight={1}>
                        {Array.from({ length: 12 }, (_, index) => (
                          <Grid item key={index}>
                            <Chip label={(index + 1).toString()} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button></Button>
                    </Grid>
                  </Grid>
                  {annualPayload.terminalAnnualizationScenario.map(
                    (confs, cindex) => {
                      const selectedScenarioIds =
                        annualPayload.terminalAnnualizationScenario.map(
                          (scn) => scn.scenarioId
                        );
                      const isNew = confs.scenarioId === 0;
                      return (
                        <Grid
                          container
                          columnGap={3}
                          alignItems="center"
                          marginBottom={3}
                          key={cindex}
                        >
                          <Grid item md>
                            <SelectConfigurationType
                              scenarioId={Number(confs.scenarioId)}
                              index={cindex}
                              isNew={isNew}
                              configurations={configurations}
                              selectedScenarioIds={selectedScenarioIds}
                              onChange={(
                                index: number,
                                conf: Configuration
                              ) => {
                                const updatedPayload = { ...annualPayload };
                                updatedPayload.terminalAnnualizationScenario[
                                  index
                                ] = {
                                  ...updatedPayload
                                    .terminalAnnualizationScenario[index],
                                  scenarioId: conf.scenarioId,
                                  scenarioName: conf.name,
                                  scenarioVehicleId: conf.vehicleId,
                                  scenarioVehicleName: conf.vehicleType,
                                  scenarioVehicleModelId: conf.vehicleModelId,
                                  scenarioVehicleModelName:
                                    conf.vehicleModelName,
                                  chargerId: conf.chargerId,
                                  chargerName: conf.chargerName,
                                };
                                setAnnualPayload(updatedPayload);
                              }}
                            />
                          </Grid>
                          <Grid item>Days Applied *</Grid>
                          <Grid item>
                            <Grid container spacing={1}>
                              {Array.from({ length: 12 }, (_, index) => {
                                const daysInCurrentMonth = new Date(
                                  new Date().getFullYear(),
                                  index + 1,
                                  0
                                ).getDate();

                                return (
                                  <Grid item key={index}>
                                    <TextField
                                      type="number"
                                      variant="outlined"
                                      size="small"
                                      inputProps={{
                                        maxLength: 2,
                                        pattern: "[0-9]{1,2}",
                                        title: `Enter number of days between 0 and ${daysInCurrentMonth}.`,
                                      }}
                                      sx={{
                                        width: "50px",
                                        "& input[type=number]": {
                                          MozAppearance: "textfield",
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                          {
                                            WebkitAppearance: "none",
                                            margin: 0,
                                          },
                                      }}
                                      value={confs.daysApplied[index]}
                                      required
                                      onKeyDown={(event) => {
                                        if (
                                          [
                                            "Backspace",
                                            "Delete",
                                            "ArrowLeft",
                                            "ArrowRight",
                                            "Tab",
                                            "Enter",
                                            "Escape",
                                          ].includes(event.key) ||
                                          (event.ctrlKey &&
                                            ["a", "c", "v", "x"].includes(
                                              event.key.toLowerCase()
                                            ))
                                        ) {
                                          return;
                                        }
                                        if (!/^[0-9]$/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(event) => {
                                        const newValue =
                                          event.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                        const numericValue = Math.round(
                                          Number(newValue)
                                        );

                                        const sumExcludingCurrent =
                                          annualPayload.terminalAnnualizationScenario.reduce(
                                            (sum, scenario, idx) =>
                                              idx === cindex
                                                ? sum
                                                : sum +
                                                  (scenario.daysApplied[
                                                    index
                                                  ] || 0),
                                            0
                                          );

                                        const totalIncludingNewValue =
                                          sumExcludingCurrent + numericValue;
                                        const duplicateAnnualPayload = {
                                          ...annualPayload,
                                        };
                                        duplicateAnnualPayload.terminalAnnualizationScenario[
                                          cindex
                                        ].daysApplied[index] = numericValue;

                                        if (
                                          daysInCurrentMonth >=
                                          totalIncludingNewValue
                                        ) {
                                          setError((prevValues) => ({
                                            ...prevValues,
                                            [MONTH[index]]: false,
                                          }));
                                        } else {
                                          setError((prevValues) => ({
                                            ...prevValues,
                                            [MONTH[index]]: true,
                                          }));
                                        }
                                        setAnnualPayload(
                                          duplicateAnnualPayload
                                        );
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => removeNewConfigurations(cindex)}
                            >
                              <Close />
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                  <Box marginTop={3}>
                    <Button
                      variant="outlined"
                      onClick={() => addNewConfigurations()}
                    >
                      <Add /> Add Configurations
                    </Button>
                    {Object.keys(error).find((month) => error[month]) && (
                      <Typography variant="caption" color="red" marginLeft={2}>
                        The total number of days applied should not exceed the
                        number of days in{" "}
                        {Object.keys(error)
                          .filter((month) => error[month])
                          .join(", ")}
                        .
                      </Typography>
                    )}
                  </Box>
                </Section>
              </Box>
            ) : (
              <Section>
                <Typography variant="h3" marginBottom={2}>
                  Current recommendation
                </Typography>
                {annualPayload.terminalAnnualizationScenario.map(
                  (scenario, index) => (
                    <Grid
                      container
                      columnGap={3}
                      justifyContent="center"
                      alignItems="center"
                      marginBottom={3}
                      marginTop={3}
                      key={index}
                    >
                      <Grid item md>
                        <TextField
                          label={`Configuration ${index + 1}`}
                          fullWidth
                          value={scenario.scenarioName}
                          sx={{
                            "& .MuiInputLabel-root.Mui-disabled": {
                              color: "rgba(0, 0, 0, 0.80)",
                            },
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid item md>
                        <TextField
                          label="Vehicle Model"
                          fullWidth
                          value={scenario.scenarioVehicleModelName}
                          sx={{
                            "& .MuiInputLabel-root.Mui-disabled": {
                              color: "rgba(0, 0, 0, 0.80)",
                            },
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid item md>
                        <TextField
                          label="Charger Model"
                          fullWidth
                          value={scenario.chargerName}
                          sx={{
                            "& .MuiInputLabel-root.Mui-disabled": {
                              color: "rgba(0, 0, 0, 0.80)",
                            },
                          }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  )
                )}
              </Section>
            )}

            <Box
              display="flex"
              justifyContent={step > 1 ? "space-between" : "flex-end"}
              marginTop={3}
            >
              {step > 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: 100 }}
                  onClick={() => handleStepChange(false)}
                >
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                disabled={isDifferentVehicleScenarios || disableNextButton}
                sx={{ width: 100 }}
                onClick={() =>
                  step === TOTALSTEPS - 1
                    ? handleSubmit()
                    : handleStepChange(true)
                }
              >
                {step === TOTALSTEPS - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </DialogContent>
        </Box>
      ) : (
        <DialogContent>
          <Box
            sx={{
              flexGrow: 1,
              display: "grid",
              placeItems: "center",
              overflowY: "hidden",
            }}
          >
            <Grid container direction="column" spacing={3} alignItems="center">
              <Grid item>
                <Typography variant="h2">
                  Annualization Simulation In Progress.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Please check back later; the annualization will be complete
                  soon.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleCloseDialog}>
                  OKAY
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
}
