import {
  Alert,
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Snackbar,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import HelpTooltip from "components/HelpTooltip";
import InputSlider from "components/InputSlider";
import { Section } from "components/Section";
import { AnnualFinance } from "types/terminal-annualization";
import CostComparisonChart from "../../financial/charts/CostComparisonChart";
import CostComparisonTable from "../../financial/components/CostComparisonTable";
import { AnnualFinancialControl } from "../Annualization";

const convertLocalizedValue = (value: string): number => {
  return Number(value.replace(/\D/g, "")) || 0;
};

export const AnnualizationFinancial = ({
  financialData,
  financialControls,
  setFinancialControls,
  updateFinancialControls,
}: {
  financialData: AnnualFinance;
  financialControls: AnnualFinancialControl;
  setFinancialControls: React.Dispatch<
    React.SetStateAction<AnnualFinancialControl>
  >;
  updateFinancialControls: () => void;
}) => {
  const LongWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      textAlign: "justify",
      textJustify: "inter-word",
    },
  });

  return (
    <Box
      sx={{
        padding: 2,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Section>
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
            Financial Controls
          </Typography>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Typography variant="subtitle1">
                Planning horizon years:
              </Typography>
              <InputSlider
                min={1}
                max={24}
                step={1}
                value={financialControls.planningHorizonYears}
                onChange={(newValue) =>
                  setFinancialControls({
                    ...financialControls,
                    planningHorizonYears: newValue,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Year(s)</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Typography variant="subtitle1">
                Fuel Cost Growth Rate (%):
              </Typography>
              <InputSlider
                min={0}
                max={10}
                step={0.1}
                value={Number(
                  (financialControls.fuelCostGrowthRate * 100).toFixed(3)
                )}
                onChange={(newValue) => {
                  setFinancialControls({
                    ...financialControls,
                    fuelCostGrowthRate: Number((newValue / 100).toFixed(3)),
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Typography variant="subtitle1">
                Electric Cost Growth Rate (%):
              </Typography>
              <InputSlider
                min={0}
                max={10}
                step={0.1}
                value={Number(
                  (financialControls.electricCostGrowthRate * 100).toFixed(3)
                )}
                onChange={(newValue) => {
                  setFinancialControls({
                    ...financialControls,
                    electricCostGrowthRate: Number((newValue / 100).toFixed(3)),
                  });

                  // dispatch(
                  //   setElectricCostGrowthRate(
                  //     Number((newValue / 100).toFixed(3))
                  //   )
                  // );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <HelpTooltip
                title={"This is the same as Interest Rate"}
                placement="right"
              >
                <Typography variant="subtitle1">Discount Rate (%):</Typography>
              </HelpTooltip>
              <InputSlider
                min={0}
                max={10}
                step={0.1}
                value={Number(
                  (financialControls.discountRate * 100).toFixed(1)
                )}
                onChange={
                  (newValue) =>
                    setFinancialControls({
                      ...financialControls,
                      discountRate: Number((newValue / 100).toFixed(3)),
                    })
                  // dispatch(setDiscountRate(Number(newValue.toFixed(1)) / 100))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <HelpTooltip
                title={
                  "An arrangement in which a third-party developer installs, owns, " +
                  "and operates an energy system on a customer’s property. PPAs facilitate the " +
                  "financing of distributed generation assets such as photovoltaic, microturbines, reciprocating engines, and fuel cells."
                }
              >
                <Typography variant="subtitle1">
                  Power Purchase Agreement (%):
                </Typography>
              </HelpTooltip>
              <InputSlider
                min={0}
                max={100}
                step={1}
                value={Number((financialControls.ppaRate * 100).toFixed(1))}
                onChange={
                  (newValue) =>
                    setFinancialControls({
                      ...financialControls,
                      ppaRate: Number((newValue / 100).toFixed(3)),
                    })
                  // dispatch(setPPARate(Number(newValue.toFixed(1)) / 100))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={2} marginTop={3}>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1">Charger Cost =</Typography>
                <Input
                  sx={{ marginLeft: "10px", width: "120px" }}
                  type="string"
                  value={financialControls.chargerCost}
                  onChange={(e) =>
                    setFinancialControls({
                      ...financialControls,
                      chargerCost: convertLocalizedValue(e.target.value),
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">$</InputAdornment>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1">Vehicle Cost =</Typography>
                <Input
                  sx={{ marginLeft: "10px", width: "120px" }}
                  type="string"
                  value={financialControls.vehicleCost}
                  onChange={(e) =>
                    setFinancialControls({
                      ...financialControls,
                      vehicleCost: convertLocalizedValue(e.target.value),
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">$</InputAdornment>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={6} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1">
                  Total Initial Installation Cost =
                </Typography>
                <Input
                  sx={{ marginLeft: "10px", width: "120px" }}
                  type="string"
                  value={financialControls.totalInstallationCost}
                  onChange={(e) => {
                    setFinancialControls({
                      ...financialControls,
                      totalInstallationCost: convertLocalizedValue(
                        e.target.value
                      ),
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">$</InputAdornment>
                  }
                />
              </Box>
            </Grid>

            <Grid container justifyContent="flex-end" mt={2} xs={10.6} lg={12}>
              <Grid item>
                <Button variant="contained" onClick={updateFinancialControls}>
                  Remember my setting
                </Button>
                <Snackbar
                  open={false}
                  autoHideDuration={6000}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                  // onClose={() => setOpenCostUpdateAlert(false)}
                >
                  <Alert severity={"success"}>{"alertMessage"}</Alert>
                </Snackbar>
                <Grid />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Section>
      <Box
        sx={{
          border: 0.5,
          padding: "16px",
          borderColor: "silver",
          borderRadius: 5,
          marginTop: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
            Total Optimized Expenses (Net Present Value: USD)
          </Typography>
          <Typography variant="h3" sx={{ marginLeft: "30px" }}>
            {`$${Number(
              financialData?.costComparisonTableData.evOptimizedChargingData.totalExpensesNPV.toFixed(
                0
              )
            ).toLocaleString()}`}
          </Typography>
        </Box>
      </Box>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <LongWidthTooltip
                title={`Only configuration is the current workload without EVs. EV-reference case is all EVs running till end of shift and then charging to full. EV-optimized charging optimizes the operational and charging schedule for EVs.`}
              >
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  Configuration Comparison
                </Typography>
              </LongWidthTooltip>
            </Box>
            {financialData && (
              <CostComparisonChart
                dataSets={financialData.costComparisonData}
                baseVehicleEngineType={"ICE"}
              />
            )}
          </Section>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <LongWidthTooltip
                title={`Only configuration is the current workload without EVs. EV-reference case is all EVs running till end of shift and then charging to full. EV-optimized charging optimizes the operational and charging schedule for EVs.`}
              >
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  Configuration Comparison Data
                </Typography>
              </LongWidthTooltip>
            </Box>
            {financialData && (
              <CostComparisonTable
                data={financialData.costComparisonTableData}
                baseVehicleEngineType={"ICE"}
              />
            )}
          </Section>
        </Grid>
      </Grid>
    </Box>
  );
};
