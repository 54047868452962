import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { AnnualCharger } from "types/terminal";

type AnnualChargerTableProps = {
  chargerInfo: Omit<
    AnnualCharger,
    "id" | "name" | "amperage" | "numberOfPorts"
  >;
};

const AnnualChargerTable = ({ chargerInfo }: AnnualChargerTableProps) => {
  return (
    <Box marginTop={2}>
      <Box marginTop={1}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 350, textDecoration: "underline" }}
        >
          Chargers
        </Typography>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", marginTop: 2 }}
        >
          <Table
            sx={{
              border: "1px solid #e0e0e0",
              tableLayout: "fixed",
              width: "100%",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Make
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.make || "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Model
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.model || "N/A"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Total Count
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.optNumChargers || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Unit MSRP
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  ${chargerInfo.priceUsd || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Charger Rating
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.chargeRateKw || 0} KW
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Charger Voltage
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.voltage || 0} V
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Charger Replacement Life Cycle Years
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.chargerReplacementLifecycleYears || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  Battery Replacement Life Cycle Years
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #e0e0e0",
                    width: "50%",
                  }}
                >
                  {chargerInfo.batteryReplacementLifecycleYears || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AnnualChargerTable;
