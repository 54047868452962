export const getStateAbbrFromStateName = (stateName: string) => {
  switch (stateName) {
    case "New York":
      return "NY";
    case "Massachusetts":
      return "MA";
    case "TxPPC":
      return "TXPPC";
    case "CPS":
      return "CPS";
    case "NBU":
      return "NBU";
    case "LCRA":
      return "LCRA";
    case "Austin Energy":
      return "AE";
    case "First Energy":
      return "FIRST_ENERGY";
    case "Socal Edison":
      return "SOCAL";
    case "NiSource":
      return "NISOURCE";
    case "Dubai":
      return "DUBAI";
    case "Utah":
      return "UTAH";
    case "Rhode Island":
      return "RI";
    case "PSE&G":
      return "PSE&G";
    case "Vermont":
      return "VT";
    case "Colorado":
      return "CO";
    case "Texas":
      return "TX";
    case "California":
      return "CA";
    case "Washington":
      return "WA";
    case "Oregon":
      return "OR";
    case "SE Massachusetts":
      return "BROCKTON";
    case "Pennsylvania":
      return "pennsylvania";
    case "San Bernardino":
      return "SAN_BERNARDINO";
    case "New Jersey":
      return "NJ";
    case "Maryland":
      return "MD";
    default:
      // NGRID only requires two states.
      // This will have the API request a non-existent state
      return "N/A";
  }
};

export const getStatesConfig = (stateAbbr: string) => {
  const result = {
    location: stateAbbr.toLowerCase(),
    isTxPPC: false,
    isFirstEnergy: false,
    isSoCal: false,
    isNiSource: false,
    isDubai: false,
    isUtah: false,
    isRhodeIsland: false,
    isPseng: false,
    isVermont: false,
    isColorado: false,
    isTexas: false,
    isCalifornia: false,
    isWashington: false,
    isOregon: false,
    isBrockton: false,
    isPennsylvania: false,
    isNextGenCompany: false,
    isFeederDisable: false,
    isDemoRegridDepot: false,
    isSanBernardino: false,
    isNewJersey: false,
    isMaryland: false,
  };

  const isFeederDisableForLocation =
    process.env.REACT_APP_HOME_URL?.includes("development") || false;

  switch (stateAbbr.toLowerCase()) {
    case "txppc":
    case "cps":
    case "nbu":
    case "lcra":
    case "austin energy":
    case "ae":
      result.isTxPPC = true;
      result.isNextGenCompany = true;
      result.isFeederDisable = true;
      break;
    case "first energy":
    case "first_energy":
      result.isFirstEnergy = true;
      result.isNextGenCompany = true;
      // Disable FirstEnergy feeder-line layer only in the development environment.
      result.isFeederDisable = isFeederDisableForLocation;
      break;
    case "socal":
    case "socal edison":
      result.isSoCal = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "nisource":
      result.isNiSource = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "dubai":
      result.isDubai = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "utah":
      result.isUtah = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "ri":
    case "rhode island":
      result.isRhodeIsland = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "pse&g":
      result.isPseng = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "vermont":
    case "vt":
      result.isVermont = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "colorado":
    case "co":
      result.isColorado = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "texas":
    case "tx":
      result.isTexas = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "california":
    case "ca":
      result.isCalifornia = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "washington":
    case "wa":
      result.isWashington = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "oregon":
    case "or":
      result.isOregon = true;
      result.isDemoRegridDepot = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "brockton":
    case "se massachusetts":
      result.isBrockton = true;
      result.isFeederDisable = true;
      result.isNextGenCompany = true;
      break;
    case "pennsylvania":
      result.isPennsylvania = true;
      result.isNextGenCompany = true;
      // Disable pennsylvania feeder-line layer only in the development environment.
      result.isFeederDisable = isFeederDisableForLocation;
      break;
    case "san bernardino":
    case "san_bernardino":
      result.isSanBernardino = true;
      result.isNextGenCompany = true;
      result.isFeederDisable = true;
      break;
    case "nj":
    case "new jersey":
      result.isNewJersey = true;
      result.isNextGenCompany = true;
      result.isFeederDisable = true;
      break;
    case "md":
    case "maryland":
      result.isMaryland = true;
      result.isNextGenCompany = true;
      result.isFeederDisable = true;
      break;
    default:
      break;
  }

  return result;
};

export const getStateNameFromStateAbbr = (stateAbbr: string) => {
  switch (stateAbbr) {
    case "TX":
      return "Texas";
    case "CA":
      return "California";
    case "FL":
      return "Florida";
    case "VA":
      return "Virginia";
    case "GA":
      return "Georgia";
    case "IN":
      return "Indiana";
    case "NJ":
      return "New Jersey";
    case "NY":
      return "New York";
    case "PA":
      return "Pennsylvania";
    case "MA":
      return "Massachusetts";
    case "DUBAI":
      return "Dubai";
    case "UTAH":
      return "Utah";
    case "RI":
      return "Rhode Island";
    case "VT":
      return "Vermont";
    case "CO":
      return "Colorado";
    case "WA":
      return "Washington";
    case "OR":
      return "Oregon";
    case "Annecy":
      return "Annecy";
    case "BROCKTON":
      return "SE Massachusetts";
    case "pennsylvania":
      return "Pennsylvania";
    case "SAN_BERNARDINO":
      return "San Bernardino";
    case "NJ":
      return "New Jersey";
    case "MD":
      return "Maryland";
    default:
      return "N/A";
  }
};
