import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { StepType, useTour } from "@reactour/tour";
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  ClearAll,
  Delete,
  InfoOutlined,
  Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  DataGrid,
  GridActionsCellItem,
  GridAlignment,
  GridCloseIcon,
} from "@mui/x-data-grid";
import { loadFinancialData } from "api/terminal/financial";
import { useTerminals } from "api/terminal/terminals";
import {
  addNewAnnualization,
  disableAnnualPlan,
  loadAnnualizations,
} from "api/terminal/terminals-annualization";
import { Section } from "components/Section";
import {
  Annualization,
  AnnualPayload,
  Configuration,
  Terminal,
  TerminalScenario,
} from "types/terminal";
import { FinancialData } from "types/terminal-financial";
import { formatCurrency } from "utils/format-number";
import { useAccessToken } from "utils/get-access-token";
import { annualizationDownload } from "./annualizationDownload";
import AddAnnualizationDialog from "./components/AddAnnualizationDialog";
import AddScenarioDialog from "./components/AddScenarioDialog";
import AnnualizationListTable from "./components/AnnualizationListTable";
import SelectDownloadDialog from "./components/SelectDownloadDialog";
import TerminalListTable from "./components/TerminalListTable";
import { terminalTourSteps } from "./components/terminalTourStep";
import AnnualizationPage from "./pages/annualization/Annualization";
import TerminalPage from "./TerminalPage";

/* NOTE: For any code in this 'terminal' folder, the following sets of words are used interchangeably:
/   - terminal <> site
/   - scenario <> configuration
/   - facility <> cost center
*/

type SiteListProp = {
  terminals: Terminal[];
  annualizations: Annualization[];
  selectedAnnualIds: number[];
  setSelectedAnnualIds: (annualId: number) => void;
  setSelectedAnnualization: (annualization: Annualization) => void;
  navigateToScenario: (
    terminalId: number,
    facilityId: number,
    scenarioId: number
  ) => void;
  setSteps: React.Dispatch<React.SetStateAction<StepType[]>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  selectedTerminalIds: number[];
  setSelectedTerminalIds: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedTerminalIdForPopup: React.Dispatch<React.SetStateAction<number>>;
  setSelectedFacilityIdForPopup: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTerminalNameForPopup: React.Dispatch<React.SetStateAction<string>>;
  setSelectedFacilityNameForPopup: React.Dispatch<React.SetStateAction<string>>;
  setSelectedOrganizationIdForPopup: React.Dispatch<
    React.SetStateAction<number>
  >;
  setOpenDownloadDialog: React.Dispatch<React.SetStateAction<boolean>>;
  downloadAnnualPlans: () => void;
  setTransitionState: React.Dispatch<React.SetStateAction<number>>;
  isTutorial: Boolean;
  tutorialStep: number;
  refetchTerminal: () => void;
  deleteAnnualization: (annualId: number) => void;
  downloadingAnnualization: boolean;
};

const SiteList = ({
  terminals,
  annualizations,
  selectedAnnualIds,
  setSelectedAnnualIds,
  setSelectedAnnualization,
  navigateToScenario,
  setSteps,
  setCurrentStep,
  setIsOpen,
  selectedTerminalIds,
  setSelectedTerminalIds,
  setSelectedTerminalIdForPopup,
  setSelectedFacilityIdForPopup,
  setSelectedTerminalNameForPopup,
  setSelectedFacilityNameForPopup,
  setSelectedOrganizationIdForPopup,
  setOpenDownloadDialog,
  downloadAnnualPlans,
  setTransitionState,
  isTutorial,
  tutorialStep,
  refetchTerminal,
  downloadingAnnualization,
}: SiteListProp) => {
  return (
    <Grid
      container
      direction="column"
      width="600px"
      height="100%"
      padding={2}
      overflow={"auto"}
      justifyContent="center"
      rowGap={2}
    >
      <Grid item>
        <Grid container columnGap={2} alignItems="center">
          <Grid item>
            <Typography variant="h2">Configurations Library</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setSteps(terminalTourSteps);
                setCurrentStep(0);
                setIsOpen(true);
                setTransitionState(1);
              }}
              startIcon={<HelpOutlineIcon />}
              color="info"
              variant="outlined"
              size="small"
            >
              Tutorial
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md sx={{ flexGrow: 1 }}>
        <Grid
          container
          direction="column"
          rowGap={2}
          sx={{ height: "100%", width: "100%" }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <TerminalListTable
              terminals={terminals}
              navigateToScenario={navigateToScenario}
              selectedTerminalIds={selectedTerminalIds}
              setSelectedTerminalIds={(ids) => setSelectedTerminalIds(ids)}
              setSelectedTerminalIdForPopup={setSelectedTerminalIdForPopup}
              setSelectedFacilityIdForPopup={setSelectedFacilityIdForPopup}
              setSelectedTerminalNameForPopup={setSelectedTerminalNameForPopup}
              setSelectedFacilityNameForPopup={setSelectedFacilityNameForPopup}
              setSelectedOrganizationIdForPopup={
                setSelectedOrganizationIdForPopup
              }
              isTutorial={isTutorial}
              tutorialStep={tutorialStep}
              refetchTerminal={refetchTerminal}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Selecting Terminals makes the associated data available for download">
              <span style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  disabled={!selectedTerminalIds.length}
                  className="download-data-button"
                  sx={{ width: "100%" }}
                  onClick={() => setOpenDownloadDialog(true)}
                >
                  Download Terminal Data
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Divider orientation="horizontal" flexItem />

      <Grid item>
        <Typography variant="h2">Annual Plans Library</Typography>
      </Grid>

      <Grid item md sx={{ flexGrow: 1 }}>
        <Grid
          container
          direction="column"
          rowGap={2}
          sx={{ height: "100%", width: "100%" }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <AnnualizationListTable
              annualizations={annualizations.filter((y) => y.statusId === 1)}
              setSelectedAnnualization={(annualization) =>
                setSelectedAnnualization(annualization)
              }
              selectedAnnualIds={selectedAnnualIds}
              setSelectedAnnualIds={(annualId) =>
                setSelectedAnnualIds(annualId)
              }
            />
          </Grid>
          <Grid item>
            <Tooltip title="Selecting Annual plan makes the associated data available for download">
              <span style={{ width: "100%" }}>
                <LoadingButton
                  variant="contained"
                  disabled={!selectedAnnualIds.length}
                  className="download-data-button"
                  sx={{ width: "100%" }}
                  loading={downloadingAnnualization}
                  onClick={() => downloadAnnualPlans()}
                >
                  Download Annual Plan
                </LoadingButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function TerminalLandingPage() {
  const { getToken } = useAccessToken();
  const [selectedScenario, setSelectedScenario] = useState<
    TerminalScenario | undefined
  >(undefined);
  const [scenarios, setScenarios] = useState<TerminalScenario[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [openAnnualDialog, setOpenAnnualDialog] = useState<boolean>(false);
  const [selectedTerminalIds, setSelectedTerminalIds] = useState<number[]>([]);
  const [selectedAnnualIds, setSelectedAnnualIds] = useState<number[]>([]);
  const [selectedTerminalIdForPopup, setSelectedTerminalIdForPopup] =
    useState<number>(0);
  const [selectedTerminalNameForPopup, setSelectedTerminalNameForPopup] =
    useState<string>("");
  const [selectedFacilityIdForPopup, setSelectedFacilityIdForPopup] =
    useState<number>(0);
  const [selectedFacilityNameForPopup, setSelectedFacilityNameForPopup] =
    useState<string>("");
  const [selectedOrganizationIdForPopup, setSelectedOrganizationIdForPopup] =
    useState<number>(0);
  const [downloadingAnnualization, setDownloadingAnnualization] =
    useState<boolean>(false);
  const [transitionState, setTransitionState] = useState(0);
  const { terminals, refetch, loadingTerminals } = useTerminals();
  const statusCheckInterval = useRef<
    string | number | NodeJS.Timeout | undefined
  >();
  const statusCheckIntervalAnnual = useRef<
    string | number | NodeJS.Timeout | undefined
  >();
  const { setIsOpen, setSteps, setCurrentStep, currentStep, isOpen } =
    useTour();

  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [annualizations, setAnnualizations] = useState<Annualization[]>([]);
  const [filteredAnnualizations, setFilteredAnnualizations] = useState<
    Annualization[]
  >([]);
  const [selectedAnnualization, setSelectedAnnualization] = useState<
    Annualization | undefined
  >(undefined);
  const [loadingAnnualization, setLoadingAnnualization] =
    useState<boolean>(true);

  const [filterValues, setFilterValues] = useState({
    terminal: "",
    costCenter: "",
    vehicleType: "",
  });

  const [annualId, setAnnualId] = useState<number>(0);
  const [annualName, setAnnualName] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transformedData, setTransformedData] = useState<
    Configuration[] | never[]
  >([]);
  const [loadingSummaryConfs, setLoadingSummaryConfs] = useState<boolean>(true);

  const [annualPayload, setAnnualPayload] = useState<AnnualPayload>({
    name: "",
    utilityRateId: 0,
    statusId: 4,
    vehicleTypeId: 0,
    facilityId: 0,
    propertyId: 0,
    terminalAnnualizationScenario: [],
  });

  const [rowPerPages, setRowPerPages] = useState<{
    configurations: number;
    annualizations: number;
  }>({
    configurations: 10,
    annualizations: 10,
  });

  useEffect(() => {
    if (
      selectedTerminalIdForPopup &&
      selectedFacilityIdForPopup &&
      selectedTerminalNameForPopup &&
      selectedFacilityNameForPopup
    ) {
      setOpenAddDialog(true);
    }
  }, [
    selectedTerminalIdForPopup,
    selectedFacilityIdForPopup,
    selectedTerminalNameForPopup,
    selectedFacilityNameForPopup,
  ]);

  useEffect(() => {
    if (
      currentStep >= 4 &&
      currentStep < 9 &&
      transitionState === 1 &&
      !openAddDialog
    ) {
      setTransitionState(2);
      setIsOpen(false);
      setOpenAddDialog(true);
    } else if (currentStep >= 4 && currentStep < 9 && transitionState === 2) {
      setTransitionState(1);
      setIsOpen(true);
    } else if ((currentStep >= 9 || currentStep < 4) && transitionState === 1) {
      setOpenAddDialog(false);
    }
    if (currentStep >= 11 && transitionState === 1 && !selectedScenario) {
      // setSelectedScenario as the first scenario in terminals object
      setSelectedScenario(
        terminals[0].terminalFacilities[0].terminalScenarios[0]
      );
      setTransitionState(2);
      setIsOpen(false);
    } else if (currentStep >= 11 && transitionState === 2) {
      setTransitionState(1);
      setIsOpen(true);
    } else if (currentStep < 11 && transitionState === 1 && selectedScenario) {
      setSelectedScenario(undefined);
      setTransitionState(2);
      setIsOpen(false);
    } else if (currentStep < 11 && transitionState === 2) {
      setTransitionState(1);
      setIsOpen(true);
    }
  }, [
    currentStep,
    transitionState,
    setIsOpen,
    openAddDialog,
    selectedScenario,
    terminals,
  ]);

  useEffect(() => {
    if (!isOpen) setTransitionState(0);
    else setTransitionState(1);
  }, [isOpen]);

  const getTerminalFinance = async (
    terminalId: number,
    scenarioId: number,
    facilityId: number,
    scenarioVehicleId: number,
    utilityId: number,
    chargerCost: number,
    vehicleCost: number,
    installationCost: number,
    fuelCostGrowthRate: number,
    electricCostGrowthRate: number
  ) => {
    const apiToken = await getToken();
    const currentYear = new Date().getFullYear();
    // These below valuse are constant now, but we will change after.
    const financialData: FinancialData | undefined = await loadFinancialData({
      terminalId,
      facilityId,
      scenarioId,
      scenarioVehicleId,
      financialControls: {
        discountRate: 0.04,
        fuelCostGrowthRate,
        planningStartYear: currentYear,
        planningHorizonYears: 12,
        ppaRate: 0.4,
        investmentStrategy: "upfront",
        chargerCost,
        vehicleCost,
        installationCost,
        utilityRateId: utilityId,
        attributableInstallationCost: 0,
        electricCostGrowthRate,
      },
      apiToken,
    });
    return (
      financialData?.costComparisonTableData.evOptimizedChargingData
        .totalExpensesNPV || 0
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSummaryConfs(true);
      const promises = terminals.flatMap((terminal, terminalIndex) =>
        terminal.terminalFacilities.flatMap((facility, facilityIndex) =>
          facility.terminalScenarios
            .filter((scenario) => scenario.status?.status === "SUCCESS")
            .flatMap((scenario, scenarioIndex) =>
              scenario.scenarioVehicles.map(async (vehicle, vehicleIndex) => {
                const tco = await getTerminalFinance(
                  terminal.id,
                  scenario.id,
                  facility.id,
                  vehicle?.id,
                  scenario.utilityRateStructureId,
                  scenario.chargerCost || 0,
                  scenario.vehicleCost || 0,
                  scenario.installationCost || 0,
                  scenario.fuelCostGrowthRate,
                  scenario.electricCostGrowthRate
                );

                return {
                  id: `${terminalIndex}-${facilityIndex}-${scenarioIndex}-${vehicleIndex}`,
                  name: scenario.name,
                  terminal: terminal.name,
                  costCenters: facility.name,
                  vehicleId: vehicle?.vehicleType?.id || 0,
                  vehicleType: vehicle?.vehicleType?.name || "",
                  vehicleModelId: vehicle?.evVehicle?.id || 0,
                  vehicleModelName:
                    vehicle?.evVehicle?.name ||
                    `${vehicle.evVehicle?.make} ${vehicle.evVehicle?.model}`,
                  utilityrateName: scenario.utilityRateStructure?.name,
                  noevs: vehicle.fleetSize || vehicle.numICEVehiclesToElectrify,
                  nocharger: scenario.optNumChargers,
                  tco: formatCurrency(tco),
                  terminalId: terminal.id,
                  facilityId: facility.id,
                  scenarioId: scenario.id,
                  chargerId: scenario?.charger?.id,
                  chargerName:
                    scenario?.charger?.name ||
                    `${scenario.charger.make} ${scenario.charger.model}`,
                };
              })
            )
        )
      );

      const results = await Promise.all(promises);
      setLoadingSummaryConfs(false);
      setTransformedData(results.filter(Boolean));
    };

    fetchData();
  }, [terminals]);

  const allTerminals = useMemo(() => {
    const terminalMap = new Map<string, { id: number; name: string }>();
    terminals.forEach((terminal) => {
      if (!terminalMap.has(terminal.name)) {
        terminalMap.set(terminal.name, {
          id: terminal.id,
          name: terminal.name,
        });
      }
    });
    return Array.from(terminalMap.values());
  }, [terminals]);

  const costCenters = useMemo(() => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      costCenter: "",
      vehicleType: "",
    }));
    const costCenterMap = new Map<string, { id: number; name: string }>();
    const filteredTerminals = filterValues
      ? terminals.filter((terminal) => terminal.name === filterValues.terminal)
      : terminals;

    filteredTerminals.forEach((terminal) =>
      terminal?.terminalFacilities.forEach((facility) => {
        if (!costCenterMap.has(facility.name)) {
          costCenterMap.set(facility.name, {
            id: facility.id,
            name: facility.name,
          });
        }
      })
    );
    return Array.from(costCenterMap.values());
  }, [terminals, filterValues.terminal]);

  const vehicleTypes = useMemo(() => {
    const vehicleTypeMap = new Map<number, { id: number; name: string }>();
    const filteredTerminals = filterValues
      ? terminals.filter((terminal) => terminal.name === filterValues.terminal)
      : terminals;

    filteredTerminals.forEach((terminal) =>
      terminal.terminalFacilities.forEach((facility) => {
        if (facility.name === filterValues.costCenter) {
          facility.terminalScenarios.forEach((scenario) =>
            scenario.scenarioVehicles.forEach((vehicle) => {
              if (vehicle.vehicleType) {
                vehicleTypeMap.set(vehicle.vehicleType.id, vehicle.vehicleType);
              }
            })
          );
        }
      })
    );
    return Array.from(vehicleTypeMap.values());
  }, [terminals, filterValues.terminal, filterValues.costCenter]);

  useEffect(() => {
    if (selectedScenario) {
      const matchingTerminal = terminals.find(
        (t) => t.id === selectedScenario.propertyId
      );
      const matchingFacility = matchingTerminal?.terminalFacilities.find(
        (f) => f.id === selectedScenario.facilityId
      );
      setScenarios(matchingFacility?.terminalScenarios || []);
    }
  }, [terminals, selectedScenario]);

  useEffect(() => {
    let filteredConfigurations = [...transformedData];
    let filteredAnnualizations = [...annualizations];
    if (filterValues.terminal) {
      filteredConfigurations = filteredConfigurations.filter(
        (row) => row.terminal === filterValues.terminal
      );
      filteredAnnualizations = filteredAnnualizations.filter(
        (row) => row.property.name === filterValues.terminal
      );
    }
    if (filterValues.vehicleType) {
      filteredConfigurations = filteredConfigurations.filter(
        (row) => row.vehicleType === filterValues.vehicleType
      );
      filteredAnnualizations = filteredAnnualizations.filter(
        (row) => row.vehicleType.name === filterValues.vehicleType
      );
    }
    if (filterValues.costCenter) {
      filteredConfigurations = filteredConfigurations.filter(
        (row) => row.costCenters === filterValues.costCenter
      );
      filteredAnnualizations = filteredAnnualizations.filter(
        (row) => row.terminalFacility.name === filterValues.costCenter
      );
    }

    setConfigurations(filteredConfigurations);
    setFilteredAnnualizations(filteredAnnualizations);
  }, [filterValues, transformedData, terminals, annualizations]);

  useEffect(() => {
    const inProgressIds = terminals.reduce((ids: number[], terminal) => {
      terminal.terminalFacilities.forEach((facility) => {
        facility.terminalScenarios.forEach((scenario) => {
          if (scenario.status && scenario.status.status === "IN-PROGRESS") {
            ids.push(scenario.id);
          }
        });
      });
      return ids;
    }, []);

    clearInterval(statusCheckInterval.current);

    if (inProgressIds.length) {
      statusCheckInterval.current = setInterval(async () => {
        const apiToken = await getToken();
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_HOST}:${
              process.env.REACT_APP_API_PORT
            }/terminals/check-status?ids=${inProgressIds.join(",")}`,
            {
              headers: {
                Authorization: `Bearer ${apiToken}`,
              },
            }
          );

          if (response.ok) {
            const result = await response.json();
            const hasStatusChange = result.filter(
              (scenarioStatus: any) => scenarioStatus.status !== "IN-PROGRESS"
            );
            if (
              hasStatusChange.length ||
              result.length !== inProgressIds.length
            ) {
              refetch();
            }
          }
        } catch (error: unknown) {
          console.error(
            `Failed to retrieve scenarios status: ${inProgressIds}`
          );
        }
      }, 15000); // every 15s
    }
  }, [terminals, refetch, getToken]);

  async function loadAnnualizationData() {
    setLoadingAnnualization(true);
    try {
      const apiToken = await getToken();
      const data = await loadAnnualizations(apiToken);
      setAnnualizations(data);
      return data;
    } catch (error) {
      console.error("Failed to load annualization data", error);
      setAnnualizations([]);
    } finally {
      setLoadingAnnualization(false);
    }
  }

  async function checkAnnualizationStatus(
    inProgressIds: number[],
    sumbittedIds: number[]
  ) {
    try {
      const apiToken = await getToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${
          process.env.REACT_APP_API_PORT
        }/terminals/annualizations/check-status?ids=${[
          ...inProgressIds,
          ...sumbittedIds,
        ].join(",")}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const inProgressData = result.filter(
          (item: Annualization) => item.status.status === "IN-PROGRESS"
        );

        const submittedData = result.filter(
          (item: Annualization) => item.status.status === "SUBMITTED"
        );

        if ([...inProgressData, ...submittedData].length === 0) {
          clearInterval(statusCheckIntervalAnnual.current);
          await loadAnnualizationData();
        } else {
          if (
            inProgressData.length < inProgressIds.length ||
            submittedData.length < sumbittedIds.length
          ) {
            await loadAnnualizationData();
          }
        }
      }
    } catch (error) {
      console.error(
        `Failed to retrieve annualization status: ${inProgressIds}`,
        error
      );
    }
  }

  useEffect(() => {
    loadAnnualizationData();
  }, []);

  useEffect(() => {
    const getInProgressIds = () =>
      annualizations
        .filter((item) => item.status.status === "IN-PROGRESS")
        .map((item) => item.id);

    const getSubmittedIds = () =>
      annualizations
        .filter((item) => item.status.status === "SUBMITTED")
        .map((item) => item.id);

    const inProgressIds = getInProgressIds();
    const sumbittedIds = getSubmittedIds();

    if (statusCheckIntervalAnnual.current) {
      clearInterval(statusCheckIntervalAnnual.current);
    }

    if (inProgressIds.length > 0 || sumbittedIds.length > 0) {
      statusCheckIntervalAnnual.current = setInterval(() => {
        checkAnnualizationStatus(inProgressIds, sumbittedIds);
      }, 15000); // Runs every 15s
    }

    return () => {
      if (statusCheckIntervalAnnual.current) {
        clearInterval(statusCheckIntervalAnnual.current);
      }
    };
  }, [annualizations]);

  const navigateToScenario = (
    terminalId: number,
    facilityId: number,
    scenarioId: number
  ) => {
    setSelectedScenario(
      terminals
        .filter((t) => t.id === terminalId)[0]
        .terminalFacilities.filter((f) => f.id === facilityId)[0]
        .terminalScenarios.filter((s) => s.id === scenarioId)[0]
    );
  };

  const navigateToAnnualization = (annualizationId: number) => {
    setSelectedAnnualization(
      annualizations.filter((annual) => annual.id === annualizationId)[0]
    );
  };

  const onDialogClose = () => {
    setOpenAddDialog(false);
    setSelectedTerminalIdForPopup(0);
    setSelectedTerminalNameForPopup("");
    setSelectedFacilityIdForPopup(0);
    setSelectedFacilityNameForPopup("");
    setSelectedOrganizationIdForPopup(0);
  };

  const handleDownloadDialogClose = () => {
    setOpenDownloadDialog(false);
  };

  const confirmDeleteAnnualization = (annualId: number, annualName: string) => {
    setAnnualId(annualId);
    setAnnualName(annualName);
    setOpenDialog(true);
  };

  const configurationsColumns = [
    {
      field: " ",
      header: " ",
      width: 40,
      sortable: false,
      renderCell: (params: { row: Configuration }) => (
        <Checkbox
          sx={{
            minWidth: "30px",
            "&.Mui-disabled": {
              "& .MuiSvgIcon-root": {
                fill: (theme) => theme.palette.grey[300],
                backgroundColor: (theme) => theme.palette.grey[300],
                borderRadius: "4px",
                width: "60%",
                height: "60%",
                border: 1,
              },
            },
            padding: "0px",
            margin: "0px",
          }}
          disabled={
            filterValues.costCenter === "" ||
            filterValues.terminal === "" ||
            filterValues.vehicleType === ""
          }
          checked={Boolean(
            annualPayload.terminalAnnualizationScenario?.find(
              (y) => y.scenarioId === params.row.scenarioId
            )
          )}
          onClick={() => {
            setAnnualPayload((prevState: AnnualPayload) => {
              const updatedVehicles = [
                ...(prevState.terminalAnnualizationScenario || []),
              ];
              prevState.propertyId =
                allTerminals.find((t) => t.id === params.row.terminalId)?.id ||
                0;
              prevState.facilityId = params.row.facilityId;
              prevState.vehicleTypeId = params.row.vehicleId;
              const existingIndex = updatedVehicles.findIndex(
                (y) => y.scenarioId === params.row.scenarioId
              );
              if (existingIndex > -1) {
                updatedVehicles.splice(existingIndex, 1);
              } else {
                const newScenario = {
                  scenarioId: params.row.scenarioId,
                  scenarioName: params.row.name,
                  scenarioVehicleId: params.row.vehicleId,
                  scenarioVehicleName: params.row.vehicleType,
                  scenarioVehicleModelId: params.row.vehicleModelId,
                  scenarioVehicleModelName: params.row.vehicleModelName,
                  chargerId: params.row.chargerId,
                  chargerName: params.row.chargerName,
                  daysApplied: [],
                };
                const firstEmptyIndex = updatedVehicles.findIndex(
                  (y) => !y.scenarioVehicleName
                );
                if (firstEmptyIndex > -1) {
                  updatedVehicles.splice(firstEmptyIndex, 0, newScenario);
                } else {
                  updatedVehicles.push(newScenario);
                }
              }
              return {
                ...prevState,
                terminalAnnualizationScenario: updatedVehicles,
              };
            });
          }}
        />
      ),
    },
    {
      field: "terminal",
      headerName: "Terminal",
      flex: 1,
      sortable: false,
    },
    {
      field: "costCenters",
      headerName: "Cost Center",
      flex: 2,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Configuration Name",
      flex: 2,
      sortable: false,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "utilityrateName",
      headerName: "Utility Rate Name",
      flex: 2,
      sortable: false,
    },
    {
      field: "nocharger",
      headerName: "Chargers",
      flex: 1,
      sortable: false,
    },
    { field: "noevs", headerName: "EVs", flex: 1, sortable: false },
    {
      field: "tco",
      headerName: "TCO",
      flex: 1,
      sortable: false,
      align: "right" as GridAlignment,
      headerAlign: "right" as GridAlignment,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="View Configuration">
              <Visibility />
            </Tooltip>
          }
          label="View"
          className="view-action"
          onClick={() =>
            navigateToScenario(
              params.row.terminalId,
              params.row.facilityId,
              params.row.scenarioId
            )
          }
        />,
      ],
    },
  ];

  const annualizationColumns = [
    {
      field: "name",
      headerName: "Annualization Name",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "property.name",
      headerName: "Terminal",
      flex: 0.7,
      valueGetter: (params: any) => params.row.property.name,
      sortable: false,
    },
    {
      field: "terminalFacility.name",
      headerName: "Cost Center",
      flex: 0.7,
      valueGetter: (params: any) => params.row.terminalFacility.name,
      sortable: false,
    },
    {
      field: "vehicleType.name",
      headerName: "Vehicle Type",
      flex: 1.5,
      valueGetter: (params: any) => params.row.vehicleType?.name,
      sortable: false,
    },
    {
      field: "property.address",
      headerName: "Location",
      flex: 1,
      valueGetter: (params: any) => params.row.property.address,
      sortable: false,
    },
    {
      field: "utilityRates.name",
      headerName: "Utility Rate Applied",
      flex: 1,
      valueGetter: (params: any) => params.row.utilityRates.name,
      sortable: false,
    },
    {
      field: "evVehicle.name",
      headerName: "EV Vehicle",
      flex: 1,
      valueGetter: (params: any) => {
        const { evVehicle } = params.row;
        if (!evVehicle) return "Computing...";

        const name =
          evVehicle.name ||
          `${evVehicle.make ?? ""} ${evVehicle.model ?? ""}`.trim();
        return name || "Computing...";
      },
      sortable: false,
    },
    {
      field: "fleetSize",
      flex: 0.7,
      headerName: "Fleet Size",
      sortable: false,
    },
    {
      field: "charger.name",
      headerName: "Charger",
      flex: 1,
      valueGetter: (params: any) => {
        const { charger } = params.row;
        if (!charger) return "Computing...";

        const name =
          charger.name || `${charger.make ?? ""} ${charger.model ?? ""}`.trim();
        return name || "Computing...";
      },
      sortable: false,
    },
    {
      field: "status.status",
      valueGetter: (params: any) => params.row.status?.status,
      headerName: "Status",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="View Annualization">
              <Visibility />
            </Tooltip>
          }
          disabled={params.row.status?.id !== 1 || false}
          label="View"
          className="view-action"
          onClick={() => navigateToAnnualization(params.row.id)}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Delete Annualization">
              <Delete />
            </Tooltip>
          }
          label="View"
          onClick={() =>
            confirmDeleteAnnualization(params.row.id, params.row.name)
          }
        />,
      ],
    },
  ];

  const handleOpenAnnualDialog = () => {
    setOpenAnnualDialog(!openAnnualDialog);
  };

  const addNewConfigurations = () => {
    const values = { ...annualPayload };
    values.terminalAnnualizationScenario.push({
      scenarioId: 0,
      scenarioName: "",
      scenarioVehicleId: 0,
      scenarioVehicleName: "",
      scenarioVehicleModelId: 0,
      scenarioVehicleModelName: "",
      chargerId: 0,
      chargerName: "",
      daysApplied: [],
    });
    setAnnualPayload(values);
  };

  const removeNewConfigurations = (index: number) => {
    const values = { ...annualPayload };
    values.terminalAnnualizationScenario.splice(index, 1);
    setAnnualPayload(values);
  };

  const handleSubmit = async () => {
    setLoadingAnnualization(true);
    const apiToken = await getToken();
    try {
      await addNewAnnualization(annualPayload, apiToken);
      await loadAnnualizationData();
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedAnnualIds([]);
      setAnnualPayload({
        name: "",
        utilityRateId: 0,
        statusId: 4,
        vehicleTypeId: 0,
        facilityId: 0,
        propertyId: 0,
        terminalAnnualizationScenario: [],
      });
      setLoadingAnnualization(false);
    }
  };

  const updateSelectedAnnualIds = (annualId: number) => {
    const ids = [...selectedAnnualIds];
    if (ids.includes(annualId)) {
      ids.splice(ids.indexOf(annualId), 1);
    } else ids.push(annualId);
    setSelectedAnnualIds(ids);
  };

  const downloadAnnualPlans = async () => {
    setDownloadingAnnualization(true);
    const apiToken = await getToken();
    await annualizationDownload(selectedAnnualIds, apiToken);
    setDownloadingAnnualization(false);
  };

  const deleteAnnualization = async (annualId: number) => {
    setLoading(true);
    try {
      const apiToken = await getToken();
      const response = await disableAnnualPlan(annualId, apiToken);
      if (response) {
        loadAnnualizationData();
      } else {
        console.log("Error deleting annual plan.");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      {selectedScenario ? (
        <TerminalPage
          scenarios={scenarios}
          terminals={terminals}
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
          refetchData={refetch}
          isTutorial={isOpen}
          tutorialStep={currentStep}
          setIsOpen={setIsOpen}
          goBack={() => {
            setSelectedScenario(undefined);
            refetch();
          }}
          refetchTerminal={refetch}
        />
      ) : (
        <>
          {selectedAnnualization ? (
            <AnnualizationPage
              selectedAnnualization={selectedAnnualization}
              setSelectedAnnualization={(annualId) =>
                setSelectedAnnualization(annualId)
              }
            />
          ) : (
            <Stack direction={"row"} sx={{ height: "100%" }}>
              <SiteList
                terminals={terminals}
                annualizations={annualizations}
                selectedAnnualIds={selectedAnnualIds}
                setSelectedAnnualIds={(annualId: number) =>
                  updateSelectedAnnualIds(annualId)
                }
                setSelectedAnnualization={setSelectedAnnualization}
                navigateToScenario={navigateToScenario}
                setSteps={setSteps}
                setCurrentStep={setCurrentStep}
                setIsOpen={setIsOpen}
                selectedTerminalIds={selectedTerminalIds}
                setSelectedTerminalIds={setSelectedTerminalIds}
                setSelectedTerminalIdForPopup={setSelectedTerminalIdForPopup}
                setSelectedFacilityIdForPopup={setSelectedFacilityIdForPopup}
                setSelectedTerminalNameForPopup={
                  setSelectedTerminalNameForPopup
                }
                setSelectedFacilityNameForPopup={
                  setSelectedFacilityNameForPopup
                }
                setSelectedOrganizationIdForPopup={
                  setSelectedOrganizationIdForPopup
                }
                setOpenDownloadDialog={setOpenDownloadDialog}
                downloadAnnualPlans={() => downloadAnnualPlans()}
                setTransitionState={setTransitionState}
                isTutorial={isOpen}
                tutorialStep={currentStep}
                refetchTerminal={refetch}
                deleteAnnualization={deleteAnnualization}
                downloadingAnnualization={downloadingAnnualization}
              />
              <Box
                sx={{
                  flex: 1,
                  position: "relative",
                  padding: "0 16px",
                  border: "0.5px solid silver",
                  overflowY: `${
                    loadingTerminals ||
                    loadingAnnualization ||
                    loadingSummaryConfs
                      ? "hidden"
                      : "scroll"
                  }`,
                  height: "100%",
                }}
                marginBottom={3}
              >
                <Backdrop
                  sx={{
                    color: "#FFFFFF",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                  }}
                  open={
                    loadingTerminals ||
                    loadingAnnualization ||
                    loadingSummaryConfs
                  }
                >
                  <Stack alignItems={"center"} spacing={2}>
                    <CircularProgress color="inherit" />
                    <Collapse in={loadingTerminals}>
                      <Typography variant="h3">Loading Terminals...</Typography>
                    </Collapse>
                    <Collapse in={loadingAnnualization}>
                      <Typography variant="h3">
                        Loading Annualizations...
                      </Typography>
                    </Collapse>
                    <Collapse in={loadingSummaryConfs}>
                      <Typography variant="h3">
                        Loading Summary of Configurations...
                      </Typography>
                    </Collapse>
                  </Stack>
                </Backdrop>
                <Grid container direction={"column"} marginBottom={2}>
                  <Grid item md>
                    <Section>
                      <Box sx={{ marginBottom: 2 }}>
                        <Typography
                          variant="controlTitle"
                          sx={{ fontWeight: 600 }}
                        >
                          Configuration Filters
                        </Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item md>
                          <Grid container direction={"column"} spacing={3}>
                            <Grid item md>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="terminal-center-label">
                                  Terminal *
                                </InputLabel>
                                <Select
                                  labelId="terminal-center-label"
                                  value={filterValues.terminal || ""}
                                  onChange={(event) => {
                                    const values = { ...annualPayload };
                                    values.propertyId =
                                      allTerminals.find(
                                        (t) => t.name === event.target.value
                                      )?.id || 0;
                                    values.terminalAnnualizationScenario = [];
                                    setAnnualPayload(values);
                                    setFilterValues({
                                      ...filterValues,
                                      terminal: event.target.value,
                                    });
                                  }}
                                  label="Terminal"
                                >
                                  {allTerminals.map(
                                    (vehicle, index: number) => (
                                      <MenuItem
                                        value={vehicle.name}
                                        key={index}
                                      >
                                        {vehicle.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="vehicle-center-label">
                                  Vehicle Type *
                                </InputLabel>
                                <Select
                                  labelId="vehicle-center-label"
                                  value={filterValues.vehicleType || ""}
                                  onChange={(event) => {
                                    setFilterValues({
                                      ...filterValues,
                                      vehicleType: event.target.value,
                                    });
                                    const values = { ...annualPayload };
                                    values.vehicleTypeId =
                                      vehicleTypes.find(
                                        (t) => t.name === event.target.value
                                      )?.id || 0;
                                    values.terminalAnnualizationScenario = [];
                                    setAnnualPayload(values);
                                  }}
                                  label="Vehicle Center"
                                >
                                  {vehicleTypes.map(
                                    (vehicle, index: number) => (
                                      <MenuItem
                                        value={vehicle.name}
                                        key={index}
                                      >
                                        {vehicle.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md>
                          <Grid container direction={"column"} spacing={3}>
                            <Grid item md>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="cost-center-label">
                                  Cost Center *
                                </InputLabel>
                                <Select
                                  labelId="cost-center-label"
                                  value={filterValues.costCenter || ""}
                                  onChange={(event) => {
                                    setFilterValues({
                                      ...filterValues,
                                      costCenter: event.target.value,
                                    });
                                    const values = { ...annualPayload };
                                    values.facilityId =
                                      costCenters.find(
                                        (t) => t.name === event.target.value
                                      )?.id || 0;
                                    values.terminalAnnualizationScenario = [];
                                    setAnnualPayload(values);
                                  }}
                                  label="Cost Center"
                                >
                                  {costCenters.map(
                                    (costCenter, index: number) => (
                                      <MenuItem
                                        value={costCenter.name}
                                        key={index}
                                      >
                                        {costCenter.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="warning"
                                startIcon={<ClearAll />}
                                onClick={() => {
                                  setFilterValues({
                                    costCenter: "",
                                    terminal: "",
                                    vehicleType: "",
                                  });
                                  setAnnualPayload({
                                    name: "",
                                    utilityRateId: 0,
                                    statusId: 4,
                                    vehicleTypeId: 0,
                                    facilityId: 0,
                                    propertyId: 0,
                                    terminalAnnualizationScenario: [],
                                  });
                                }}
                                sx={{ marginTop: 1 }}
                              >
                                Clear all dropdown selections
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box display="flex" alignItems="center" marginTop={2}>
                        <InfoOutlined fontSize="small" color="action" />
                        <Typography
                          variant="subtitle2"
                          color="GrayText"
                          marginLeft={1}
                        >
                          To create an annual plan, you need to select the
                          terminal, cost center, and vehicle type.
                        </Typography>
                      </Box>
                    </Section>
                  </Grid>
                  <Grid item md sx={{ height: "100%" }}>
                    <Section>
                      <Box sx={{ marginBottom: 3 }}>
                        <Typography
                          variant="controlTitle"
                          sx={{ fontWeight: 600 }}
                        >
                          Configurations Workspace
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          minHeight: 450,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                        }}
                        className="terminal-list-table"
                      >
                        <DataGrid
                          columns={configurationsColumns}
                          rows={configurations}
                          hideFooterSelectedRowCount
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          pageSize={rowPerPages.configurations}
                          onPageSizeChange={(pageSize) =>
                            setRowPerPages({
                              ...rowPerPages,
                              configurations: pageSize,
                            })
                          }
                          selectionModel={[]}
                          disableColumnMenu
                          autoHeight
                        />
                      </Box>
                    </Section>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={
                          annualPayload.terminalAnnualizationScenario.length ===
                            0 ||
                          filterValues.costCenter === "" ||
                          filterValues.terminal === "" ||
                          filterValues.vehicleType === ""
                        }
                        sx={{ marginLeft: 3 }}
                        onClick={() => handleOpenAnnualDialog()}
                      >
                        Create an Annual Plan
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md sx={{ height: "100%" }}>
                    <Section>
                      <Box sx={{ marginBottom: 3 }}>
                        <Typography
                          variant="controlTitle"
                          sx={{ fontWeight: 600 }}
                        >
                          Annual Plans Workspace
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          minHeight: 450,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                        }}
                        className="terminal-list-table"
                      >
                        <DataGrid
                          columns={annualizationColumns}
                          rows={filteredAnnualizations}
                          hideFooterSelectedRowCount
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          pageSize={rowPerPages.annualizations}
                          onPageSizeChange={(pageSize) =>
                            setRowPerPages({
                              ...rowPerPages,
                              annualizations: pageSize,
                            })
                          }
                          selectionModel={[]}
                          disableColumnMenu
                          autoHeight
                        />
                      </Box>
                    </Section>
                  </Grid>
                </Grid>
              </Box>
              {openAddDialog && (
                <AddScenarioDialog
                  DialogProps={{
                    open: openAddDialog,
                    onClose: onDialogClose,
                  }}
                  selectedTerminal={{
                    id: selectedTerminalIdForPopup,
                    name: selectedTerminalNameForPopup,
                  }}
                  selectedFacility={{
                    id: selectedFacilityIdForPopup,
                    name: selectedFacilityNameForPopup,
                  }}
                  organizationId={selectedOrganizationIdForPopup}
                  terminals={terminals}
                  refetchData={refetch}
                />
              )}
              {openDownloadDialog && (
                <SelectDownloadDialog
                  open={openDownloadDialog}
                  handleClose={handleDownloadDialogClose}
                  selectedTerminalIds={selectedTerminalIds}
                  terminals={terminals}
                />
              )}
              {openAnnualDialog && (
                <AddAnnualizationDialog
                  DialogProps={{
                    open: openAnnualDialog,
                    onClose: handleOpenAnnualDialog,
                  }}
                  vehicleTypes={vehicleTypes}
                  annualizationNames={annualizations
                    .filter((annual) => annual.name)
                    .map((annual) => annual.name)}
                  filterValues={filterValues}
                  configurations={configurations}
                  addNewConfigurations={() => addNewConfigurations()}
                  removeNewConfigurations={(index) =>
                    removeNewConfigurations(index)
                  }
                  annualPayload={annualPayload}
                  onUpdateVehicleType={(vehicleType: string) => {
                    const annualCopy = { ...annualPayload };
                    annualCopy.terminalAnnualizationScenario =
                      annualCopy.terminalAnnualizationScenario.slice(0, 1);
                    setAnnualPayload(annualCopy);
                    setFilterValues({
                      ...filterValues,
                      vehicleType: vehicleType,
                    });
                  }}
                  setAnnualPayload={setAnnualPayload}
                  onSubmit={() => handleSubmit()}
                />
              )}
            </Stack>
          )}
        </>
      )}

      {/* Delete Annual plan Popup */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          {`Do you want to delete the annual plan (${annualName || ""})?`}
        </DialogTitle>
        <DialogActions>
          <LoadingButton
            color="error"
            onClick={() => {
              setOpenDialog(false);
              deleteAnnualization(annualId);
            }}
            loading={loading}
            variant="text"
          >
            Delete
          </LoadingButton>
          <Button
            color="inherit"
            sx={{ color: "black" }}
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
