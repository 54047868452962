import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TcoBarChart, { TcoLegend } from "./charts/TcoBarChart";
import { useEffect, useMemo, useState } from "react";
import { FleetScenario } from "types/fleets";

function getTotalCost(scenario: FleetScenario) {
  scenario.laborCost = 0;
  return (
    scenario.totalFuelCost +
    scenario.insuranceCost +
    scenario.downtimeCost +
    scenario.maintenanceCost +
    scenario.otherCost +
    scenario.batteryReplacementCost +
    scenario.fleetVehicles.reduce((acc, vehicle) => {
      return acc + vehicle.vehicleCosts;
    }, 0) +
    scenario.fleetChargerLevels.reduce((acc, charger) => {
      return acc + charger.chargerCost;
    }, 0)
  );
}

export type TotalCostOfOwnershipProps = {
  scenario?: FleetScenario;
  referenceScenarios: FleetScenario[] | undefined;
  referenceScenarioId?: number;
};

function TotalCostOfOwnership({
  scenario,
  referenceScenarios,
  referenceScenarioId,
}: TotalCostOfOwnershipProps) {
  const [selectedReferenceScenarioId, setSelectedReferenceScenarioId] =
    useState<number | undefined>(referenceScenarioId);

  useEffect(() => {
    if (
      referenceScenarioId &&
      referenceScenarios &&
      referenceScenarios.length > 0
    ) {
      setSelectedReferenceScenarioId(referenceScenarioId);
    }
  }, [referenceScenarioId, referenceScenarios]);

  const referenceScenario = referenceScenarios?.find(
    (scenario) => scenario.id === selectedReferenceScenarioId
  );

  const isSameScenario = useMemo(() => {
    const getBaseName = (name?: string) => name?.split(" - ")[0];
    return getBaseName(scenario?.name) === getBaseName(referenceScenario?.name);
  }, [scenario, referenceScenario]);

  let suggestedMax = 0;
  let differenceInCost = 0;

  if (isSameScenario && scenario && referenceScenario) {
    const [evScenario, iceScenario] = scenario.name.includes("EV")
      ? [scenario, referenceScenario]
      : [referenceScenario, scenario];

    const evCost = getTotalCost(evScenario);
    const iceCost = getTotalCost(iceScenario);

    suggestedMax = Math.max(evCost, iceCost);
    differenceInCost = Math.max(0, evCost - iceCost);
  }

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
          Total Cost of Ownership
          {/* <Typography component="span">
            ({scenario?.planningHorizon || 10} year amortization)
          </Typography> */}
        </Typography>
      </Box>

      {referenceScenarios && (
        <FormControl fullWidth>
          <InputLabel id="reference-scenario-select-label">
            Select Reference Scenario
          </InputLabel>
          <Select
            labelId="reference-scenario-select-label"
            value={referenceScenario ? referenceScenario.id : ""}
            onChange={(e) =>
              setSelectedReferenceScenarioId(
                e.target.value === "" ? undefined : (e.target.value as number)
              )
            }
            label="Select Reference Scenario"
          >
            <MenuItem value={""}>None</MenuItem>
            {referenceScenarios.map((reference) => {
              if (reference.id !== scenario?.id) {
                return (
                  <MenuItem key={reference.id} value={reference.id}>
                    {reference.name}
                  </MenuItem>
                );
              }
              return null; // Return null if the condition is not met
            })}
          </Select>
        </FormControl>
      )}

      <Stack>
        <Stack>
          {scenario && (
            <TcoBarChart
              fleetElectificationScenario={scenario}
              disableXaxis={true}
              title={scenario?.name || "This Scenario"}
              suggestedMax={suggestedMax}
              referenceScenario={referenceScenario}
            />
          )}
        </Stack>
        <TcoLegend />
        {selectedReferenceScenarioId && isSameScenario && (
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Incentive for breakeven:
            <span>{` $${Math.ceil(differenceInCost).toLocaleString()}`}</span>
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default TotalCostOfOwnership;
