import { useEffect, useState } from "react";
import { HubUtilityRate, HubUtilityRateNew } from "types/hub-utility-rate";
import { UtilityRateStructureNewInput } from "types/terminal-financial";
import { useAccessToken } from "utils/get-access-token";

type UseSiteUtilityRatesResult = {
  utilityRates: HubUtilityRate[];
  loadingSiteUtilityRates: boolean;
  error: Error | undefined;
};

export const useSiteUtilityRates = (
  siteId: number
): UseSiteUtilityRatesResult => {
  const [utilityRates, setUtilityRates] = useState<HubUtilityRate[]>([]);
  const [loadingSiteUtilityRates, setLoadingSiteUtilityRates] =
    useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const { getToken } = useAccessToken();

  const fetchData = async () => {
    setLoadingSiteUtilityRates(true);

    const apiToken = await getToken();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/hub/sites/${siteId}/utility-rates`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      const data: HubUtilityRate[] = await response.json();

      setUtilityRates(data);
      setLoadingSiteUtilityRates(false);
    } catch (error) {
      setError(error as Error);
      setLoadingSiteUtilityRates(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [siteId]);

  return { utilityRates, loadingSiteUtilityRates, error };
};

type UseHubUtilityRatesResult = {
  utilityRates: HubUtilityRateNew[];
  loadingHubUtilityRates: boolean;
  error: Error | undefined;
  refetch: () => void;
};

export const useHubUtilityRates = (
  siteId: number
): UseHubUtilityRatesResult => {
  const [utilityRates, setUtilityRates] = useState<HubUtilityRateNew[]>([]);
  const [loadingHubUtilityRates, setLoadingHubUtilityRates] =
    useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const { getToken } = useAccessToken();

  const fetchData = async () => {
    setLoadingHubUtilityRates(true);

    const apiToken = await getToken();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/hub/sites/${siteId}/v2/utility-rates`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      const data: HubUtilityRateNew[] = await response.json();

      setUtilityRates(data);
      setLoadingHubUtilityRates(false);
    } catch (error) {
      setError(error as Error);
      setLoadingHubUtilityRates(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const refetch = () => {
    fetchData();
  };

  return { utilityRates, loadingHubUtilityRates, error, refetch };
};

// Upload new utility rate using excel file.
export async function uploadHubUtilityRate(
  utilityRates: UtilityRateStructureNewInput,
  siteId: number,
  apiToken: string
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/hub/sites/${siteId}/utility-rates`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(utilityRates),
  });

  if (!response.ok) {
    throw new Error("Failed to create utility rate.");
  }
  return response.json();
}
