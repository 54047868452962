import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  AlertColor,
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  Snackbar,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { updateAnnualizationFinancialControls } from "api/terminal/financial";
import {
  loadAnnualization,
  useAnnualFinancialData,
} from "api/terminal/terminals-annualization";
import { Section } from "components/Section";
import { annualizationDownload } from "dashboard/terminal/annualizationDownload";
import { useEffect, useState } from "react";
import { Annualization } from "types/terminal";
import { useAccessToken } from "utils/get-access-token";
import {
  calculateAnnualMoneySpendInformation,
  calculateMetaInformation,
} from "utils/terminal-annual-calculation";
import UtilitySpendChart from "./chart/UtilitySpendChart";
import AnnualizationMetaInformation from "./components/AnnualMetaInformation";
import AnnualMoneySpendInformation from "./components/AnnualMoneySpendInformation";
import AnnualizationSideBar from "./components/AnnualSideBar";
import UtilityRateTable from "./components/UtilityRateStructureTable";
import { AnnualizationFinancial } from "./financial/AnnualizationFinancial";
import { annualFinancialDownload } from "./financial/financialDownload";

type AnnualizationPageProps = {
  selectedAnnualization: Annualization;
  setSelectedAnnualization: (annualId: Annualization | undefined) => void;
};

export type AnnualFinancialControl = {
  discountRate: number;
  fuelCostGrowthRate: number;
  planningStartYear: number;
  planningHorizonYears: number;
  ppaRate: number;
  investmentStrategy: string;
  chargerCost: number;
  vehicleCost: number;
  totalInstallationCost: number;
  electricCostGrowthRate: number;
};

const initialFinancialControl: AnnualFinancialControl = {
  discountRate: 0.04,
  fuelCostGrowthRate: 0.05,
  planningStartYear: new Date().getFullYear(),
  planningHorizonYears: 12,
  ppaRate: 0,
  investmentStrategy: "upfront",
  chargerCost: 0,
  vehicleCost: 0,
  totalInstallationCost: 0,
  electricCostGrowthRate: 0.0202,
};

const AnnualizationPage = ({
  selectedAnnualization,
  setSelectedAnnualization,
}: AnnualizationPageProps) => {
  const { getToken } = useAccessToken();
  const [annualData, setAnnualData] = useState<Annualization | undefined>(
    undefined
  );

  const [financialControls, setFinancialControls] =
    useState<AnnualFinancialControl>(initialFinancialControl);

  const [annualMoneySpendInformation, setAnnuaMoneySpendInformation] = useState(
    {
      totalElectricityCost: 0,
      maximumElectricityCost: 0,
      totalCapEx: 0,
      totalOpEx: 0,
    }
  );
  const [metaInformation, setMetaInformation] = useState({
    highWorkLoadDays: 0,
    mediumWorkLoadDays: 0,
    lowWorkLoadDays: 0,
    peakMonthWorkload: "",
    peakMonthPowerDraw: "",
    peakMonthEnergyConsumption: "",
  });
  const [loadingAnnualization, setLoadingAnnualization] =
    useState<boolean>(true);
  const [downloadingAnnualization, setDownloadingAnnualization] =
    useState<boolean>(false);

  const [downloadingFinancial, setDownloadingFinancial] =
    useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState("summary");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    type: AlertColor;
    message: string;
  }>({
    type: "success",
    message: "",
  });

  const { financialData, loadingAnnualFinancialData } = useAnnualFinancialData({
    annualizationId: selectedAnnualization.id,
    financialControls,
  });

  async function loadAnnualData() {
    setLoadingAnnualization(true);
    const apiToken = await getToken();
    const annualizations: Annualization = await loadAnnualization(
      selectedAnnualization.id,
      apiToken
    );
    setAnnualData(annualizations);
    setLoadingAnnualization(false);
  }

  useEffect(() => {
    loadAnnualData();
  }, []);

  useEffect(() => {
    if (!annualData) return;

    setFinancialControls((prev) => ({
      ...prev,
      chargerCost: annualData.chargerPrice,
      vehicleCost: annualData.vehiclePrice,
      planningHorizonYears: annualData.planningHorizon,
      totalInstallationCost: annualData.totalInstallationCost,
      discountRate: annualData.discountRate,
      ppaRate: annualData.ppaRate,
      electricCostGrowthRate: annualData.electricCostGrowthRate,
      fuelCostGrowthRate: annualData.fuelCostGrowthRate,
    }));
  }, [annualData]);

  useEffect(() => {
    if (financialData?.utilityCost) {
      setAnnuaMoneySpendInformation(
        calculateAnnualMoneySpendInformation(financialData)
      );
      setMetaInformation(calculateMetaInformation(financialData));
    }
  }, [financialData]);

  const downloadAnnualizationHandler = async () => {
    setDownloadingAnnualization(true);
    const apiToken = await getToken();
    let customFinancialControls: any = {
      ...annualData,
      financial: financialData,
    };
    await annualizationDownload(
      [selectedAnnualization.id],
      apiToken,
      customFinancialControls,
      financialControls
    );
    setDownloadingAnnualization(false);
  };

  const downloadFinancialHandler = async () => {
    setDownloadingFinancial(true);
    if (annualData && financialData)
      await annualFinancialDownload(
        annualData,
        financialData,
        financialControls
      );
    setDownloadingFinancial(false);
  };

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newTab: string
  ) => {
    setCurrentTab(newTab);
  };

  const handleUpdateFinancialScenarioCosts = async () => {
    const apiToken = await getToken();
    try {
      await updateAnnualizationFinancialControls(
        selectedAnnualization.id,
        financialControls,
        apiToken
      );
      setSnackbar({
        type: "success",
        message: "Successfully updated financial scenario costs",
      });
      setShowSnackbar(true);
      loadAnnualData();
    } catch (error) {
      setSnackbar({
        type: "error",
        message: "Failed to update financial scenario costs",
      });
      setShowSnackbar(true);
    }
  };

  return (
    <Stack direction={"row"} sx={{ height: "100%" }}>
      <Backdrop
        sx={{
          color: "#FFFFFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        }}
        open={loadingAnnualFinancialData || loadingAnnualization}
      >
        <Stack alignItems={"center"} spacing={2}>
          <CircularProgress color="inherit" />
          <Collapse in={loadingAnnualFinancialData}>
            <Typography variant="h3">Loading Annual Finance...</Typography>
          </Collapse>
          <Collapse in={loadingAnnualization}>
            <Typography variant="h3">Loading Annualizations...</Typography>
          </Collapse>
        </Stack>
      </Backdrop>
      <AnnualizationSideBar
        annualData={annualData}
        workload={financialData?.workLoads.configs?.[0]}
        downloadHandler={downloadAnnualizationHandler}
        setSelectedAnnualization={setSelectedAnnualization}
        downloadFinancialHandler={downloadFinancialHandler}
        isFinancialDownloading={downloadingFinancial}
        isDownloading={downloadingAnnualization}
      />
      <Stack
        direction={"column"}
        sx={{ flex: 1, width: "80%", overflowY: "scroll" }}
      >
        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList onChange={handleTabChange} variant={"fullWidth"}>
              <Tab
                label={"Summary"}
                value={"summary"}
                className="summary-tab"
              />
              <Tab
                label={"Financial"}
                value={"financial"}
                className="financial-tab"
              />
            </TabList>
          </Box>
          <TabPanel value={"summary"} className="chart-tab-panel">
            <Box sx={{ padding: 1 }}>
              <Section>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginBottom={3}
                  marginTop={2}
                >
                  <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                    Annual Plan Summary - {selectedAnnualization.name}
                  </Typography>
                </Grid>
                <Grid container rowSpacing={2} columnSpacing={5} marginTop={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <Box>
                      <Typography
                        variant="controlTitle"
                        sx={{ fontWeight: 400, textDecoration: "underline" }}
                      >
                        Total Cost of Ownership
                      </Typography>
                    </Box>
                    <AnnualMoneySpendInformation
                      annualMoneySpendInformation={annualMoneySpendInformation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <Box>
                      <Typography
                        variant="controlTitle"
                        sx={{ fontWeight: 400, textDecoration: "underline" }}
                      >
                        Plan Metadata
                      </Typography>
                    </Box>
                    <AnnualizationMetaInformation
                      metaInformation={metaInformation}
                    />
                  </Grid>
                </Grid>
              </Section>
              <Grid container rowSpacing={2} columnSpacing={2} marginBottom={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <Section>
                    <Box sx={{ marginBottom: 3 }}>
                      <Typography
                        variant="controlTitle"
                        sx={{ fontWeight: 600 }}
                      >
                        Utility Expenses (Monthly)
                      </Typography>
                    </Box>
                    <Box height={345}>
                      {financialData && (
                        <UtilitySpendChart
                          financialInformation={financialData}
                        />
                      )}
                    </Box>
                  </Section>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <Section>
                    <Box>
                      <Typography
                        variant="controlTitle"
                        sx={{ fontWeight: 600 }}
                      >
                        Utility Rate Structure
                      </Typography>
                      <UtilityRateTable
                        utilityRateId={selectedAnnualization.utilityRateId}
                      />
                    </Box>
                  </Section>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={"financial"} className="chart-tab-panel">
            {financialData && (
              <AnnualizationFinancial
                financialData={financialData}
                financialControls={financialControls}
                setFinancialControls={setFinancialControls}
                updateFinancialControls={handleUpdateFinancialScenarioCosts}
              />
            )}
            <Snackbar
              open={showSnackbar}
              autoHideDuration={6000}
              onClose={() => setShowSnackbar(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert severity={snackbar.type}>{snackbar.message}</Alert>
            </Snackbar>
          </TabPanel>
        </TabContext>
      </Stack>
    </Stack>
  );
};

export default AnnualizationPage;
