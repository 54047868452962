import { AnnualFinancialControl } from "dashboard/terminal/pages/annualization/Annualization";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { useCallback, useState } from "react";
import { AnnualPayload } from "types/terminal";
import { AnnualFinance } from "types/terminal-annualization";
import { useAccessToken } from "utils/get-access-token";

export async function loadAnnualizations(apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualizations.");
  }
  return response.json();
}

export async function loadAnnualization(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualizations.");
  }
  return response.json();
}

export async function loadAnnualFinancial(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}/financial`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualization financial.");
  }
  return response.json();
}

export async function addNewAnnualization(
  annualizations: AnnualPayload,
  apiToken: string
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(annualizations),
  });

  if (!response.ok) {
    throw new Error("Failed to create annualization.");
  }
  return response.json();
}

export async function disableAnnualPlan(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete annualization.");
  }
  return response.json();
}

export async function loadAnnualDownload(annualId: number, apiToken: string) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualId}/download`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch annualization financial.");
  }
  return response.json();
}

const DEBOUNCE_DELAY = 500;

type UseAnnualizationFinancialData = {
  financialData: AnnualFinance | null;
  loadingAnnualFinancialData: boolean;
  errorLoadingAnnualFinancialData: Error | null;
  refetch: () => void;
};
type FinancialProps = {
  annualizationId: number;
  financialControls: AnnualFinancialControl;
  apiToken?: string;
};
export function useAnnualFinancialData({
  annualizationId,
  financialControls,
}: FinancialProps): UseAnnualizationFinancialData {
  const { getToken } = useAccessToken();
  const [financialData, setAnnualFinancialData] =
    useState<AnnualFinance | null>(null);
  const [loadingAnnualFinancialData, setLoadingAnnualFinancialData] =
    useState<boolean>(false);
  const [errorLoadingAnnualFinancialData, setErrorLoadingAnnualFinancialData] =
    useState<Error | null>(null);

  const fetchData = async () => {
    setLoadingAnnualFinancialData(true);
    setErrorLoadingAnnualFinancialData(null);
    const apiToken = await getToken();

    try {
      const urlParams = new URLSearchParams();

      Object.keys(financialControls).forEach((key) => {
        const value = financialControls[key as keyof typeof financialControls];
        urlParams.append(key, value.toString());
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualizationId}/financial?${urlParams}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: AnnualFinance = await response.json();
        setAnnualFinancialData(result);
      } else {
        setAnnualFinancialData(null);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingAnnualFinancialData(error);
      }
    }

    setLoadingAnnualFinancialData(false);
  };

  const delayedFetchData = useCallback(fetchData, [
    annualizationId,
    financialControls,
    getToken,
  ]);

  useDebouncedEffect(
    delayedFetchData,
    [annualizationId, financialControls],
    DEBOUNCE_DELAY
  );
  const refetch = () => {
    fetchData();
  };

  return {
    financialData,
    loadingAnnualFinancialData,
    errorLoadingAnnualFinancialData,
    refetch,
  };
}
