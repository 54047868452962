import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { ScenarioYearlyParam, SegmentData } from "types/hub-scenario-data";
import { SiteVehicleList } from "types/hub-site-vehicle";
import { HubUtilityRateNew } from "types/hub-utility-rate";

type ParameterTableRow = {
  year: number;
  truckEvAdoptionRate: number;
  trailerEvAdoptionRate: number;
  carEvAdoptionRate: number;
  lightDutyEvAdoptionRate: number;
  maxUtilitySupply: number;
};

type ParametersProp = {
  siteName: string | undefined;
  scenarioName: string | undefined;
  utilityRate: HubUtilityRateNew | null | undefined;
  carData: SegmentData | undefined;
  LightDutyData: SegmentData | undefined;
  truckData: SegmentData | undefined;
  trailerData: SegmentData | undefined;
  carYearlyParams: ScenarioYearlyParam[];
  lightDutyYearlyParams: ScenarioYearlyParam[];
  truckYearlyParams: ScenarioYearlyParam[];
  trailerYearlyParams: ScenarioYearlyParam[];
  siteVehicles: SiteVehicleList;
};
const ParametersBox = ({
  siteName,
  scenarioName,
  utilityRate,
  truckData,
  trailerData,
  truckYearlyParams,
  trailerYearlyParams,
  carData,
  carYearlyParams,
  LightDutyData,
  lightDutyYearlyParams,
  siteVehicles,
}: ParametersProp) => {
  const spaceRequirements = useMemo(() => {
    const requirements = [];

    if (siteVehicles.cars && carData?.vehicle_parking_space_requirement) {
      requirements.push(
        `Car spot (plus overhead): ${carData.vehicle_parking_space_requirement} sq. ft.`
      );
    }

    if (
      siteVehicles.lightDutyVehicle &&
      LightDutyData?.vehicle_parking_space_requirement
    ) {
      requirements.push(
        `Light Truck spot (plus overhead): ${LightDutyData.vehicle_parking_space_requirement} sq. ft.`
      );
    }
    if (siteVehicles.trucks && truckData?.vehicle_parking_space_requirement) {
      requirements.push(
        `Straight Truck spot (plus overhead): ${truckData.vehicle_parking_space_requirement} sq. ft.`
      );
    }

    if (
      siteVehicles.trailers &&
      trailerData?.vehicle_parking_space_requirement
    ) {
      requirements.push(
        `Tractor-Trailer spot (plus overhead): ${trailerData.vehicle_parking_space_requirement} sq. ft.`
      );
    }

    return requirements.join(", ");
  }, [siteVehicles, carData, LightDutyData, truckData, trailerData]);

  const tableData = useMemo(() => {
    const data: Record<number, ParameterTableRow> = {};

    const processYearlyParams = (
      yearlyParams: typeof truckYearlyParams,
      key: keyof ParameterTableRow
    ) => {
      yearlyParams.forEach(({ year, ev_adoption_rate, max_utility_supply }) => {
        if (!data[year]) {
          data[year] = { year } as ParameterTableRow;
        }
        data[year][key] = ev_adoption_rate;
        data[year].maxUtilitySupply = max_utility_supply;
      });
    };

    processYearlyParams(truckYearlyParams, "truckEvAdoptionRate");
    processYearlyParams(trailerYearlyParams, "trailerEvAdoptionRate");
    processYearlyParams(carYearlyParams, "carEvAdoptionRate");
    processYearlyParams(lightDutyYearlyParams, "lightDutyEvAdoptionRate");

    return Object.values(data);
  }, [
    carYearlyParams,
    lightDutyYearlyParams,
    truckYearlyParams,
    trailerYearlyParams,
  ]);

  return (
    <Box
      sx={{
        border: 0.5,
        borderColor: "silver",
        padding: "16px",
        borderRadius: 5,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
          {`Parameters for ${siteName ?? ""}`}
        </Typography>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <List dense>
        <ListItem disableGutters>
          <ListItemText
            primary="EV adoption scenario:"
            secondary={scenarioName ?? ""}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Utility Name"
            secondary={utilityRate?.name ?? ""}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem disableGutters>
          <ListItemText
            primary="Space Requirements"
            secondary={spaceRequirements}
          />
        </ListItem>
      </List>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              {siteVehicles.cars && (
                <TableCell align="right">EV adoption % (Car)</TableCell>
              )}
              {siteVehicles.lightDutyVehicle && (
                <TableCell align="right">EV adoption % (Light Truck)</TableCell>
              )}
              {siteVehicles.trucks && (
                <TableCell align="right">
                  EV adoption % (Straight Truck)
                </TableCell>
              )}
              {siteVehicles.trailers && (
                <TableCell align="right">
                  EV adoption % (Tractor-Trailer)
                </TableCell>
              )}
              <TableCell align="right">Utility Supply (MW)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              ?.sort((a, b) => a.year - b.year)
              ?.map((row) => (
                <TableRow
                  key={row.year}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.year}
                  </TableCell>
                  {siteVehicles.cars && (
                    <TableCell align="right">
                      {!isNaN(row.carEvAdoptionRate)
                        ? (row.carEvAdoptionRate * 100).toFixed(2)
                        : ""}
                    </TableCell>
                  )}
                  {siteVehicles.lightDutyVehicle && (
                    <TableCell align="right">
                      {!isNaN(row.lightDutyEvAdoptionRate)
                        ? (row.lightDutyEvAdoptionRate * 100).toFixed(2)
                        : ""}
                    </TableCell>
                  )}
                  {siteVehicles.trucks && (
                    <TableCell align="right">
                      {!isNaN(row.truckEvAdoptionRate)
                        ? (row.truckEvAdoptionRate * 100).toFixed(2)
                        : ""}
                    </TableCell>
                  )}
                  {siteVehicles.trailers && (
                    <TableCell align="right">
                      {!isNaN(row.trailerEvAdoptionRate)
                        ? (row.trailerEvAdoptionRate * 100).toFixed(2)
                        : ""}
                    </TableCell>
                  )}
                  <TableCell align="right">{row.maxUtilitySupply}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ParametersBox;
