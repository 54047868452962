import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  BarElement,
  BarController,
  Legend,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FleetScenario } from "types/fleets";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

const colors = {
  vehicle: "#05C2CC",
  charger: "#FFEDCC",
  fuel: "#6D7675",
  insurance: "#BEC4C2",
  downtime: "#E6D5E9",
  maintenance: "#A3C6DD",
  other: "#A3E0D5",
  labor: "#F6B4B0",
};

function getTotalMiles(fleetElectrificationScenario: FleetScenario) {
  return fleetElectrificationScenario.fleetVehicles.reduce((acc, vehicle) => {
    return acc + vehicle.totalAnnualMileage;
  }, 0);
}

function createDatasetFromFleetElectrificationScenario(
  fleetElectrificationScenario: FleetScenario,
  referenceScenario?: FleetScenario
): ChartDataset<"bar", number[]>[] {
  const costCategories = [
    { key: "vehicleCost", label: "Vehicle Costs", color: colors.vehicle },
    { key: "chargerCost", label: "Charger Costs", color: colors.charger },
    { key: "totalFuelCost", label: "Fuel/Energy Costs", color: colors.fuel },
    { key: "insuranceCost", label: "Insurance Costs", color: colors.insurance },
    {
      key: "maintenanceCost",
      label: "Maintenance Costs",
      color: colors.maintenance,
    },
    { key: "downtimeCost", label: "Downtime Costs", color: colors.downtime },
    {
      key: "batteryReplacementCost",
      label: "Battery Replacement Costs",
      color: colors.other,
    },
  ] as const;

  // Reset costs based on scope
  if (!fleetElectrificationScenario.scope.includes("Vehicles")) {
    Object.assign(fleetElectrificationScenario, {
      vehicleCost: 0,
      totalFuelCost: 0,
      laborCost: 0,
      downtimeCost: 0,
      batteryReplacementCost: 0,
      maintenanceCost: 0,
      insuranceCost: 0,
    });
  }

  if (!fleetElectrificationScenario.scope.includes("Chargers/EVSE")) {
    fleetElectrificationScenario.chargerCost = 0;
  }

  const datasets: ChartDataset<"bar", number[]>[] = costCategories.map(
    ({ key, label, color }) => ({
      label,
      data: [fleetElectrificationScenario[key]],
      borderColor: "#000000",
      backgroundColor: color,
      borderWidth: 1,
    })
  );

  // Add reference scenario data if available
  if (referenceScenario) {
    datasets.forEach((dataset, index) => {
      const key = costCategories[index].key as keyof FleetScenario;
      if (referenceScenario[key] !== undefined) {
        const value = referenceScenario[key];
        // Ensure only `number` values are added
        dataset.data.push(typeof value === "number" ? value : 0);
      }
    });
  }

  return datasets;
}

function formatMoney(amount: number) {
  return `$${Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(amount)}`;
}

export type TcoBarChartProps = {
  fleetElectificationScenario: FleetScenario;
  disableXaxis?: boolean;
  title: string;
  suggestedMax?: number;
  referenceScenario?: FleetScenario;
};

export default function TcoBarChart({
  fleetElectificationScenario: fleetElectrificationScenario,
  title,
  disableXaxis,
  suggestedMax,
  referenceScenario,
}: TcoBarChartProps) {
  const totalMiles = getTotalMiles(fleetElectrificationScenario);
  const labels = [fleetElectrificationScenario.name]; // without labels, no data appears
  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "TCO Chart",
        align: "start",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const cost = context.parsed.x as number;
            const totalCostStr = formatMoney(cost);
            // const costPerMileStr = formatMoney(
            //   cost /
            //     ((totalMiles ?? 0) *
            //       fleetElectrificationScenario?.planning_horizon) // 10 years of miles because costs are amortized over 10 years
            // ); commented out for now. we may add it in future.
            return `${context.dataset.label}: ${totalCostStr}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        suggestedMax: suggestedMax,
        ticks: {
          display: disableXaxis,
          callback: function (value, index, ticks) {
            return formatMoney(value as number);
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const datasets =
    fleetElectrificationScenario !== undefined
      ? createDatasetFromFleetElectrificationScenario(
          fleetElectrificationScenario,
          referenceScenario
        )
      : [];

  if (referenceScenario) {
    labels.push(referenceScenario.name);
  }

  const data = {
    labels,
    datasets,
  };

  return (
    <Box sx={{ height: "200px" }}>
      <Chart type="bar" options={options} data={data} />
    </Box>
  );
}

export function TcoLegend() {
  return (
    <Box sx={{ padding: "2em" }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.vehicle,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Vehicle</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.downtime,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Downtime</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.charger,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Charger</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.maintenance,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Maintenance</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.fuel,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Fuel/Energy</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.other,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Battery Replacement</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.insurance,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Insurance</Typography>
          </Stack>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stack direction={"row"} spacing={1}>
            <Box
              sx={{
                height: 20,
                width: 20,
                backgroundColor: colors.labor,
                border: 0.3,
                borderRadius: 1,
              }}
            ></Box>
            <Typography>Labor</Typography>
          </Stack>
        </Grid> */}
      </Grid>
    </Box>
  );
}
