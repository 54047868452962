export async function loadPopupVehicles(
  apiToken: string,
  vehicleTypeId: number,
  terminalId: number
) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/${terminalId}/scenario/popup/vehicles/${vehicleTypeId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch vehicle information.");
  }
  return response.json();
}

export async function loadPopupChargers(apiToken: string, terminalId: number) {
  const url = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/${terminalId}/scenario/popup/chargers`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch charger information.");
  }
  return response.json();
}
