import { useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { HubCharger } from "types/hub-charger";
import { SiteVehicleList } from "types/hub-site-vehicle";

export interface VehicleChargerValues {
  carChargerId?: number;
  carChargerCost?: number;
  lightDutyChargerId?: number;
  lightDutyChargerCost?: number;
  truckChargerId?: number;
  truckChargerCost?: number;
  trailerChargerId?: number;
  trailerChargerCost?: number;
}

type ChargerChoicesProp = {
  chargers: HubCharger[];
  carChargerId: number | null;
  carChargerCost: number;
  lightDutyChargerId: number | null;
  lightDutyChargerCost: number;
  truckChargerId: number | null;
  truckChargerCost: number;
  trailerChargerId: number | null;
  trailerChargerCost: number;
  handleChargerValuesChange: (
    vehicleChargerValues: VehicleChargerValues
  ) => void;
  siteVehicles: SiteVehicleList;
};
export default function ChargerChoices({
  chargers,
  carChargerId,
  carChargerCost,
  lightDutyChargerId,
  lightDutyChargerCost,
  truckChargerId,
  truckChargerCost,
  trailerChargerId,
  trailerChargerCost,
  handleChargerValuesChange,
  siteVehicles,
}: ChargerChoicesProp) {
  const selectedChargerValues = useMemo(() => {
    const vehicleTypes = [
      {
        isEnabled: siteVehicles.cars || siteVehicles.lightDutyVehicle,
        chargerFor: "Cars",
        chargerId: carChargerId,
        chargerCost: carChargerCost,
        handleIdChange: (id: number) =>
          handleChargerValuesChange({ carChargerId: id }),
        handleCostChange: (cost: number) =>
          handleChargerValuesChange({ carChargerCost: cost }),
      },
      {
        isEnabled: siteVehicles.cars || siteVehicles.lightDutyVehicle,
        chargerFor: "Light Trucks",
        chargerId: lightDutyChargerId,
        chargerCost: lightDutyChargerCost,
        handleIdChange: (id: number) =>
          handleChargerValuesChange({ lightDutyChargerId: id }),
        handleCostChange: (cost: number) =>
          handleChargerValuesChange({ lightDutyChargerCost: cost }),
      },
      {
        isEnabled: siteVehicles.trucks || siteVehicles.trailers,
        chargerFor: "Straight Trucks",
        chargerId: truckChargerId,
        chargerCost: truckChargerCost,
        handleIdChange: (id: number) =>
          handleChargerValuesChange({ truckChargerId: id }),
        handleCostChange: (cost: number) =>
          handleChargerValuesChange({ truckChargerCost: cost }),
      },
      {
        isEnabled: siteVehicles.trucks || siteVehicles.trailers,
        chargerFor: "Tractor-Trailers",
        chargerId: trailerChargerId,
        chargerCost: trailerChargerCost,
        handleIdChange: (id: number) =>
          handleChargerValuesChange({ trailerChargerId: id }),
        handleCostChange: (cost: number) =>
          handleChargerValuesChange({ trailerChargerCost: cost }),
      },
    ];

    return vehicleTypes
      .filter(({ isEnabled }) => isEnabled)
      .map(
        ({
          chargerFor,
          chargerId,
          chargerCost,
          handleIdChange,
          handleCostChange,
        }) => ({
          chargerFor,
          chargerId: chargerId ?? chargers[0]?.id,
          chargerCost,
          handleChargerIdChange: handleIdChange,
          handleChargerCostChange: handleCostChange,
        })
      );
  }, [
    siteVehicles,
    carChargerId,
    chargers,
    carChargerCost,
    handleChargerValuesChange,
    lightDutyChargerId,
    lightDutyChargerCost,
    truckChargerId,
    truckChargerCost,
    trailerChargerId,
    trailerChargerCost,
  ]);

  return (
    <>
      <Typography sx={{ marginBottom: 2 }}>Charger Choices:</Typography>
      <Grid container columnSpacing={2}>
        {selectedChargerValues.map((selectedChargerValue, index) => (
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={index}>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="inherit" sx={{ marginBottom: 1 }}>
                {selectedChargerValue.chargerFor}
              </Typography>
              <FormControl sx={{ marginTop: 1.5, width: "100%" }}>
                <InputLabel id={`charger-rate-${index}`}>
                  Charger Rate
                </InputLabel>
                <Select
                  value={selectedChargerValue.chargerId ?? ""}
                  labelId={`charger-rate-${index}`}
                  label="Charger Rate"
                  onChange={(e) =>
                    selectedChargerValue.handleChargerIdChange(
                      Number(e.target.value)
                    )
                  }
                  sx={{ width: "100%" }}
                >
                  {chargers.map((charger, i) => (
                    <MenuItem key={i} value={charger.id}>
                      {`${charger.charge_rate_kw}KW`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-read-only-input"
                label="Charger Price"
                value={selectedChargerValue.chargerCost ?? 0}
                onChange={(e) =>
                  selectedChargerValue.handleChargerCostChange(
                    Number(e.target.value)
                  )
                }
                sx={{ width: "100%", marginTop: 1.5 }}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                key={index}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
