import { Warning } from "@mui/icons-material";
import {
  Box,
  Tooltip as HelperTooltip,
  Stack,
  Typography,
} from "@mui/material";
import {
  CategoryScale,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import { FleetScenario, FleetScenarioVehicle } from "types/fleets";
import { getPaletteColor } from "utils/color";
import { generate24HourLabels } from "utils/time";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export type PowerProfileChartProps = {
  scenario?: FleetScenario;
};

function calculateHourlyPowerDemandSum(vehicles: FleetScenarioVehicle[]) {
  const hourlyPowerDemandSum = Array.from({ length: 24 }, () => 0);
  vehicles.forEach((vehicle) => {
    vehicle.powerDemandProfile.forEach((profile) => {
      hourlyPowerDemandSum[profile.hour] +=
        profile.powerDemandKw ?? profile.power_demand_kw;
    });
  });
  return hourlyPowerDemandSum;
}

export function PowerProfileChart({ scenario }: PowerProfileChartProps) {
  let datasets: ChartDataset<"bar", number[]>[] = [];
  const [error, setError] = useState<{
    vehicleError: boolean;
    chargerError: boolean;
    vehicleWarning: boolean;
    message: string;
  }>({
    vehicleError: false,
    chargerError: false,
    vehicleWarning: false,
    message: "",
  });

  useEffect(() => {
    if (!scenario) return;

    // Find the max charger capacity and the number of chargers
    const maxChargerData = scenario.fleetChargerLevels.reduce(
      (maxCharger, charger) => {
        return charger.capacity > maxCharger.capacity
          ? {
              capacity: charger.capacity,
              num_of_chargers: charger.numOfChargers,
            }
          : maxCharger;
      },
      { capacity: 0, num_of_chargers: 0 }
    );

    const powerDemandError = scenario.fleetVehicles.some((vehicle) => {
      const totalPowerDemand = vehicle.powerDemandProfile.reduce(
        (totalPower, profile) => totalPower + profile.powerDemandKw,
        0
      );
      return Math.ceil(vehicle.energyRequired) > Math.ceil(totalPowerDemand);
    });

    const powerDemandHours = calculateHourlyPowerDemandSum(
      scenario.fleetVehicles
    );

    const exceedsCapacity = powerDemandHours.some(
      (power) =>
        power > maxChargerData.capacity * maxChargerData.num_of_chargers
    );
    let message = "";
    if (powerDemandError) {
      message = "Warning: Downtime is not enough to charge completely.";
    } else if (exceedsCapacity) {
      message = "Error: Charger capacity not enough to meet charging demand.";
    }

    setError({
      vehicleError: powerDemandError,
      chargerError: exceedsCapacity,
      vehicleWarning: powerDemandError,
      message,
    });
  }, [scenario]);

  if (scenario) {
    datasets = scenario.fleetVehicles.map((vehicle, i) => {
      const borderColor = getPaletteColor(i);
      const backgroundColor = borderColor;
      const data = vehicle.powerDemandProfile.map((powerDemand) =>
        scenario.scope && scenario.scope.includes("Chargers/EVSE")
          ? powerDemand.powerDemandKw ?? powerDemand.power_demand_kw
          : 0
      );
      return {
        type: "bar",
        label: vehicle.vehicleType,
        data,
        borderColor: borderColor.toString(),
        backgroundColor: backgroundColor.toString(),
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      } as ChartDataset<"bar", number[]>;
    });
  }

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        title: { text: "Hours of Day", display: true },
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        title: { text: "kW", display: true },
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  const data = {
    labels: generate24HourLabels(),
    datasets,
  };

  return (
    <Stack>
      <HelperTooltip title="Chargers are assumed to be able to draw power at rates lower than peak rating.">
        <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
          Power Use Profile{" "}
          <Typography component="span">(cumulative)</Typography>
        </Typography>
      </HelperTooltip>
      {(error.vehicleError || error.chargerError) && (
        <Stack direction={"row"} spacing={"3px"}>
          {error.vehicleWarning && <Warning color="error" />}
          <Typography
            variant="body1"
            sx={{
              color: error.vehicleError ? "#de1e09" : "#d32f2f",
              paddingTop: "3px",
            }}
          >
            {`${error.message}`}
          </Typography>
        </Stack>
      )}

      <Box sx={{ height: 250 }}>
        <Chart type={"bar"} options={options} data={data} />
      </Box>
      {scenario?.chargingStrategy && (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {`Charging Strategy: ${
            scenario?.chargingStrategy === "maximum-rating"
              ? "Charge to full ASAP (higher cost)"
              : "Charge slowly over idle hours (lower cost)"
          }`}
        </Typography>
      )}
    </Stack>
  );
}
