import { AnnualFinancialControl } from "dashboard/terminal/pages/annualization/Annualization";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { useCallback, useState } from "react";
import { FinancialControls, FinancialData } from "types/terminal-financial";
import { useAccessToken } from "utils/get-access-token";

const DEBOUNCE_DELAY = 500;

type UseFinancialData = {
  financialData: FinancialData | null;
  loadingFinancialData: boolean;
  errorLoadingFinancialData: Error | null;
  refetch: () => void;
};
type FinancialProps = {
  terminalId: number;
  facilityId: number;
  scenarioId: number;
  scenarioVehicleId: number;
  financialControls: FinancialControls;
  apiToken?: string;
};
export function useFinancialData({
  terminalId,
  facilityId,
  scenarioId,
  scenarioVehicleId,
  financialControls,
}: FinancialProps): UseFinancialData {
  const { getToken } = useAccessToken();
  const [financialData, setFinancialData] = useState<FinancialData | null>(
    null
  );
  const [loadingFinancialData, setLoadingFinancialData] =
    useState<boolean>(false);
  const [errorLoadingFinancialData, setErrorLoadingFinancialData] =
    useState<Error | null>(null);

  const fetchData = async () => {
    setLoadingFinancialData(true);
    setErrorLoadingFinancialData(null);
    const apiToken = await getToken();

    try {
      const urlParams = new URLSearchParams();

      Object.keys(financialControls).forEach((key) => {
        const value = financialControls[key as keyof typeof financialControls];
        urlParams.append(key, value.toString());
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/${terminalId}/facilities/${facilityId}/scenarios/${scenarioId}/${scenarioVehicleId}/financial?${urlParams}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.ok) {
        const result: FinancialData = await response.json();
        setFinancialData(result);
      } else {
        setFinancialData(null);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorLoadingFinancialData(error);
      }
    }

    setLoadingFinancialData(false);
  };

  const delayedFetchData = useCallback(fetchData, [
    terminalId,
    facilityId,
    scenarioId,
    scenarioVehicleId,
    financialControls,
  ]);

  useDebouncedEffect(
    delayedFetchData,
    [terminalId, facilityId, scenarioId, scenarioVehicleId, financialControls],
    DEBOUNCE_DELAY
  );
  const refetch = () => {
    fetchData();
  };

  return {
    financialData,
    loadingFinancialData,
    errorLoadingFinancialData,
    refetch,
  };
}

export async function loadFinancialData({
  terminalId,
  facilityId,
  scenarioId,
  scenarioVehicleId,
  financialControls,
  apiToken,
}: FinancialProps) {
  try {
    const urlParams = new URLSearchParams();
    Object.keys(financialControls).forEach((key) => {
      const value = financialControls[key as keyof typeof financialControls];
      urlParams.append(key, value.toString());
    });

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/${terminalId}/facilities/${facilityId}/scenarios/${scenarioId}/${scenarioVehicleId}/financial?${urlParams}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );

    if (response.ok) {
      const result: FinancialData = await response.json();
      return result;
    } else {
      return;
    }
  } catch (error: unknown) {
    throw Error("Error while fetching financial data.");
  }
}

export async function updateAnnualizationFinancialControls(
  annualizationId: number,
  financialControls: AnnualFinancialControl,
  apiToken: string
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/terminals/annualizations/${annualizationId}/financial-controls`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(financialControls),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update scenario costs");
    }
  } catch (error: unknown) {
    throw Error("Error while updating financial scenario costs.");
  }
}
