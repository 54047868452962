import * as ExcelJs from "exceljs";
import { saveAs } from "file-saver";
import { Annualization } from "types/terminal";
import { AnnualFinance } from "types/terminal-annualization";
import { CostComparisonRowData } from "types/terminal-financial";
import { AnnualFinancialControl } from "../Annualization";

export const annualFinancialDownload = async (
  annualData: Annualization,
  financialData: AnnualFinance,
  financialControls: AnnualFinancialControl
) => {
  const currentYear = new Date().getFullYear();
  const planningHorizon = financialControls.planningHorizonYears;

  const createEmptyArray = () => Array(planningHorizon).fill(0);

  const investmentCost = {
    vehicleCost: createEmptyArray(),
    chargerCost: createEmptyArray(),
    chargerInstallation: createEmptyArray(),
    batteryReplacement: createEmptyArray(),
    energyCost: createEmptyArray(),
    maintenanceCost: createEmptyArray(),
    totalCost: createEmptyArray(),
  };

  const {
    vehicleExpenses,
    chargerExpenses,
    installationExpenses,
    batteryExpenses,
  } = financialData.capitalExpenses;
  const { fuelCosts, maintenanceCosts } = financialData.operationalExpenses;

  const vehicleCostNPV = vehicleExpenses.reduce(
    (sum, cost) => sum + cost.totalNPV,
    0
  );
  const chargerCostNPV = chargerExpenses.reduce(
    (sum, cost) => sum + cost.totalNPV,
    0
  );
  const installationCostNPV = installationExpenses.reduce(
    (sum, cost) => sum + cost.totalNPV,
    0
  );
  const batteryReplacementNPV = batteryExpenses.reduce(
    (sum, cost) => sum + cost.totalNPV,
    0
  );
  const fuelCostNPV = fuelCosts.reduce((sum, cost) => sum + cost.costNPV, 0);
  const maintenanceCostNPV = maintenanceCosts.reduce(
    (sum, cost) => sum + cost.costNPV,
    0
  );

  const totalCostNPV =
    vehicleCostNPV +
    chargerCostNPV +
    installationCostNPV +
    batteryReplacementNPV +
    fuelCostNPV +
    maintenanceCostNPV;
  Array.from({ length: planningHorizon }, (_, index) => {
    const year = currentYear + index;
    investmentCost.vehicleCost[index] =
      vehicleExpenses.find((cost) => cost.year === year)?.total ?? 0;
    investmentCost.chargerCost[index] =
      chargerExpenses.find((cost) => cost.year === year)?.total ?? 0;
    investmentCost.chargerInstallation[index] =
      installationExpenses.find((cost) => cost.year === year)?.total ?? 0;
    investmentCost.batteryReplacement[index] =
      batteryExpenses.find((cost) => cost.year === year)?.total ?? 0;
    investmentCost.energyCost[index] =
      fuelCosts.find((cost) => cost.year === year)?.costUsd ?? 0;
    investmentCost.maintenanceCost[index] =
      maintenanceCosts.find((cost) => cost.year === year)?.costUsd ?? 0;
  });

  const extractComparisonRow = (
    label: string,
    key: keyof CostComparisonRowData
  ) => [
    "",
    label,
    financialData.costComparisonTableData.iceOnlyData[key],
    financialData.costComparisonTableData.evOpportunityChargingData[key],
    financialData.costComparisonTableData.evOptimizedChargingData[key],
  ];

  const comparisonData = [
    [
      "",
      "Configuration Type",
      financialData.costComparisonTableData.iceOnlyData.configurationType.toString(),
      financialData.costComparisonTableData.evOpportunityChargingData.configurationType.toString(),
      financialData.costComparisonTableData.evOptimizedChargingData.configurationType.toString(),
    ],
    extractComparisonRow("Vehicle Count (#)", "vehicleCount"),
    extractComparisonRow("Vehicle Price (USD)", "vehiclePrice"),
    extractComparisonRow(
      "Vehicle Replacement Cost (USD)",
      "vehicleReplacementCost"
    ),
    extractComparisonRow(
      "Total Vehicle Cost (USD) Over Planning Horizon",
      "totalVehicleCost"
    ),
    extractComparisonRow("Charger Count (#)", "chargerCount"),
    extractComparisonRow("Charger Price (USD)", "chargerPrice"),
    extractComparisonRow("Charger Installation Cost (USD)", "installationCost"),
    extractComparisonRow(
      "Charger Replacement Cost (USD)",
      "chargerReplacementCost"
    ),
    extractComparisonRow(
      "Total Charger Cost (USD) Over Planning Horizon",
      "totalChargerCost"
    ),
    extractComparisonRow(
      "Battery Replacement Cost (USD)",
      "batteryReplacementCost"
    ),
    extractComparisonRow(
      "Total Capital Expenses (Vehicle + Charger Costs; USD)",
      "totalCapitalExpenses"
    ),
    extractComparisonRow(
      "Total Operating Expenses (Fuel + Maintenance Costs; USD)",
      "totalOperationalExpenses"
    ),
    extractComparisonRow(
      "Total Expenses (CapEx + OpEx Across Planning Horizon; USD)",
      "totalExpenses"
    ),
    extractComparisonRow("Total NPV Expenses (USD)", "totalExpensesNPV"),
  ];

  const evRefAssumptions = [
    [
      "",
      "Fleet Size",
      "Vehicles on shift charge immediately after their shift ends. Fleet size is determined based on charging behavior, downtime, and other factors.",
    ],
    [
      "",
      "Vehicle Selection",
      "Select vehicles with the largest battery capacity and highest charging rate.",
    ],
    [
      "",
      "Charging Infrastructure",
      "The number of chargers matches the number of vehicles required on shift.",
    ],
    [
      "",
      "Charging Strategy",
      "Vehicles charge immediately after each shift ends, without considering utility rates or peak demand periods.",
    ],
  ];

  const wb = new ExcelJs.Workbook();
  const sheet1 = wb.addWorksheet("Financial Data");
  sheet1.addRow(["Financial Projection Outputs"]);
  sheet1.getCell("A1").font = { bold: true, underline: true };
  sheet1.addRow([
    "",
    "Optimal EV Count (#)",
    annualData.fleetSize - annualData.evReserve,
  ]);

  sheet1.addRow(["", "Number of EVs in Reserve (#)", annualData.evReserve]);
  sheet1.addRow(["", "Total Vehicle Count (#)", annualData.fleetSize]);

  sheet1.addRow(["", "Optimal Charger Count (#)", annualData.optNumChargers]);
  sheet1.addRow([
    "",
    "Vehicle Price (USD)",
    financialControls.vehicleCost || annualData.vehiclePrice,
  ]);

  sheet1.addRow([
    "",
    "Charger Price (USD)",
    financialControls.chargerCost || annualData.chargerPrice,
  ]);
  sheet1.addRow([
    "",
    "Charger Installation Cost Per Unit (USD))",
    Math.floor(
      financialData.financialInformation.totalCapitalExpenses
        .installationExpenses / annualData.optNumChargers
    ),
  ]);

  sheet1.addRow([
    "",
    "Vehicle CapEx (USD)",
    financialData.financialInformation.totalCapitalExpenses.vehicleExpenses,
  ]);
  sheet1.addRow([
    "",
    "Charger CapEx (USD)",
    financialData.financialInformation.totalCapitalExpenses.chargerExpenses,
  ]);

  sheet1.addRow([
    "",
    "Installation CapEx (USD)",
    financialControls.totalInstallationCost ||
      financialData.financialInformation.totalCapitalExpenses
        .installationExpenses,
  ]);
  sheet1.addRow([
    "",
    "Total CapEx (USD)",
    financialData.financialInformation.totalCapitalExpenses
      .totalCapitalExpenses,
  ]);

  sheet1.addRow([
    "",
    "Utility Cost (USD)",
    financialData.financialInformation.totalOperationalExpenses.fuelCosts,
  ]);
  sheet1.addRow([
    "",
    "Maintenance Cost (USD)",
    financialData.financialInformation.totalOperationalExpenses
      .maintenanceCosts,
  ]);
  sheet1.addRow([
    "",
    "Total Annual OpEx (USD)",
    financialData.financialInformation.totalOperationalExpenses
      .totalOperationalExpenses,
  ]);
  sheet1.addRow([
    "",
    "Net Present Value (USD)",
    financialData.totalCost.totalExpensesNPV,
  ]);

  let row = sheet1.getRow(17);
  row.addPageBreak();
  sheet1.addRow([]);

  sheet1.addRow(["Financial Controls"]);
  sheet1.getCell("A19").font = { bold: true, underline: true };
  sheet1.addRow([
    "",
    "Planning horizon years",
    financialControls.planningHorizonYears,
  ]);
  sheet1.addRow([
    "",
    "Fuel Cost Growth Rate (%)",
    financialControls.fuelCostGrowthRate * 100,
  ]);
  sheet1.addRow([
    "",
    "Electric Cost Growth Rate (%)",
    financialControls.electricCostGrowthRate * 100,
  ]);
  sheet1.addRow([
    "",
    "Discount Rate (%)",
    financialControls.discountRate * 100,
  ]);
  sheet1.addRow([
    "",
    "ICE Maintenance (%)",
    annualData?.configs[0].iceVehicleMaintenanceCostPct * 100,
  ]);
  sheet1.addRow([
    "",
    "EV Maintenance (%)",
    annualData?.configs[0].vehicleMaintenanceCostPct * 100,
  ]);
  sheet1.addRow([
    "",
    "Charger Maintenance (%)",
    annualData?.configs[0].chargerMaintenanceCostPct * 100,
  ]);

  row = sheet1.getRow(27);
  row.addPageBreak();
  sheet1.addRow([]);

  sheet1.addRow(["Investment Cost Schedule for EV - Optimized Charging"]);
  sheet1.getCell("A29").font = { bold: true, underline: true };
  sheet1.addRow([
    "",
    "Year",
    "NPV",
    ...Array.from(
      { length: financialControls.planningHorizonYears },
      (_, index) => currentYear + index
    ),
  ]);
  sheet1.addRow([
    "",
    "Vehicle Cost",
    vehicleCostNPV,
    ...investmentCost.vehicleCost,
  ]);
  sheet1.addRow([
    "",
    "Charger Cost",
    chargerCostNPV,
    ...investmentCost.chargerCost,
  ]);
  sheet1.addRow([
    "",
    "Charger Installation",
    installationCostNPV,
    ...investmentCost.chargerInstallation,
  ]);
  sheet1.addRow([
    "",
    "Battery Replacement",
    batteryReplacementNPV,
    ...investmentCost.batteryReplacement,
  ]);
  sheet1.addRow([
    "",
    "Optimized Energy Cost",
    fuelCostNPV,
    ...investmentCost.energyCost,
  ]);
  sheet1.addRow([
    "",
    "Maintenance Cost",
    maintenanceCostNPV,
    ...investmentCost.maintenanceCost,
  ]);
  sheet1.addRow(["", "Total", totalCostNPV, ...investmentCost.totalCost]);

  row = sheet1.getRow(38);
  row.addPageBreak();
  sheet1.addRow([]);

  sheet1.addRow(["Comparative Financial Data"]);
  sheet1.getCell("A40").font = { bold: true, underline: true };

  comparisonData.forEach((data) => sheet1.addRow(data));

  row = sheet1.getRow(56);
  row.addPageBreak();
  sheet1.addRow([]);

  sheet1.addRow(["EV Reference Case Assumptions"]);
  sheet1.getCell("A58").font = { bold: true, underline: true };

  evRefAssumptions.forEach((data) => sheet1.addRow(data));

  const filename = `annualization_financial_download-${annualData?.name}.xlsx`;
  await wb.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  });
};
