import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";

import {
  BubbleDataPoint,
  ChartData,
  ChartOptions,
  ScatterDataPoint,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { generateTimeLabels } from "utils/time";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

export type DemandChartProps = {
  variant: "power" | "energy";
  data?: number[];
  name?: string;
  utilityConstraint?: number;
  page: "hubs" | "terminals";
  comparisonData?: {
    name?: string | undefined;
    data?: number[];
  };
};

const terminalChartLabels = generateTimeLabels();
const hubsChartLabels = Array.from({ length: 24 }, (_, i) => {
  const period = i < 12 ? "AM" : "PM";
  const hour12 = i % 12 === 0 ? 12 : i % 12;
  return `${hour12}${period}`;
});

const ChargingProfileChart = ({
  variant,
  data,
  utilityConstraint,
  page,
  name,
  comparisonData,
}: DemandChartProps) => {
  // Add the first element to the end of the array to make the chart loop
  if (data && data.length > 0) {
    data.push(data[0]);
  }

  const isHubs = page === "hubs";

  const chartProperties = {
    chartTitle:
      variant === "power"
        ? "Power Demand Profile"
        : "Energy Consumption Profile",
    yAxisTitle:
      variant === "power" ? "Power Demand (kW)" : "Energy Consumption (kWh)",
    toolTipTitle: variant === "power" ? "Power Demand" : "Energy Consumption",
    units: variant === "power" ? "kW" : "kWh",
  };

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${chartProperties.units}`;
          },
        },
      },
      legend: {
        display: !isHubs && comparisonData?.name !== undefined,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: utilityConstraint
          ? [
              {
                type: "line",
                yMin: utilityConstraint,
                yMax: utilityConstraint,
                borderColor: "#ff0000",
                borderDash: [5, 5],
                borderWidth: 1,
              },
              {
                type: "label",
                xValue: 12,
                yValue: utilityConstraint
                  ? utilityConstraint * 1.05
                  : undefined,
                backgroundColor: "transparent",
                content: ["Utility threshold"],
                font: { size: 12 },
              },
            ]
          : [],
      },
    },
    scales: {
      x: {
        title: { display: false },
        stacked: page === "hubs",
        grid: { display: false },
        ticks: {
          autoSkip: true,
          callback(tickValue: any) {
            return tickValue % 12 === 0 ? this.getLabelForValue(tickValue) : "";
          },
        },
      },
      y: {
        title: { display: true, text: chartProperties.yAxisTitle },
        min: 0,
        stacked: page === "hubs",
      },
    },
  };

  let chartData: ChartData<"line" | "bar", number[]> = {
    labels: isHubs ? hubsChartLabels : terminalChartLabels,
    datasets: [
      {
        label: name ?? chartProperties.toolTipTitle,
        data: data ?? [],
        borderColor: "#05C2CC",
        backgroundColor:
          variant === "power"
            ? "rgba(5, 194, 204, 0.3)"
            : "rgba(5, 194, 204, 1)",
        order: 2,
        barPercentage: variant === "energy" ? 0.8 : undefined,
        categoryPercentage: variant === "energy" ? 0.8 : undefined,
        stepped: variant === "power",
        fill: variant === "power",
        borderWidth: variant === "power" ? 2 : undefined,
        pointRadius: variant === "power" ? 1 : 0,
        type: variant === "power" ? "line" : "bar",
        pointHoverRadius: variant === "power" ? 2 : 0,
      },
    ],
  };

  if (comparisonData) {
    chartData.datasets.push({
      label: comparisonData?.name ?? chartProperties.toolTipTitle,
      data: comparisonData?.data ?? [],
      borderColor: "#fdbe02",
      backgroundColor:
        variant === "power" ? "rgba(253, 253, 253, 0.97)" : "#ffdb6e",
      type: variant === "power" ? "line" : "bar",
      stepped: variant === "power",
      order: 1,
    });
  }

  return (
    <Chart
      type={variant === "power" ? "line" : "bar"}
      options={options}
      data={chartData}
    />
  );
};

export default ChargingProfileChart;
