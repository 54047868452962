import { loadDemand } from "api/demand";
import { VehicleClassAnnualDatum } from "types/vehicle-class";
import {
  scaleDemandByGrowthScenarioYear,
  scaleCorridorDemandByGrowthScenarioYear,
} from "utils/demand";
import { downloadFile } from "utils/file";
import { DemandPageProps } from "./DemandPage";
import { Feature, FeatureCollection } from "geojson";

const convertGeoJSONToWKT = (geojson: Feature) => {
  const geometry = geojson.geometry;

  if (geometry.type === "Point") {
    const [x, y] = geometry.coordinates;
    return `POINT (${x} ${y})`;
  }

  if (geometry.type === "Polygon") {
    const coordinates = geometry.coordinates[0];
    return `POLYGON ((${coordinates
      .map((coord) => coord.join(" "))
      .join(", ")}))`;
  }

  if (geometry.type === "MultiPolygon") {
    const polygons = geometry.coordinates.map(
      (polygon) =>
        `((${polygon[0].map((coord) => coord.join(" ")).join(", ")}))`
    );
    return `MULTIPOLYGON (${polygons.join(", ")})`;
  }

  throw new Error("Unsupported geometry type");
};

export const downloadDemand = async (
  data: any,
  props: DemandPageProps,
  annualData: VehicleClassAnnualDatum[] | undefined,
  year: number | undefined,
  demandTypes: string[],
  demandTypeRatios: any,
  demandSum: number,
  demandScaleFactor: number,
  corridorCheckbox: boolean,
  growthScenarioName?: string,
  apiToken?: string
) => {
  let downloadSuccess = true;

  try {
    const locationName = props.location?.name
      .toLowerCase()
      .replaceAll(" ", "_");
    growthScenarioName = growthScenarioName?.replaceAll(" ", "_");
    const keys = Object.keys(data);

    let demandTypesString = demandTypes.join("|");
    if (corridorCheckbox) {
      if (demandTypes.length > 0) {
        demandTypesString += "|";
      }
      demandTypesString += "Corridor";
    }

    let csvData = "";
    const appendData = (data: string = "") => {
      csvData += data;
      csvData += "\n";
    };

    appendData("Block Group Info,,,,,,,Demographics,,,,Demand (kWh) by hour");

    appendData(
      "Year,Season,Demand Types,Location,Block Group GeoId,Area (sq. mi.),Geometry," +
        "Poverty,Non-white,Multifamily Housing,Renter,12am,1am,2am,3am,4am,5am,6am," +
        "7am,8am,9am,10am,11am,12pm,1pm,2pm,3pm,4pm,5pm,6pm,7pm,8pm,9pm,10pm,11pm"
    );

    let hourlyFetchPromises = [];
    for (let hour = 0; hour < 24; hour++) {
      hourlyFetchPromises.push(
        loadDemand(
          props.selectedChargingDemandSimulation.id,
          demandTypes,
          hour,
          props.location?.id,
          apiToken
        )
      );
    }
    let hourlyDemandData = await Promise.all(hourlyFetchPromises);

    // if the corridor checkbox is checked, add the corridor demand to the public demand
    let hourlyCorridorFetchPromises = [];
    let hourlyCorridorDemandData: any[] = [];
    if (corridorCheckbox) {
      for (let hour = 0; hour < 24; hour++) {
        hourlyCorridorFetchPromises.push(
          loadDemand(
            props.selectedChargingDemandSimulation.id,
            ["corridor"],
            hour,
            props.location?.id,
            apiToken
          )
        );
      }
      hourlyCorridorDemandData = await Promise.all(hourlyCorridorFetchPromises);
    }

    let scaledHourlyDemand = [];
    if (
      annualData !== undefined &&
      props.selectedTrafficModel?.numVehicles !== undefined &&
      year !== undefined &&
      demandSum !== 0
    ) {
      if (hourlyCorridorDemandData.length > 0) {
        for (let i = 0; i < hourlyDemandData.length; i++) {
          let scaledHourlyCorridorDemandData =
            scaleCorridorDemandByGrowthScenarioYear(
              hourlyCorridorDemandData[i],
              annualData,
              year,
              demandScaleFactor
            );
          let scaledHourlyDemandData = scaleDemandByGrowthScenarioYear(
            annualData,
            year,
            hourlyDemandData[i],
            demandSum,
            demandTypes,
            demandTypeRatios
          );
          if (scaledHourlyDemandData) {
            for (let blockGroup in scaledHourlyDemandData) {
              if (blockGroup in scaledHourlyCorridorDemandData) {
                scaledHourlyDemandData[blockGroup] +=
                  scaledHourlyCorridorDemandData[blockGroup];
              }
            }
            scaledHourlyDemand.push(scaledHourlyDemandData);
          } else {
            scaledHourlyDemand.push(scaledHourlyCorridorDemandData);
          }
        }
      } else {
        for (let i = 0; i < hourlyDemandData.length; i++) {
          scaledHourlyDemand.push(
            scaleDemandByGrowthScenarioYear(
              annualData,
              year,
              hourlyDemandData[i],
              demandSum,
              demandTypes,
              demandTypeRatios
            )
          );
        }
      }
    }

    // verify that the number of block groups matches the number of demand values
    // before attempting to write the data to a CSV file
    if (
      scaledHourlyDemand[0] &&
      Object.keys(scaledHourlyDemand[0]).length !==
        props.blockGroups?.features.length
    ) {
      console.warn(
        "The number of block groups does not match the number of demand values"
      );
    }

    const squareMetersToSquareMiles = 3.86102e-7;
    const blockGroupToAreaMap = new Map(
      props.blockGroups?.features.map((item) => [
        item.properties?.geoid,
        {
          area: item.properties?.area,
          geometry: convertGeoJSONToWKT(item),
        },
      ])
    );

    for (let i = 0; i < keys.length; i++) {
      const rowData = [
        `${year ?? ""}`,
        `${props.ac === "high" ? "Winter/Summer" : "Spring/Fall"}`,
        demandTypesString,
        `${props.location?.name ?? ""}`,
        keys[i],
      ];
      rowData.push(
        (blockGroupToAreaMap.has(keys[i]) && blockGroupToAreaMap.get(keys[i])
          ? blockGroupToAreaMap.get(keys[i])?.area * squareMetersToSquareMiles
          : undefined
        )?.toFixed(2) ?? ""
      );
      rowData.push(
        (blockGroupToAreaMap.has(keys[i]) && blockGroupToAreaMap.get(keys[i])
          ? `"${blockGroupToAreaMap
              .get(keys[i])
              ?.geometry.replaceAll('"', '""')}"`
          : undefined) ?? ""
      );
      let j;
      if (props.demographics[keys[i]]) {
        for (j = 0; j < Object.keys(props.demographics[keys[i]]).length; j++) {
          rowData.push(
            Number(props.demographics[keys[i]][String(j + 2)]).toFixed(2) ?? ""
          );
        }
      } else {
        for (j = 0; j < 4; j++) {
          rowData.push("");
        }
      }
      for (let k = j; k < 4; k++) {
        rowData.push("");
      }
      for (let hour = 0; hour < 24; hour++) {
        if (
          scaledHourlyDemand &&
          scaledHourlyDemand[hour] &&
          keys[i] in scaledHourlyDemand[hour]
        ) {
          rowData.push(scaledHourlyDemand[hour][keys[i]].toFixed(2) ?? "");
        } else {
          rowData.push("0");
        }
      }
      appendData(rowData.join(","));
    }

    const filename = `${locationName}_${year}_${growthScenarioName}_charging_demand.csv`;
    downloadFile(csvData, filename);
  } catch (error) {
    console.log(error);
    downloadSuccess = false;
  }
  return downloadSuccess;
};

export const downloadFeederLines = async (
  data: FeatureCollection,
  props: DemandPageProps,
  scaleYear?: number
) => {
  let downloadSuccess = true;

  try {
    const locationName = props.location?.name
      .toLowerCase()
      .replaceAll(" ", "_");

    let csvData = "";
    const appendData = (data: string = "") => {
      csvData += data;
      csvData += "\n";
    };

    appendData("feeder_name,year,substation,ev_load,ev_status,feeder_length");

    for (let i = 0; i < data.features.length; i++) {
      const rowData = [
        `${data.features[i].properties?.feederName ?? ""}`,
        `${scaleYear ?? ""}`,
        `${data.features[i].properties?.substation ?? ""}`,
        `${data.features[i].properties?.evLoad ?? ""}`,
        `${data.features[i].properties?.evStatus ?? ""}`,
        `${data.features[i].properties?.shapeLength ?? ""}`,
      ];
      appendData(rowData.join(","));
    }

    const filename = `${locationName}_feeder_lines_${scaleYear}.csv`;
    downloadFile(csvData, filename);
  } catch (error) {
    console.log(error);
    downloadSuccess = false;
  }
  return downloadSuccess;
};
