import { Feature } from "geojson";

export const loadFeederLines = async (stateAbbr: string, apiToken: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}:${
      process.env.REACT_APP_API_PORT
    }/feeder-lines?${new URLSearchParams({ state: stateAbbr })}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return Promise.reject(new Error("Failed to fetch feeder lines."));
  }
};

export const loadFeederLine = async (
  id: string,
  apiToken: string,
  state: string
): Promise<Feature> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/feeder-lines/${state}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return Promise.reject(new Error("Failed to fetch feeder line."));
  }
};

export const loadLdvFeederLines = async (
  location: string,
  year: number,
  apiToken: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}:${
      process.env.REACT_APP_API_PORT
    }/feeder-lines/ldv?${new URLSearchParams({
      location: encodeURIComponent(JSON.stringify(location)),
      year: year.toString(),
    })}`,
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return Promise.reject("This location does not support feeder line data.");
  }
};
