import { useRef, useState } from "react";
import ExcelJS from "exceljs";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Checkbox,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import TimeSlider from "components/TimeSlider";
import { HubScenarioControls } from "types/hub-scenario-parameters";
import { SiteVehicleList } from "types/hub-site-vehicle";
import SiteSpecificSlider from "./SiteSpecificSlider";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { fleetsDataType } from "api/hub/fleetData";
import UtilitySelectBox from "./UtilitySelectBox";
import { HubUtilityRateNew } from "types/hub-utility-rate";
import { UtilityRateStructureNewInput } from "types/terminal-financial";
import { PG_AND_E_UTILITY_RATE } from "dashboard/terminal/SampleUtilityRate";
import { downloadUtilityRate } from "dashboard/terminal/download";

type AdvancedSettingsDialogProps = {
  openAdvancedSettingsDialog: boolean;
  annualAveragePerCharger: number;
  toggleAdvancedSettingsModal: () => void;
  setAnnualAveragePerCharger: (value: number) => void;
  scenarioControls: HubScenarioControls;
  setScenarioControls: (value: HubScenarioControls) => void;
  siteVehicles: SiteVehicleList;
  ldvOptimalStatus: boolean;
  optimalStatus: boolean;
  fleetsData: fleetsDataType;
  onClickLDVOptimal: () => void;
  onClickOptimal: () => void;
  utilityRates: HubUtilityRateNew[];
  uploadUtilityRate: (utilityRate: UtilityRateStructureNewInput) => void;
};
export default function AdvancedSettingsDialog({
  openAdvancedSettingsDialog,
  annualAveragePerCharger,
  toggleAdvancedSettingsModal,
  setAnnualAveragePerCharger,
  scenarioControls,
  setScenarioControls,
  siteVehicles,
  ldvOptimalStatus,
  optimalStatus,
  fleetsData,
  onClickLDVOptimal,
  onClickOptimal,
  utilityRates,
  uploadUtilityRate,
}: AdvancedSettingsDialogProps) {
  const [annualAverage, setAnnualAverage] = useState<number>(
    annualAveragePerCharger
  );
  const utilityFileElement = useRef<HTMLInputElement>(null);

  useDebouncedEffect(
    () => setAnnualAveragePerCharger(annualAverage),
    [annualAverage],
    500
  );

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target?.files?.[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target?.result as ArrayBuffer;
        if (!buffer) {
          console.error("Failed to read file content.");
          return;
        }

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        const sheet = workbook.worksheets[0];
        if (!sheet) {
          console.error("No sheet found in the Excel file.");
          return;
        }

        const values = sheet.getSheetValues() as any[][];
        const result: UtilityRateStructureNewInput = {
          name: values[1]?.[2] || "",
          fixedMonthlyFees: parseFloat(values[2]?.[2]) || 0,
          energyRates: [],
          demandRates: [],
          energyCharges: [],
          demandCharges: [],
        };

        // Determine the end of the energy rates section
        let energyRatesEnd = 6; // Start from row 6
        while (
          values[energyRatesEnd]?.[1] ||
          values[energyRatesEnd]?.[2] ||
          values[energyRatesEnd]?.[3]
        ) {
          energyRatesEnd++;
        }

        // Extract Energy Rates
        result.energyRates = values
          .slice(6, energyRatesEnd)
          .map(([_, period, tier, rates]) => ({
            period: parseFloat(period) || 0,
            tier: parseFloat(tier) || 0,
            rates: parseFloat(rates) || 0,
          }))
          .filter(({ tier, rates }) => tier !== 0 || rates !== 0);

        if (result.energyRates.length === 0) {
          result.energyRates.push({ period: 1, tier: 1, rates: 0 });
        }

        // Extract Energy Charges
        const energyChargesStart = energyRatesEnd + 2;
        const energyChargesEnd = energyChargesStart + 12; // 12 months
        for (let i = energyChargesStart; i < energyChargesEnd; i++) {
          const row: number[] = [];
          for (let hour = 2; hour <= 25; hour++) {
            row.push(parseFloat(values[i]?.[hour]) || 0);
          }
          result.energyCharges.push(row);
        }

        // Determine the end of the demand rates section
        let demandRatesStart = energyChargesEnd + 3;
        let demandRatesEnd = demandRatesStart;
        while (
          values[demandRatesEnd]?.[1] ||
          values[demandRatesEnd]?.[2] ||
          values[demandRatesEnd]?.[3]
        ) {
          demandRatesEnd++;
        }

        // Extract Demand Rates
        result.demandRates = values
          .slice(demandRatesStart, demandRatesEnd)
          .map(([_, period, tier, rates]) => ({
            period: parseFloat(period) || 0,
            tier: parseFloat(tier) || 0,
            rates: parseFloat(rates) || 0,
          }))
          .filter(({ tier, rates }) => tier !== 0 || rates !== 0);

        if (result.demandRates.length === 0) {
          result.demandRates.push({ period: 1, tier: 1, rates: 0 });
        }

        // Extract Demand Charges
        const demandChargesStart = demandRatesEnd + 2;
        const demandChargesEnd = demandChargesStart + 12; // 12 months
        for (let i = demandChargesStart; i < demandChargesEnd; i++) {
          const row: number[] = [];
          for (let hour = 2; hour <= 25; hour++) {
            row.push(parseFloat(values[i]?.[hour]) || 0);
          }
          result.demandCharges.push(row);
        }
        uploadUtilityRate(result);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error while processing the file:", error);
    }
  };

  const handleUploadFileClick = () => {
    if (utilityFileElement.current) {
      utilityFileElement.current.click();
    }
  };

  return (
    <Dialog
      open={openAdvancedSettingsDialog}
      onClose={() => toggleAdvancedSettingsModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={() => toggleAdvancedSettingsModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box>
          <Typography
            sx={{ flex: "1 1 100%", marginBottom: 3 }}
            variant="h2"
            id="tableTitle"
            component="div"
          >
            Advanced Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography
              sx={{ marginRight: 3 }}
              variant="body1"
              id="tableTitle"
              component="span"
            >
              Average Annual Maintenance Cost Per Charger
            </Typography>
            <TextField
              sx={{ m: 1, width: "130px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              type="number"
              value={annualAverage}
              inputProps={{ min: 0 }}
              onChange={(e) => setAnnualAverage(Number(e.target.value))}
            />
          </Box>
          <Stack direction="row" sx={{ marginTop: 1 }}>
            <Typography
              sx={{ marginTop: 2, marginRight: 2 }}
              variant="body1"
              id="tableTitle"
              component="span"
            >
              Utility Rate Structure
            </Typography>
            <UtilitySelectBox
              utilityRates={utilityRates}
              selectedUtilityRateId={scenarioControls.utilityRateId}
              setSelectedUtilityRateId={(utilityRateId: number) =>
                setScenarioControls({
                  ...scenarioControls,
                  utilityRateId,
                })
              }
            />
          </Stack>
          <Stack spacing={2} sx={{ marginTop: 2 }} direction={"row"}>
            <Box sx={{ width: "50%" }}>
              <input
                ref={utilityFileElement}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                accept=".xlsx, .xls"
              />
              <Button
                sx={{ padding: 1, width: "100%" }}
                color="primary"
                variant="contained"
                onClick={handleUploadFileClick}
              >
                Upload utility rate
              </Button>
            </Box>

            <Button
              sx={{ padding: 1, width: "50%" }}
              variant="contained"
              color="secondary"
              onClick={() =>
                downloadUtilityRate(
                  PG_AND_E_UTILITY_RATE,
                  "sample-hub-utility-rates.xlsx"
                )
              }
            >
              Download Sample Rate
            </Button>
          </Stack>
          <Divider sx={{ marginTop: 1 }} />
          {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
            <>
              <Typography variant="h6" sx={{ marginTop: 1 }}>
                LDV Settings
              </Typography>

              <Box>
                <Typography
                  sx={{ marginRight: 3 }}
                  variant="body1"
                  id="tableTitle"
                  component="span"
                >
                  Car Operating Hours
                </Typography>

                <TimeSlider
                  duplicateMidnight
                  value={scenarioControls.carOperatingHours}
                  onChange={(e, value) => {
                    setScenarioControls({
                      ...scenarioControls,
                      carOperatingHours: value as number[],
                    });
                  }}
                  valueLabelDisplay="auto"
                  step={1}
                />
              </Box>

              <Box sx={{ marginTop: 1 }}>
                <Typography
                  sx={{ marginRight: 3 }}
                  variant="body1"
                  id="tableTitle"
                  component="span"
                >
                  Light Truck Operating Hours
                </Typography>
                <TimeSlider
                  duplicateMidnight
                  value={scenarioControls.lightDutyOperatingHours}
                  onChange={(e, value) =>
                    setScenarioControls({
                      ...scenarioControls,
                      lightDutyOperatingHours: value as number[],
                    })
                  }
                  valueLabelDisplay="auto"
                  step={1}
                />
              </Box>
              <Stack spacing={3} sx={{ marginTop: 1 }}>
                <Stack sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    defaultValue={scenarioControls.carsParkingPct * 100}
                    InputLabels={{
                      left: "Car",
                      right: "Light Truck",
                    }}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        carsParkingPct: value[0] / 100,
                      })
                    }
                    disabled={ldvOptimalStatus}
                  />
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle2">Optimal Mix</Typography>
                    <Checkbox
                      checked={ldvOptimalStatus}
                      onClick={onClickLDVOptimal}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<RadioButtonChecked />}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    InputLabels={{
                      left: "Public Access",
                      right: "Private",
                    }}
                    defaultValue={scenarioControls.publicCarChargerPct * 100}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        publicCarChargerPct: value[0] / 100,
                      })
                    }
                    disabled={Object.keys(fleetsData).length > 0}
                  />
                </Stack>
              </Stack>
            </>
          )}

          {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
            <Divider sx={{ marginTop: 2 }} />
          )}
          {(siteVehicles.trucks || siteVehicles.trailers) && (
            <>
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                MHD Settings
              </Typography>
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  sx={{ marginRight: 3 }}
                  variant="body1"
                  id="tableTitle"
                  component="span"
                >
                  Truck Operating Hours
                </Typography>
                <TimeSlider
                  duplicateMidnight
                  value={scenarioControls.truckOperatingHours}
                  onChange={(e, value) =>
                    setScenarioControls({
                      ...scenarioControls,
                      truckOperatingHours: value as number[],
                    })
                  }
                  valueLabelDisplay="auto"
                  step={1}
                />
              </Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  sx={{ marginRight: 3 }}
                  variant="body1"
                  id="tableTitle"
                  component="span"
                >
                  Tractor-Trailer Operating Hours
                </Typography>
                <TimeSlider
                  duplicateMidnight
                  value={scenarioControls.trailerOperatingHours}
                  onChange={(e, value) =>
                    setScenarioControls({
                      ...scenarioControls,
                      trailerOperatingHours: value as number[],
                    })
                  }
                  valueLabelDisplay="auto"
                  step={1}
                />
              </Box>
              <Stack spacing={3} sx={{ marginTop: 2 }}>
                <Stack sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    defaultValue={scenarioControls.trucksParkingPct * 100}
                    InputLabels={{
                      left: "Straight Truck",
                      right: "Tractor-Trailer",
                    }}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        trucksParkingPct: value[0] / 100,
                      })
                    }
                    disabled={optimalStatus}
                  />
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle2">Optimal Mix</Typography>
                    <Checkbox
                      checked={optimalStatus}
                      onClick={onClickOptimal}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<RadioButtonChecked />}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    InputLabels={{
                      left: "Public Access",
                      right: "Private",
                    }}
                    defaultValue={scenarioControls.publicChargerPct * 100}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        publicChargerPct: value[0] / 100,
                      })
                    }
                    disabled={Object.keys(fleetsData).length > 0}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
